import {
  AmenitiesElectricityPowerIcon,
  AmenitiesEntertainmentBluetoothIcon,
  AmenitiesWaterfuelSewageTankIcon,
  AmenitiesWaterfuelWatertankIcon,
} from '@outdoorsyco/bonfire';
import { SVGProps } from 'react';
import { useIntl } from 'react-intl';

import { ICONS } from '@/components/switchback/Icon/Icon.constants';

import {
  AMENITIES_FOR_CAMPGROUNDS_KEYS,
  AMENITIES_FOR_LODGING_CAMPSITES_KEYS,
  AMENITIES_FOR_RV_CAMPSITES_KEYS,
  AMENITIES_FOR_STAY_KEYS,
  AMENITIES_FOR_TENT_CAMPSITES_KEYS,
  AMENITIES_KEYS,
} from './amenities';
import { ELocationDetails } from './locationDetails';
import { TRulesFilters } from './moreFilter';

export enum EAmenityGroup {
  COOKING = 'cooking',
  STAY_COOKING = 'stay_cooking',
  TEMPERATURE = 'temperature',
  BATHROOM = 'bathroom',
  STAY_BATHROOM = 'stay_bathroom',
  OTHER = 'other',
  OUTDOOR_SPACE = 'outdoor_space',
  SLEEP = 'sleep',
  OFFICE_AND_ENTERTAINMENT = 'office_and_entertainment',
  SAFETY = 'safety',
  ACCESSIBILITY = 'accessibility',
  CAMPGROUND_UTILITY = 'campground_utility',
  CAMPGROUND_FOOD_AND_BEVERAGE = 'campground_food_and_beverage',
  CAMPGROUND_SERVICES = 'campground_services',
  CAMPGROUND_ENTERTAINMENT = 'campground_entertainment',
  CAMPGROUND_SLEEP = 'campground_sleep',
  CAMPGROUND_COOKING = 'campground_cooking',
  CAMPGROUND_BATHROOM = 'campground_bathroom',
  CAMPGROUND_OUTDOOR_SPACE = 'campground_outdoor_space',
  CAMPGROUND_OFFICE_AND_ENTERTAINMENT = 'campground_office_and_entertainment',
  CAMPGROUND_TEMPERATURE = 'campground_temperature',
  CAMPGROUND_SAFETY = 'campground_safety',
  CAMPGROUND_ACCESSIBILITY = 'campground_accessibility',
  CAMPGROUND_OCCUPANCY = 'campground_occupancy',
  CAMPGROUND_ON_SITE = 'campground_on_site',
  CAMPGROUND_HOOK_UPS = 'campground_hook_ups',
  CAMPGROUND_PARKING = 'campground_parking',
}

export type TAmenitySlug =
  | AMENITIES_KEYS
  | AMENITIES_FOR_STAY_KEYS
  | AMENITIES_FOR_CAMPGROUNDS_KEYS
  | AMENITIES_FOR_RV_CAMPSITES_KEYS
  | AMENITIES_FOR_TENT_CAMPSITES_KEYS
  | AMENITIES_FOR_LODGING_CAMPSITES_KEYS
  | ELocationDetails
  | TRulesFilters
  | 'water_tank'
  | 'accessible';

interface TAmenityFilter {
  icon?: ICONS;
  bonfireIcon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  label: string;
  slug: TAmenitySlug;
  helpText?: string;
}

export interface IAmenityGroup {
  title: string;
  items: TAmenityFilter[];
}

export enum ECampsiteType {
  RV_SITE = 'rv_site',
  TENT_SITE = 'tent_site',
  LODGING_SITE = 'lodging_site',
  STATIONARY_CAMPER_SITE = 'stationary_camper_site',
}

export const useAmenitiesFilterList = (isStay = false) => {
  const intl = useIntl();
  const stayAmenities: Partial<Record<EAmenityGroup, IAmenityGroup>> = {
    [EAmenityGroup.STAY_COOKING]: {
      title: intl.formatMessage({
        description: 'search/filters: cooking amenities header',
        defaultMessage: 'Cooking',
        id: 'kvWkRJ',
      }),
      items: [
        {
          icon: ICONS.POTABLE_WATER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity potable water',
            defaultMessage: 'Potable water',
            id: 'gFrJs/',
          }),
          slug: 'potable_water',
        },
        {
          icon: ICONS.REFRIGERATOR_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity refrigerator',
            defaultMessage: 'Refrigerator',
            id: 'kBUON4',
          }),
          slug: 'refrigerator',
        },
        {
          icon: ICONS.MICROWAVE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity microwave',
            defaultMessage: 'Microwave',
            id: 'xaA20Y',
          }),
          slug: 'microwave',
        },
        {
          icon: ICONS.OVEN_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity oven',
            defaultMessage: 'Oven',
            id: 'fmNCtB',
          }),
          slug: 'oven',
        },
        {
          icon: ICONS.STOVE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity stove',
            defaultMessage: 'Stove',
            id: 'EkIKSe',
          }),
          slug: 'stove',
        },
        {
          icon: ICONS.KITCHEN_SINK_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity kitchen sink',
            defaultMessage: 'Kitchen sink',
            id: 'bQSjnF',
          }),
          slug: 'kitchen_sink',
        },
        {
          icon: ICONS.DINING_TABLE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity dining table',
            defaultMessage: 'Dining table',
            id: 'SZ/+Xc',
          }),
          slug: 'dining_table',
        },
        {
          icon: ICONS.COFFEE_MAKER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity coffee maker',
            defaultMessage: 'Coffee maker',
            id: 'rC5Egw',
          }),
          slug: 'coffee_maker',
        },
        {
          icon: ICONS.COOKING_BASICS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity cooking basics',
            defaultMessage: 'Cooking basics',
            id: 'mnSxKH',
          }),
          slug: 'cooking_basics',
        },
        {
          icon: ICONS.POTS_AND_PANS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity pots and pans',
            defaultMessage: 'Pots and pans',
            id: '0AcBDC',
          }),
          slug: 'pots_and_pans',
        },
        {
          icon: ICONS.DISHES_AND_UTENSILS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity dining table',
            defaultMessage: 'Dishes and utensils',
            id: 'TeWMe3',
          }),
          slug: 'dishes_and_utensils',
        },
      ],
    },
    [EAmenityGroup.TEMPERATURE]: {
      title: intl.formatMessage({
        description: 'search/filters: temperature amenities header',
        defaultMessage: 'Temperature',
        id: 'GiUELs',
      }),
      items: [
        {
          icon: ICONS.AIR_CONDITIONING_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity air conditioner',
            defaultMessage: 'Air conditioning',
            id: 'JGipYC',
          }),
          slug: 'air_conditioning',
        },
        {
          icon: ICONS.HEATING_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity heating',
            defaultMessage: 'Heating',
            id: 'EcaVkY',
          }),
          slug: 'heating',
        },
        {
          icon: ICONS.CEILING_FAN_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity ceiling fan',
            defaultMessage: 'Ceiling fan',
            id: 'lM+1Fc',
          }),
          slug: 'ceiling_fan',
        },
        {
          icon: ICONS.INDOOR_FIREPLACE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity ceiling fan',
            defaultMessage: 'Indoor fireplace',
            id: '6mY8kS',
          }),
          slug: 'indoor_fireplace',
        },
      ],
    },
    [EAmenityGroup.STAY_BATHROOM]: {
      title: intl.formatMessage({
        description: 'search/filters: bathroom amenities header',
        defaultMessage: 'Bathroom',
        id: 'DHneQH',
      }),
      items: [
        {
          icon: ICONS.INDOOR_SHOWER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity inside shower',
            defaultMessage: 'Indoor shower',
            id: '5b1eu+',
          }),
          slug: 'indoor_shower',
        },
        {
          icon: ICONS.OUTDOOR_SHOWER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity outside shower',
            defaultMessage: 'Outdoor shower',
            id: '6owXFI',
          }),
          slug: 'outdoor_shower',
        },
        {
          icon: ICONS.FLUSH_TOILET_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity flush toilet',
            defaultMessage: 'Flush toilet',
            id: 'adN5gg',
          }),
          slug: 'flush_toilet',
        },
        {
          icon: ICONS.COMPOST_TOILET_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity compost toilet',
            defaultMessage: 'Compost toilet',
            id: 'kqaMMb',
          }),
          slug: 'compost_toilet',
        },
        {
          icon: ICONS.BATH_TOWELS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity bath towels',
            defaultMessage: 'Bath towels',
            id: 'ZVcp5a',
          }),
          slug: 'bath_towels',
        },
        {
          icon: ICONS.TOILETRIES_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity toiletries',
            defaultMessage: 'Toiletries',
            id: 'athU54',
          }),
          slug: 'toiletries',
        },
        {
          icon: ICONS.HOT_WATER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity hot water',
            defaultMessage: 'Hot water',
            id: 'QEvHcx',
          }),
          slug: 'hot_water',
        },
      ],
    },
    [EAmenityGroup.OUTDOOR_SPACE]: {
      title: intl.formatMessage({
        description: 'search/filters: outdoor space amenities header',
        defaultMessage: 'Outdoor space',
        id: 'oOF1w3',
      }),
      items: [
        {
          icon: ICONS.FIRE_PIT_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity fire pit',
            defaultMessage: 'Fire pit',
            id: 'AYVDJN',
          }),
          slug: 'fire_pit',
        },
        {
          icon: ICONS.BBQ_GRILL_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity grill',
            defaultMessage: 'Grill',
            id: 'lAhpCI',
          }),
          slug: 'bbq_grill',
        },
        {
          icon: ICONS.HOT_TUB_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity hot tub',
            defaultMessage: 'Hot tub',
            id: 'UrBM9z',
          }),
          slug: 'hot_tub',
        },
        {
          icon: ICONS.OUTDOOR_FURNITURE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity outdoor furniture',
            defaultMessage: 'Outdoor furniture',
            id: 'xQqClZ',
          }),
          slug: 'outdoor_furniture',
        },
        {
          icon: ICONS.POOL_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity pool',
            defaultMessage: 'Pool',
            id: 'T+H/Ru',
          }),
          slug: 'pool',
        },
        {
          icon: ICONS.PICNIC_TABLE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity picnic table',
            defaultMessage: 'Picnic table',
            id: 'oCb4HF',
          }),
          slug: 'picnic_table',
        },
        {
          icon: ICONS.PATIO_BALCONY_DECK_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity patio, deck, or balcony',
            defaultMessage: 'Patio, deck, or balcony',
            id: 'sSQhJG',
          }),
          slug: 'patio_balcony_deck',
        },
      ],
    },
    [EAmenityGroup.SLEEP]: {
      title: intl.formatMessage({
        description: 'search/filters: sleep amenities header',
        defaultMessage: 'Sleep',
        id: 'eO3QkK',
      }),
      items: [
        {
          icon: ICONS.BLANKETS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity blankets',
            defaultMessage: 'Blankets',
            id: 'k0Ru4L',
          }),
          slug: 'blankets',
        },
        {
          icon: ICONS.FRESH_LINENS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity linens',
            defaultMessage: 'Linens',
            id: '18P/Ti',
          }),
          slug: 'fresh_linens',
        },
        {
          icon: ICONS.PILLOWS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity pillows',
            defaultMessage: 'Pillows',
            id: 'NfyY0/',
          }),
          slug: 'pillows',
        },
      ],
    },
    [EAmenityGroup.OFFICE_AND_ENTERTAINMENT]: {
      title: intl.formatMessage({
        description: 'search/filters: office and entertainment amenities header',
        defaultMessage: 'Office and entertainment',
        id: '4Jd1+u',
      }),
      items: [
        {
          icon: ICONS.TV_DVD_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity tv',
            defaultMessage: 'TV',
            id: 'bP9qTG',
          }),
          slug: 'tv_dvd',
        },
        {
          icon: ICONS.WIFI_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity wi-fi',
            defaultMessage: 'Wi-fi',
            id: '/TBDux',
          }),
          slug: 'wifi',
        },
        {
          icon: ICONS.WORKSPACE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity workspace',
            defaultMessage: 'Workspace',
            id: 'loYa04',
          }),
          slug: 'workspace',
        },
      ],
    },
    [EAmenityGroup.SAFETY]: {
      title: intl.formatMessage({
        description: 'search/filters: safety amenities header',
        defaultMessage: 'Safety',
        id: 'pMLwM0',
      }),
      items: [
        {
          icon: ICONS.CARBON_MONOXIDE_DETECTOR_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity co detector',
            defaultMessage: 'CO detector',
            id: 'nBWzAX',
          }),
          slug: 'carbon_monoxide_detector',
        },
        {
          icon: ICONS.SMOKE_DETECTOR_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity smoke detector',
            defaultMessage: 'Smoke detector',
            id: '/8LoDm',
          }),
          slug: 'smoke_detector',
        },
        {
          icon: ICONS.FIRE_EXTINGUISHER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity fire extinguisher',
            defaultMessage: 'Fire extinguisher',
            id: 'awcpeL',
          }),
          slug: 'fire_extinguisher',
        },
        {
          icon: ICONS.FIRST_AID_KIT_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity first-aid kit',
            defaultMessage: 'First-aid kit',
            id: 'aPS6d8',
          }),
          slug: 'first_aid_kit',
        },
      ],
    },
    [EAmenityGroup.ACCESSIBILITY]: {
      title: intl.formatMessage({
        description: 'search/filters: accessibility amenities header',
        defaultMessage: 'Accessibility',
        id: 'MqYo44',
      }),
      items: [
        {
          icon: ICONS.ADA_ACCESSIBLE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity ada accessible',
            defaultMessage: 'ADA accessible',
            id: 'THDztZ',
          }),
          // aliased to ada_accessible in odc-search, this allows the nav search header
          // to be synced with this amenity for stays while still working for RVs
          slug: 'accessible',
        },
        {
          icon: ICONS.WHEELCHAIR_ACCESSIBLE_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity wheelchair accessible',
            defaultMessage: 'Wheelchair accessible',
            id: '9ilg6z',
          }),
          slug: 'wheelchair_accessible',
        },
      ],
    },
  };

  const vehicleAmenities: Partial<Record<EAmenityGroup, IAmenityGroup>> = {
    [EAmenityGroup.COOKING]: {
      title: intl.formatMessage({
        description: 'search/filters: cooking amenities header',
        defaultMessage: 'Cooking',
        id: 'kvWkRJ',
      }),
      items: [
        {
          icon: ICONS.FRIDGE,
          label: intl.formatMessage({
            description: 'search/filters: amenity refrigerator',
            defaultMessage: 'Refrigerator',
            id: 'kBUON4',
          }),
          slug: 'refrigerator',
        },
        {
          icon: ICONS.MICROWAVE,
          label: intl.formatMessage({
            description: 'search/filters: amenity microwave',
            defaultMessage: 'Microwave',
            id: 'xaA20Y',
          }),
          slug: 'microwave',
        },
        {
          icon: ICONS.OVEN,
          label: intl.formatMessage({
            description: 'search/filters: amenity oven',
            defaultMessage: 'Oven',
            id: 'fmNCtB',
          }),
          slug: 'oven',
        },
        {
          icon: ICONS.STOVE,
          label: intl.formatMessage({
            description: 'search/filters: amenity stove',
            defaultMessage: 'Stove',
            id: 'EkIKSe',
          }),
          slug: 'stove',
          helpText: intl.formatMessage({
            description: 'search/filters: amenity stove help text',
            defaultMessage: 'Cook your favorite meals on this compact propane fueled stove',
            id: 'lnOPG/',
          }),
        },
        {
          icon: ICONS.KITCHEN_SINK,
          label: intl.formatMessage({
            description: 'search/filters: amenity kitchen sink',
            defaultMessage: 'Kitchen sink',
            id: 'bQSjnF',
          }),
          slug: 'kitchen_sink',
        },
        {
          icon: ICONS.TABLE,
          label: intl.formatMessage({
            description: 'search/filters: amenity dining table',
            defaultMessage: 'Dining table',
            id: 'SZ/+Xc',
          }),
          slug: 'dining_table',
        },
        {
          icon: ICONS.POTABLE_WATER_FOR_STAY,
          label: intl.formatMessage({
            defaultMessage: 'Potable water',
            id: 'gFrJs/',
            description: 'search/filters: amenity potable water',
          }),
          slug: 'potable_water',
        },
        {
          icon: ICONS.COFFEE_MAKER_FOR_STAY,
          label: intl.formatMessage({
            defaultMessage: 'Coffee maker',
            id: 'rC5Egw',
            description: 'search/filters: amenity coffee maker',
          }),
          slug: 'coffee_maker',
        },
        {
          icon: ICONS.POTS_AND_PANS_FOR_STAY,
          label: intl.formatMessage({
            defaultMessage: 'Pots and pans',
            id: '0AcBDC',
            description: 'search/filters: amenity pots and pans',
          }),
          slug: 'pots_and_pans',
        },
        {
          icon: ICONS.DISHES_AND_UTENSILS_FOR_STAY,
          label: intl.formatMessage({
            defaultMessage: 'Dishes and utensils',
            id: 'AaMuXh',
            description: 'search/filters: amenity dishes and utensils',
          }),
          slug: 'dishes_and_utensils',
        },
        {
          icon: ICONS.COOKING_BASICS_FOR_STAY,
          label: intl.formatMessage({
            defaultMessage: 'Cooking basics',
            id: 'mnSxKH',
            description: 'search/filters: amenity cooking basics',
          }),
          slug: 'cooking_basics',
        },
      ],
    },
    [EAmenityGroup.TEMPERATURE]: {
      title: intl.formatMessage({
        description: 'search/filters: temperature amenities header',
        defaultMessage: 'Temperature',
        id: 'GiUELs',
      }),
      items: [
        {
          icon: ICONS.AIR_CONDITIONER,
          label: intl.formatMessage({
            description: 'search/filters: amenity air conditioner',
            defaultMessage: 'Air conditioning',
            id: 'JGipYC',
          }),
          slug: 'air_conditioner',
        },
        {
          icon: ICONS.HEATER,
          label: intl.formatMessage({
            description: 'search/filters: amenity heater',
            defaultMessage: 'Heater',
            id: 'zg5aJi',
          }),
          slug: 'heater',
        },
        {
          icon: ICONS.CEILING_FAN,
          label: intl.formatMessage({
            description: 'search/filters: amenity ceiling fan',
            defaultMessage: 'Ceiling fan',
            id: 'lM+1Fc',
          }),
          slug: 'ceiling_fan',
        },
      ],
    },
    [EAmenityGroup.BATHROOM]: {
      title: intl.formatMessage({
        description: 'search/filters: bathroom amenities header',
        defaultMessage: 'Bathroom',
        id: 'DHneQH',
      }),
      items: [
        {
          icon: ICONS.SHOWER_INSIDE,
          label: intl.formatMessage({
            description: 'search/filters: amenity inside shower',
            defaultMessage: 'Indoor shower',
            id: '5b1eu+',
          }),
          slug: 'inside_shower',
        },
        {
          icon: ICONS.SHOWER_OUTSIDE,
          label: intl.formatMessage({
            description: 'search/filters: amenity outside shower',
            defaultMessage: 'Outdoor shower',
            id: '6owXFI',
          }),
          slug: 'outside_shower',
          helpText: intl.formatMessage({
            description: 'search/filters: amenity outside shower help text',
            defaultMessage: 'Rinse with fresh water outside of the RV',
            id: 'j1F9h1',
          }),
        },
        {
          icon: ICONS.TOILET,
          label: intl.formatMessage({
            description: 'search/filters: amenity toilet',
            defaultMessage: 'Toilet',
            id: '2ZV9bm',
          }),
          slug: 'toilet',
        },
        {
          icon: ICONS.BATH_TOWELS_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity bath towels',
            defaultMessage: 'Bath towels',
            id: 'ZVcp5a',
          }),
          slug: 'bath_towels',
        },
        {
          icon: ICONS.KITCHEN_SINK,
          label: intl.formatMessage({
            description: 'search/filters: amenity bathroom sink',
            defaultMessage: 'Bathroom sink',
            id: 'ubJTXv',
          }),
          slug: 'bathroom_sink',
        },
        {
          icon: ICONS.TOILET_PAPER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity toilet paper',
            defaultMessage: 'Toilet paper',
            id: 'FC9LHf',
          }),
          slug: 'toilet_paper',
        },
        {
          icon: ICONS.TOILETRIES_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity toiletries',
            defaultMessage: 'Toiletries',
            id: 'athU54',
          }),
          slug: 'toiletries',
        },
        {
          icon: ICONS.HOT_WATER_FOR_STAY,
          label: intl.formatMessage({
            description: 'search/filters: amenity hot water',
            defaultMessage: 'Hot water',
            id: 'QEvHcx',
          }),
          slug: 'hot_water',
        },
      ],
    },
    [EAmenityGroup.OTHER]: {
      title: intl.formatMessage({
        description: 'search/filters: other amenities header',
        defaultMessage: 'Other',
        id: '+C25bz',
      }),
      items: [
        {
          icon: ICONS.GENERATOR,
          label: intl.formatMessage({
            description: 'search/filters: amenity generator',
            defaultMessage: 'Generator',
            id: 'deMtrZ',
          }),
          slug: 'generator',
        },
        {
          icon: ICONS.INVERTER,
          label: intl.formatMessage({
            description: 'search/filters: amenity inverter',
            defaultMessage: 'Inverter',
            id: 'sf4JC5',
          }),
          slug: 'inverter',
          helpText: intl.formatMessage({
            description: 'search/filters: amenity inverter help text',
            defaultMessage: 'Power the RV batteries and charge your devices',
            id: 'EdEjtz',
          }),
        },
        {
          icon: ICONS.FRESH_WATER,
          label: intl.formatMessage({
            description: 'search/filters: amenity water tank',
            defaultMessage: 'Water tank',
            id: 'Zfsb1t',
          }),
          slug: 'water_tank',
        },
        {
          icon: ICONS.LEVELING_JACK,
          label: intl.formatMessage({
            description: 'search/filters: amenity leveling jacks',
            defaultMessage: 'Leveling jacks',
            id: 'z8qLJ+',
          }),
          slug: 'leveling_jacks',
        },
        {
          icon: ICONS.WIFI,
          label: intl.formatMessage({
            description: 'search/filters: amenity wifi',
            defaultMessage: 'Wi-Fi',
            id: 'CUVH7D',
          }),
          slug: 'wifi',
        },
        {
          icon: ICONS.AUDIO_INPUTS,
          label: intl.formatMessage({
            description: 'search/filters: amenity audio inputs',
            defaultMessage: 'Audio inputs',
            id: 'vZX5a1',
          }),
          slug: 'audio_inputs',
        },
        {
          icon: ICONS.AWNING,
          label: intl.formatMessage({
            description: 'search/filters: amenity awning',
            defaultMessage: 'Awning',
            id: 'Dqwti2',
          }),
          slug: 'awning',
        },
        {
          icon: ICONS.BIKE_RACK,
          label: intl.formatMessage({
            description: 'search/filters: amenity bike rack',
            defaultMessage: 'Bike rack',
            id: 'Xv2drh',
          }),
          slug: 'bike_rack',
        },
        {
          icon: ICONS.SOLAR,
          label: intl.formatMessage({
            description: 'search/filters: amenity solar',
            defaultMessage: 'Solar panel',
            id: 'Ois6Gb',
          }),
          slug: 'solar',
        },
        {
          icon: ICONS.TV,
          label: intl.formatMessage({
            description: 'search/filters: amenity tv dvd',
            defaultMessage: 'TV / DVD',
            id: 'iFxO7V',
          }),
          slug: 'tv_dvd',
        },
        {
          icon: ICONS.WASHER_DRYER,
          label: intl.formatMessage({
            description: 'search/filters: amenity washer dryer',
            defaultMessage: 'Washer / Dryer',
            id: 'jJ8v3n',
          }),
          slug: 'washer_dryer',
        },
        {
          bonfireIcon: AmenitiesWaterfuelWatertankIcon,
          label: intl.formatMessage({
            description: 'search/filters: amenity water hookup',
            defaultMessage: 'Water hookup',
            id: 'jiRA3+',
          }),
          slug: 'hookup_water',
        },
        {
          bonfireIcon: AmenitiesElectricityPowerIcon,
          label: intl.formatMessage({
            description: 'search/filters: amenity electric hookup',
            defaultMessage: 'Electric hookup',
            id: '57Nroo',
          }),
          slug: 'hookup_electric',
        },
        {
          bonfireIcon: AmenitiesWaterfuelSewageTankIcon,
          label: intl.formatMessage({
            description: 'search/filters: amenity sewer hookup',
            defaultMessage: 'Sewer hookup',
            id: 'WPuPla',
          }),
          slug: 'hookup_sewer',
        },
        {
          bonfireIcon: AmenitiesEntertainmentBluetoothIcon,
          label: intl.formatMessage({
            description: 'search/filters: amenity bluetooth',
            defaultMessage: 'Bluetooth',
            id: 'db4AMM',
          }),
          slug: 'bluetooth',
        },
      ],
    },
  };

  if (isStay) {
    return stayAmenities;
  }
  return vehicleAmenities;
};
