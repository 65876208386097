import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';

export const getUserFavorites = createSelector(
  [(state: TRootState) => state.wishlists.data],
  wishlistedRentals => {
    return { data: wishlistedRentals };
  },
);
