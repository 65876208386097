import {
  AmenitiesElectricityGeneratorIcon,
  AmenitiesOutdoorFirepitIcon,
  AmenitiesParkingParkingIcon,
  AmenitiesPetsPetFriendlyIcon,
  AmenitiesSlideoutIcon,
  AmenitiesSmokingSmokingIcon,
  VehiclesVehiclesClassCSimpleIcon,
} from '@outdoorsyco/bonfire';
import { SVGProps } from 'react';
import { defineMessage, MessageDescriptor } from 'react-intl';

import {
  FRESH_WATER,
  GENERATOR,
  PARKING_ONSITE,
  SEWAGE_TANK,
} from '@/components/switchback/Icon/assets';
import { ICONS } from '@/components/switchback/Icon/Icon.constants';
import { IconType } from '@/components/switchback/Icon/IconComponent';

interface IMoreFilterContent {
  icon?: ICONS | IconType;
  BonfireIcon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  label: MessageDescriptor;
  description?: MessageDescriptor;
  size?: { gte: number; lte: number };
}

export const maxTrailerWeight = { pound: 10000, kilogram: 4535 };
export const maxVisibleVehicleLength = 60;
export const maxSearchableVehicleLength = 255;

export type THookupFilters = 'hookup_water' | 'hookup_electric' | 'hookup_sewer';

export const vehicleDetailsHookupFilters: Record<THookupFilters, IMoreFilterContent> = {
  hookup_water: {
    label: defineMessage({
      defaultMessage: 'Water',
      id: '4ndjvp',
      description: 'More filter: vehicle details - hookups - Water',
    }),
    icon: FRESH_WATER,
  },
  hookup_electric: {
    label: defineMessage({
      defaultMessage: 'Electricity',
      id: 'fOgrv3',
      description: 'More filter: vehicle details - hookups - Electricity',
    }),
    icon: GENERATOR,
  },
  hookup_sewer: {
    label: defineMessage({
      defaultMessage: 'Sewage',
      id: 'AnDS/a',
      description: 'More filter: vehicle details - hookups - Sewage',
    }),
    icon: SEWAGE_TANK,
  },
};

export type TRulesFilters =
  | 'pet_friendly'
  | 'tailgate_friendly'
  | 'festival_friendly'
  | 'smoking_allowed';

type TRulesFiltersForStay = 'pet_friendly' | 'smoking_allowed' | 'stay_parking_onsite';

export enum ERulesFiltersForCampgrounds {
  ALLOWS_PETS = 'allows_pets',
  ALLOWS_CAMPFIRES = 'allows_campfires',
  ALLOWS_RV = 'allows_rv',
  ALLOWS_GENERATORS = 'allows_generators',
  ALLOWS_SLIDEOUTS = 'allows_slideouts',
  ALLOWS_SMOKING = 'allows_smoking',
  ALLOWS_PARKING = 'allows_parking',
}

export const getRulesFilters = () => {
  const result: Record<TRulesFilters, IMoreFilterContent> = {
    pet_friendly: {
      icon: ICONS.PET_FRIENDLY,
      label: defineMessage({
        defaultMessage: 'Pet-friendly',
        id: 'uNhd5u',
        description: 'More filter: rules and policies - Pet-friendly',
      }),
    },
    tailgate_friendly: {
      icon: ICONS.TAILGATING,
      label: defineMessage({
        defaultMessage: 'Tailgate-friendly',
        id: 'mr8EE2',
        description: 'More filter: rules and policies - Tailgate-friendly',
      }),
    },
    festival_friendly: {
      icon: ICONS.MUSIC_FESTIVAL,
      label: defineMessage({
        defaultMessage: 'Festival-friendly',
        id: 'HVjQnr',
        description: 'More filter: rules and policies - Festival-friendly',
      }),
    },
    smoking_allowed: {
      icon: ICONS.SMOKING,
      label: defineMessage({
        defaultMessage: 'Smoking allowed',
        id: 'ibPds9',
        description: 'More filter: rules and policies - Smoking allowed',
      }),
    },
  };
  return result;
};
export const getRulesFiltersForStay = () => {
  const result: Record<TRulesFiltersForStay, IMoreFilterContent> = {
    pet_friendly: {
      icon: ICONS.PET_FRIENDLY,
      label: defineMessage({
        defaultMessage: 'Pet-friendly',
        id: 'uNhd5u',
        description: 'More filter: rules and policies - Pet-friendly',
      }),
    },
    smoking_allowed: {
      icon: ICONS.SMOKING,
      label: defineMessage({
        defaultMessage: 'Smoking allowed',
        id: 'ibPds9',
        description: 'More filter: rules and policies - Smoking allowed',
      }),
    },
    stay_parking_onsite: {
      icon: PARKING_ONSITE,
      label: defineMessage({
        defaultMessage: 'Parking available',
        id: 'gBQ7Nm',
        description: 'More filter: rules and policies - Parking available',
      }),
    },
  };
  return result;
};

export const getRulesFiltersForCampgrounds = () => {
  const result: Record<ERulesFiltersForCampgrounds, IMoreFilterContent> = {
    allows_rv: {
      BonfireIcon: VehiclesVehiclesClassCSimpleIcon,
      label: defineMessage({
        defaultMessage: 'Allows RVs',
        id: 'j66qSc',
        description: 'More filter: rules and policies - Allows RVs',
      }),
    },
    allows_slideouts: {
      BonfireIcon: AmenitiesSlideoutIcon,
      label: defineMessage({
        defaultMessage: 'Allows slideouts',
        id: 'hLVNhj',
        description: 'More filter: rules and policies - Allows slideouts',
      }),
    },
    allows_campfires: {
      BonfireIcon: AmenitiesOutdoorFirepitIcon,
      label: defineMessage({
        defaultMessage: 'Allows campfires',
        id: 'Xd/xNU',
        description: 'More filter: rules and policies - Allows campfires',
      }),
    },
    allows_generators: {
      BonfireIcon: AmenitiesElectricityGeneratorIcon,
      label: defineMessage({
        defaultMessage: 'Allows generators',
        id: 'brq5dl',
        description: 'More filter: rules and policies - Allows generators',
      }),
    },
    allows_pets: {
      BonfireIcon: AmenitiesPetsPetFriendlyIcon,
      label: defineMessage({
        defaultMessage: 'Pet-friendly',
        id: 'uNhd5u',
        description: 'More filter: rules and policies - Pet-friendly',
      }),
    },
    allows_smoking: {
      BonfireIcon: AmenitiesSmokingSmokingIcon,
      label: defineMessage({
        defaultMessage: 'Smoking allowed',
        id: 'ibPds9',
        description: 'More filter: rules and policies - Smoking allowed',
      }),
    },
    allows_parking: {
      BonfireIcon: AmenitiesParkingParkingIcon,
      label: defineMessage({
        defaultMessage: 'Parking available',
        id: 'gBQ7Nm',
        description: 'More filter: rules and policies - Parking available',
      }),
    },
  };

  return result;
};

export type TCancelPoliciesFilters = 'flexible';
export type TCheckInTypePolicyFilters = 'self';
