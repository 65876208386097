import { createSelector } from 'reselect';

import { getItemsPerPage } from '@/constants/pagination';
import { EFilterCategories } from '@/constants/searchFilters';
import { IRecentSearch } from '@/redux/modules/search';
import { TRootState } from '@/redux/rootReducer';
import { getUserFavorites } from '@/redux/selectors/favorites';
import { ICampgroundData } from '@/services/types/search/campgrounds/id';
import { IMeta, INearbyCampgroundsForDelivery } from '@/services/types/search/rentals/id';
import {
  ICampgroundTile,
  IRentalTile,
  mapCampgroundsToTiles,
  mapRentalsToTiles,
} from '@/utility/mapSearchResultToTile';

type TSearchResultsData = TRootState['search'];

interface IBaseSearchResult {
  error?: string;
  isFilterOpen: boolean;
  isLoading?: boolean;
  meta?: IMeta | null;
  nearby?: {
    data?: IRentalTile[];
  };
  randomStays?: {
    data?: IRentalTile[];
  };
  selectedFilter: EFilterCategories | null;
  recentSearches?: IRecentSearch[];
  nearbyCampgroundsForDelivery?: {
    data: ICampgroundData[];
    meta: IMeta;
  } | null;
  nearbyCampgrounds?: {
    data: ICampgroundData[];
    meta: IMeta;
  } | null;
}

type TRentalSearchResult = IBaseSearchResult & {
  data: IRentalTile[];
};

type TCampgroundSearchResult = IBaseSearchResult & {
  campgrounds: {
    data: ICampgroundTile[];
  };
};

export type TSearchResult = TRentalSearchResult | TCampgroundSearchResult;

export const isCampgroundSearchResult = (
  searchResult: TSearchResult,
): searchResult is TCampgroundSearchResult => {
  return 'campgrounds' in searchResult && !!searchResult.campgrounds;
};

export const getSearchResults = createSelector<
  TRootState,
  ReturnType<typeof getUserFavorites>,
  TSearchResultsData,
  TRootState['queryParams'],
  TRootState['searchForm'],
  TSearchResult
>(
  getUserFavorites,
  state => state.search,
  state => state.queryParams,
  state => state.searchForm,
  (
    favorites,
    {
      nearby,
      randomStays,
      data,
      meta,
      campgrounds,
      isLoading,
      error,
      isFilterOpen,
      selectedFilter,
      recentSearches,
      nearbyCampgroundsForDelivery,
      nearbyCampgrounds,
    },
    queryParams,
    searchForm,
  ) => {
    const loading = !!isLoading;
    const itemsPerPage = getItemsPerPage();
    const loadingItemsPlaceholder = Array.from({ length: itemsPerPage }, () => ({ loading: true }));
    const loadingItemsPlaceholderRandomStays = Array.from({ length: 8 }, () => ({ loading: true }));
    const stayRentals = randomStays?.data?.length ? randomStays.data : [];
    const nearbyRentals = nearby?.data?.length ? nearby.data : [];
    const campgroundsForDelivery = nearbyCampgroundsForDelivery?.data?.length
      ? nearbyCampgroundsForDelivery
      : null;

    const partialSearchResult = {
      meta,
      isLoading,
      error,
      isFilterOpen,
      selectedFilter,
      recentSearches,
    };

    let searchResult: TSearchResult;
    if (campgrounds) {
      searchResult = {
        ...partialSearchResult,
        campgrounds: {
          data: loading
            ? Array.from({ length: itemsPerPage }, () => ({
                loading: true,
                campground: true,
              }))
            : mapCampgroundsToTiles({
                campgrounds: campgrounds.data || [],
                loading,
                hasImageCarousel: true,
                queryParams,
                searchMeta: meta,
                searchForm,
              }),
        },
      };
    } else {
      searchResult = {
        ...partialSearchResult,
        data: loading
          ? loadingItemsPlaceholder
          : mapRentalsToTiles({
              rentals: data || [],
              favorites,
              loading,
              hasImageCarousel: true,
              queryParams,
              searchMeta: meta,
              searchForm,
            }),
      };
    }
    if (stayRentals.length) {
      searchResult.randomStays = {
        data: loading
          ? loadingItemsPlaceholderRandomStays
          : mapRentalsToTiles({
              rentals: stayRentals,
              favorites,
              loading,
              hasImageCarousel: true,
              queryParams,
              searchMeta: meta,
              searchForm,
            }),
      };
    }
    if (nearbyRentals.length) {
      searchResult.nearby = {
        data: loading
          ? loadingItemsPlaceholder
          : mapRentalsToTiles({
              rentals: nearbyRentals,
              favorites,
              loading,
              hasImageCarousel: true,
              queryParams,
              searchMeta: meta,
              searchForm,
            }),
      };
    }

    if (campgroundsForDelivery) {
      searchResult.nearbyCampgroundsForDelivery = campgroundsForDelivery;
    }

    if (nearbyCampgrounds) {
      searchResult.nearbyCampgrounds = nearbyCampgrounds?.data?.length ? nearbyCampgrounds : null;
    }

    return searchResult;
  },
);

export const getNearbyCampgroundsForDelivery = createSelector<
  TRootState,
  TSearchResultsData,
  INearbyCampgroundsForDelivery['data'] | undefined
>(
  state => state.search,
  search => search.nearbyCampgroundsForDelivery?.data,
);

export const getIsLoading = createSelector<TRootState, TSearchResultsData, boolean>(
  state => state.search,
  search => search.isLoading,
);

export const getIsStationaryFilterTriggered = createSelector<
  TRootState,
  TSearchResultsData,
  boolean
>(
  state => state.search,
  search => {
    const { isStationaryDeliveryOpen } = search;
    return !!isStationaryDeliveryOpen;
  },
);
export const getIsDeliveryFilterTriggeredFromAd = createSelector<
  TRootState,
  TSearchResultsData,
  boolean
>(
  state => state.search,
  search => {
    const { triggerDeliveryFilterFromAd } = search;
    return !!triggerDeliveryFilterFromAd;
  },
);

export const getSearchQuery = createSelector(
  (state: TRootState) => state.search,
  (search: TSearchResultsData) => search.searchQuery,
);
