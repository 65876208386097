import { GeneralArrowRightIcon, Text } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React, { useMemo } from 'react';

import ErrorBoundary from '@/components/ErrorBoundary';
import Anchor from '@/components/switchback/Anchor/Anchor';
import { AnimateInsertion } from '@/components/switchback/AnimateInsertion';
import { useRouter } from '@/hooks/useRouter';
import { useSitewideBannerData } from '@/services/experiments/sitewideBanner';

import css from './SitewideBanner.module.css';
import { Ticker } from './Ticker';

const appendRefParam = (url?: string) => {
  if (!url) return undefined;
  try {
    const urlObj = new URL(url, window.location.origin);
    urlObj.searchParams.set('ref', 'sitewide_banner');
    return urlObj.toString();
  } catch {
    return url;
  }
};

const processHtmlContent = (html: string) => {
  try {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    tempDiv.querySelectorAll('a').forEach(anchor => {
      anchor.href = appendRefParam(anchor.href) || anchor.href;
    });

    return tempDiv.innerHTML;
  } catch {
    return html;
  }
};

export const SitewideBanner = () => {
  const router = useRouter();

  const data = useSitewideBannerData();

  const items = useMemo(() => {
    if (!data || !data.content.length || !data.pages.includes(router.pathname)) return null;

    return data.content.map((c, index) => {
      const content = (
        <Text
          component="div"
          style={{ color: data.theme.text }}
          className={cn(css.content, 'text-[15px]')}>
          {'html' in c ? (
            <div dangerouslySetInnerHTML={{ __html: processHtmlContent(c.html) }} />
          ) : (
            c.text
          )}
        </Text>
      );

      if ('url' in c) {
        return (
          <Anchor
            key={index}
            href={appendRefParam(c.url)}
            className="flex items-center gap-2 group">
            {content}

            <GeneralArrowRightIcon
              style={{ color: data.theme.text }}
              className="w-4 h-4 lg:opacity-0 lg:group-hover:opacity-100 transition-opacity"
            />
          </Anchor>
        );
      }

      return <div key={index}>{content}</div>;
    });
  }, [data, router.pathname]);

  if (!data || !items) return null;

  return (
    <ErrorBoundary fallBackComponent={null}>
      <AnimateInsertion>
        <div style={{ background: data.theme.background }}>
          <Ticker items={items} />
        </div>
      </AnimateInsertion>
    </ErrorBoundary>
  );
};
