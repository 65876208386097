import React, { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from '@/hooks/useRouter';

interface IAppRoute {
  asPath: string;
  pathname: string;
}

interface IAppRouteContextProps {
  prev: IAppRoute;
  current: IAppRoute;
}

const AppRouteContext = createContext<IAppRouteContextProps>({
  prev: { asPath: '', pathname: '' },
  current: { asPath: '', pathname: '' },
});

export const AppRouteProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { asPath, pathname } = useRouter();
  const [state, setState] = useState<IAppRouteContextProps>({
    prev: { asPath: '', pathname: '' },
    current: { asPath: '', pathname: '' },
  });

  useEffect(() => {
    setState(prevState => {
      const isPathnameChanged = prevState.current.pathname !== pathname;

      return {
        prev: isPathnameChanged ? prevState.current : prevState.prev,
        current: {
          asPath,
          pathname,
        },
      };
    });
  }, [asPath, pathname]);

  return <AppRouteContext.Provider value={state}>{children}</AppRouteContext.Provider>;
};

export const useAppRoute = (): IAppRouteContextProps => {
  return useContext(AppRouteContext);
};
