import {
  EIconButtonVariant,
  ETextStyleVariant,
  GeneralSearchIcon,
  IconButton,
  Text,
} from '@outdoorsyco/bonfire';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { formatDateRange } from '@/utility/format-date';

import { INavbarSearch } from '../../SearchHeader';
interface IProps {
  address: INavbarSearch['address'];
  dates: INavbarSearch['dates'];
  guests: INavbarSearch['guests'];
  onOpenMobileSearch?: () => void;
  isCampgroundSearch?: boolean;
  isRvBundlesSearch?: boolean;
  scrollToSearchFilter?: () => void;
}

const MobileSearchMobileButton: React.FC<IProps> = ({
  address,
  dates,
  guests,
  onOpenMobileSearch,
  isCampgroundSearch = false,
  isRvBundlesSearch = false,
  scrollToSearchFilter,
}) => {
  const intl = useIntl();

  const addressButtonLabel = address
    ? address
    : intl.formatMessage({
        defaultMessage: 'Where are you heading?',
        id: 'C9pliC',
        description: 'Global Header > Placeholder text for location button on mobile bar',
      });

  const formattedDates = useMemo<string | null>(() => {
    if (!dates?.from || !dates?.to) {
      return null;
    }
    const datesLabel = formatDateRange(dates.from.toString(), dates.to.toString());

    if (dates.flexible_days) {
      return `${datesLabel} (±${dates.flexible_days})`;
    }

    return datesLabel;
  }, [dates]);

  const totalGuests = (guests?.adults || 0) + (guests?.children || 0) + (guests?.infants || 0) || 0;

  const guestsLabel = intl.formatMessage(
    {
      defaultMessage: '{totalGuests, plural, one {# guest} other {# guests}}',
      id: 'ErPlEg',
      description: 'Global Header > label for guests button on mobile bar',
    },
    {
      totalGuests,
    },
  );

  const datesButtonLabel = formattedDates
    ? formattedDates
    : intl.formatMessage({
        defaultMessage: 'Add dates',
        id: 'zP42BU',
        description: 'Global Header > Placeholder text for dates button on mobile bar',
      });

  const guestsButtonLabel = totalGuests
    ? guestsLabel
    : intl.formatMessage({
        defaultMessage: 'Add guests',
        id: 'k/FNcF',
        description: 'Global Header > Placeholder text for guests button on mobile bar',
      });

  return (
    <div
      className={
        'border grid grid-flow-col h-12 bg-white rounded-full overflow-hidden border-neutral-20'
      }>
      {!isRvBundlesSearch ? (
        <button
          className={`flex px-4 items-center ${isRvBundlesSearch ? 'justify-between' : ''}`}
          onClick={onOpenMobileSearch}>
          <GeneralSearchIcon className="mr-[10px]" />

          <div className="flex flex-col items-start">
            {!isCampgroundSearch && !isRvBundlesSearch && (
              <Text className="text-sm font-medium text-left truncate w-[240px]">
                {addressButtonLabel}
              </Text>
            )}
            <Text className="flex items-center text-xs text-gray-50 gap-2">
              {datesButtonLabel}
              <span className="rounded-full w-0.5 h-0.5 bg-[#4F5B69]" />
              {guestsButtonLabel}
            </Text>
          </div>
        </button>
      ) : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className="flex items-center justify-between px-4 cursor-pointer"
          onClick={onOpenMobileSearch}>
          <div className="flex flex-row w-full gap-4">
            <Text
              className="flex-1 text-left border-gray-250 border-r-[1px] text-gray-50"
              variant={ETextStyleVariant.LegalBold}>
              {datesButtonLabel}
            </Text>

            <Text className="flex-1 text-left text-gray-50" variant={ETextStyleVariant.LegalBold}>
              {guestsButtonLabel}
            </Text>
          </div>

          <IconButton
            icon={GeneralSearchIcon}
            onClick={scrollToSearchFilter}
            size="small"
            variant={EIconButtonVariant.Tertiary}
            className="text-white !bg-black"
          />
        </div>
      )}
    </div>
  );
};

export default MobileSearchMobileButton;
