import {
  Button,
  EButtonColorVariant,
  EHeadingStyleVariant,
  EModalVariants,
  ETextStyleVariant,
  GeneralCalendarIcon,
  GeneralMapPinOutlineIcon,
  GeneralUserUsersIcon,
  Heading,
  Modal,
  Selector,
} from '@outdoorsyco/bonfire';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import { ESearchFilters } from '@/constants/searchFilters';
import { useRouter } from '@/hooks/useRouter';
import { IHeaderSearchFilterDates, setHeaderSearchFilter } from '@/redux/modules/globalHeader';
import { formatDateFullRange } from '@/utility/format-date';
import { getParamAsString, getParams } from '@/utility/queryParams';

import { INavbarSearch, QUERYSTRING_DATE_FORMAT } from '../../SearchHeader';
import { MobileSearchAddressModalContent } from './MobileSearchAddressModalContent';
import { MobileSearchDatesModalContent } from './MobileSearchDatesModalContent';
import { MobileSearchGuestsModalContent } from './MobileSearchGuestsModalContent';

type TMobileSearchModalProps = {
  location?: { lat: number; lng: number };
  address: INavbarSearch['address'];
  dates: INavbarSearch['dates'];
  guests: INavbarSearch['guests'];
  isSearchModalOpen: boolean;
  onCloseHandler: () => void;
  onSubmit?: () => void;
  onChangeGuests?: () => void;
  onChangeDates?: (dates?: IHeaderSearchFilterDates) => void;
  onChangeAddress?: (address?: string) => void;
  popularDestinations?: { label: string }[];
  recentSearches?: IAutocompleteOption[];
  handleRecentSearchNavigation?: (url: string) => void;
  maxRecentSearches: number;
  isGuestOccupancyAvailable?: boolean;
  isCampgroundSearch?: boolean;
};

export enum EFilterContent {
  ADDRESS = 'address',
  DATES = 'dates',
  GUESTS = 'guests',
}

export const MobileSearchModal = ({
  location,
  address,
  dates,
  guests,
  isSearchModalOpen,
  onCloseHandler,
  onSubmit,
  onChangeGuests,
  onChangeDates,
  onChangeAddress,
  popularDestinations,
  recentSearches,
  handleRecentSearchNavigation,
  maxRecentSearches,
  isGuestOccupancyAvailable,
  isCampgroundSearch = false,
}: TMobileSearchModalProps) => {
  const intl = useIntl();
  const router = useRouter();
  const dispatch = useDispatch();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filterContent, setFilterContent] = useState<EFilterContent>();

  const onClose = () => {
    const initialParams = getParams(router);
    const initialAddress: string | undefined = initialParams[ESearchFilters.ADDRESS]
      ? initialParams[ESearchFilters.ADDRESS]?.toString()
      : undefined;

    const initialDateFrom = initialParams[ESearchFilters.DATE_FROM];
    const initialDateTo = initialParams[ESearchFilters.DATE_TO];
    const initialFlexibleDays = initialParams[ESearchFilters.FLEXIBLE_DAYS];
    const initialDates: IHeaderSearchFilterDates | undefined =
      initialDateFrom && initialDateTo
        ? {
            from: dayjs(initialDateFrom.toString(), QUERYSTRING_DATE_FORMAT).toDate(),
            to: dayjs(initialDateTo.toString(), QUERYSTRING_DATE_FORMAT).toDate(),
            flexible_days:
              initialFlexibleDays === undefined ? undefined : Number(initialFlexibleDays),
          }
        : undefined;

    const initialGuestsAdults = initialParams[ESearchFilters.GUESTS_ADULTS]
      ? parseInt(String(initialParams[ESearchFilters.GUESTS_ADULTS]), 10)
      : undefined;
    const initialGuestsChildren = initialParams[ESearchFilters.GUESTS_CHILDREN]
      ? parseInt(String(initialParams[ESearchFilters.GUESTS_CHILDREN]), 10)
      : undefined;
    const initialGuestsInfants = initialParams[ESearchFilters.GUESTS_INFANTS]
      ? parseInt(String(initialParams[ESearchFilters.GUESTS_INFANTS]), 10)
      : undefined;
    const initialGuestsSeatbelts = initialParams[ESearchFilters.SEATBELTS]
      ? parseInt(String(initialParams[ESearchFilters.SEATBELTS]), 10)
      : undefined;

    let initialGuestsSleeps;
    if (initialParams[ESearchFilters.SLEEPS]) {
      initialGuestsSleeps = parseInt(String(initialParams[ESearchFilters.SLEEPS]), 10);
    } else if (
      initialParams[ESearchFilters.GUESTS_ADULTS] ||
      initialParams[ESearchFilters.GUESTS_CHILDREN]
    ) {
      initialGuestsSleeps = Math.min(
        parseInt(String(initialParams[ESearchFilters.GUESTS_ADULTS]), 10) +
          parseInt(String(initialParams[ESearchFilters.GUESTS_CHILDREN]), 10),
        10,
      );
    } else {
      initialGuestsSleeps = undefined;
    }

    const initialFeatures = getParamAsString(initialParams[ESearchFilters.FILTER_FEATURE]);
    const initialGuestsFeatures = initialFeatures
      ? {
          accessible: initialFeatures.includes('accessible'),
          pet_friendly: initialFeatures.includes('pet_friendly'),
        }
      : undefined;

    const initialState = {
      address: initialAddress,
      dates: initialDates,
      guests: {
        adults: initialGuestsAdults,
        children: initialGuestsChildren,
        infants: initialGuestsInfants,
        seatbelts: initialGuestsSeatbelts,
        sleeps: initialGuestsSleeps,
        ...initialGuestsFeatures,
      },
    };

    dispatch(setHeaderSearchFilter(initialState));
    onCloseHandler();
  };

  const handleSubmit = () => {
    onSubmit?.();
    onCloseHandler();
  };

  const formattedDates = useMemo<string | null>(() => {
    if (!dates?.from || !dates?.to) {
      return null;
    }

    const datesLabel = formatDateFullRange(dates.from.toString(), dates.to.toString()) || '';

    if (dates.flexible_days) {
      return `${datesLabel} (±${dates.flexible_days})`;
    }

    return datesLabel;
  }, [dates]);

  const totalGuests = (guests?.adults || 0) + (guests?.children || 0) + (guests?.infants || 0) || 0;
  const guestsLabel = intl.formatMessage(
    {
      defaultMessage: '{totalGuests, plural, one {# guest} other {# guests}}',
      id: 'ErPlEg',
      description: 'Global Header > label for guests button on mobile bar',
    },
    {
      totalGuests,
    },
  );

  const datesLabel = formattedDates
    ? formattedDates
    : intl.formatMessage({
        defaultMessage: 'Add dates',
        id: 'zP42BU',
        description: 'Global Header > Placeholder text for dates button on mobile bar',
      });

  const onSelectFilterContentHandler = (selectedFilterContent: EFilterContent) => {
    setOpenFilterModal(true);
    setFilterContent(selectedFilterContent);
  };

  return (
    <>
      <Modal
        isOpen={isSearchModalOpen}
        onClose={onClose}
        variant={EModalVariants.Full}
        className="z-[1410]">
        <Modal.Actions className="top-5" />
        <Modal.Header inlineHeader={true} className="mb-6 !px-4 !pt-[14px] !pb-0">
          <Heading variant={EHeadingStyleVariant.H6}>
            <FormattedMessage defaultMessage="Edit your search" id="G4o+hV" />
          </Heading>
        </Modal.Header>
        <Modal.Content className="!mb-0 !px-4">
          <div className="flex flex-col gap-3">
            {!isCampgroundSearch && (
              <Selector
                name="location"
                ariaLabel={intl.formatMessage({
                  defaultMessage: 'Please select a location.',
                  id: '1h5k89',
                })}
                icon={GeneralMapPinOutlineIcon}
                label={intl.formatMessage({ defaultMessage: 'Location', id: 'rvirM2' })}
                description={
                  address?.split('•').map(part => part.trim())[0] ||
                  intl.formatMessage({ defaultMessage: 'Add location', id: 'XOc92d' })
                }
                value={address || ''}
                isSelected={!!address}
                onChange={() => onSelectFilterContentHandler(EFilterContent.ADDRESS)}
                className="[&>input]:h-0"
                contentWrapperClassName="!p-4"
                descriptionVariant={ETextStyleVariant.MediumBold}
                labelClassName="!text-sm text-gray-50"
              />
            )}
            <Selector
              name="dates"
              ariaLabel={intl.formatMessage({
                defaultMessage: 'Please select some dates.',
                id: '/1Vehk',
              })}
              icon={GeneralCalendarIcon}
              label={intl.formatMessage({ defaultMessage: 'Depart/Return', id: 'fxWYuK' })}
              description={datesLabel}
              value={formattedDates || ''}
              isSelected={!!formattedDates}
              onChange={() => onSelectFilterContentHandler(EFilterContent.DATES)}
              className="[&>input]:h-0"
              contentWrapperClassName="!p-4"
              descriptionVariant={ETextStyleVariant.MediumBold}
              labelClassName="!text-sm text-gray-50"
            />
            <Selector
              name="guests"
              ariaLabel={intl.formatMessage({
                defaultMessage: 'Please select some guests.',
                id: 'wGpMIJ',
              })}
              icon={GeneralUserUsersIcon}
              label={intl.formatMessage({ defaultMessage: 'Guest', id: 'zF7Cth' })}
              description={
                totalGuests
                  ? guestsLabel
                  : intl.formatMessage({ defaultMessage: 'Add guest details', id: 'Wo6T+x' })
              }
              value={guestsLabel || ''}
              isSelected={!!totalGuests}
              onChange={() => onSelectFilterContentHandler(EFilterContent.GUESTS)}
              className="[&>input]:h-0"
              contentWrapperClassName="!p-4"
              descriptionVariant={ETextStyleVariant.MediumBold}
              labelClassName="!text-sm text-gray-50"
            />
          </div>
          <div className="absolute bottom-0 left-0 w-full border-t border-gray-250">
            <div className="w-full p-4">
              <Button
                className="w-full"
                variant={EButtonColorVariant.Primary}
                label={intl.formatMessage({ defaultMessage: 'Search', id: 'xmcVZ0' })}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>

      {openFilterModal && filterContent && (
        <Modal
          isOpen={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          variant={EModalVariants.Bottom}
          className="z-[1410]"
          dialogClassName="h-screen">
          {filterContent === EFilterContent.ADDRESS && (
            <MobileSearchAddressModalContent
              onCloseHandler={() => setOpenFilterModal(false)}
              location={location}
              address={address}
              onChangeAddress={onChangeAddress}
              popularDestinations={popularDestinations}
              recentSearches={recentSearches}
              handleRecentSearchNavigation={handleRecentSearchNavigation}
              maxRecentSearches={maxRecentSearches}
            />
          )}
          {filterContent === EFilterContent.DATES && (
            <MobileSearchDatesModalContent
              onCloseHandler={() => setOpenFilterModal(false)}
              dates={dates}
              onChangeDates={onChangeDates}
            />
          )}
          {filterContent === EFilterContent.GUESTS && (
            <MobileSearchGuestsModalContent
              onCloseHandler={() => setOpenFilterModal(false)}
              guests={guests}
              onChangeGuests={onChangeGuests}
              isGuestOccupancyAvailable={isGuestOccupancyAvailable}
            />
          )}
        </Modal>
      )}
    </>
  );
};
