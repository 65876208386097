import querystring from 'query-string';

import { getUserCurrency } from '@/redux/selectors/currency';
import apiRequest from '@/services/apiRequest';
import { trackEvent } from '@/services/track-event';
import filterEmptyValues from '@/utility/filterEmptyValues';
import { getSearchApi } from '@/utility/getCoreApi';
import { logger } from '@/utility/logger';

export const SET_RENTALS = 'SET_RENTALS';
export const SET_PRICE_START = 'SET_PRICE_START';
export const SET_PRICE_END = 'SET_PRICE_END';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_VEHICLE_TYPES = 'SET_VEHICLE_TYPES';
export const SET_DATES_FILTER = 'SET_DATES_FILTER';
export const SET_ADULTS_GUEST_FILTER = 'SET_ADULTS_GUEST_FILTER';
export const SET_CHILDREN_GUEST_FILTER = 'SET_CHILDREN_GUEST_FILTER';
export const SET_PETS_GUEST_FILTER = 'SET_PETS_GUEST_FILTER';
export const SET_FESTIVAL_FILTER = 'SET_FESTIVAL_FILTER';
export const RESET_DATES_FILTER = 'RESET_DATES_FILTER';
export const RESET_GUESTS_FILTER = 'RESET_GUESTS_FILTER';
export const RESET_PRICE_FILTER = 'RESET_PRICE_FILTER';
export const RESET_VEHICLE_TYPES_FILTER = 'RESET_VEHICLE_TYPES_FILTER';
export const ADD_FILTERS = 'ADD_FILTERS';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_IS_FETCHED = 'SET_IS_FETCHED';
export const ENABLE_DELIVERABLE_FILTER = 'ENABLE_DELIVERABLE_FILTER';
export const DISABLE_DELIVERABLE_FILTER = 'DISABLE_DELIVERABLE_FILTER';

export const setRentals = rentalData => ({
  type: SET_RENTALS,
  meta: rentalData?.meta,
  rentals: rentalData?.data,
  isFetched: true,
});

const setIsFetched = isFetched => ({
  type: SET_IS_FETCHED,
  isFetched,
});

export const getRentals =
  ({ count = 8, weekend = false, radius = null, autoExpandRadius = false }) =>
  async (dispatch, getState) => {
    dispatch(setIsFetched(false));
    const { currentFilters } = getState().rentals;
    const filters = filterEmptyValues(currentFilters);
    const currency = getUserCurrency(getState());

    const requestParams = {
      ...filters,
      currency,
      homepage: true,
      remove: 'images',
      'page[limit]': count,
      raw_json: true,
      omit_aggregation: true,
      ...(radius && { radius }),
      ...(radius && autoExpandRadius && { auto_expand_radius: true }),
    };

    const query = querystring.stringify(requestParams);

    const RENTALS_URLS = weekend
      ? `${getSearchApi()}/rentals/weekend?${query}`
      : `${getSearchApi()}/rentals?${query}`;

    try {
      const rentalData = await apiRequest({ url: RENTALS_URLS });
      dispatch(setRentals(rentalData));
    } catch (error) {
      logger.captureExceptionWithDatadog(error, { url: error.request?._currentUrl });
    }
    dispatch(setIsFetched(true));
  };

export const goToRentals = address => async (dispatch, getState) => {
  const { currentFilters } = getState().rentals;
  const filters = filterEmptyValues({ ...currentFilters, address });

  delete filters['page[limit]'];

  trackEvent({
    event: 'Locality : Clicked to view all rentals',
    filters,
  });

  const query = querystring.stringify(filters);
  window.location.href = `/rv-search?${query}`;
};

export const addFilters = filterList => dispatch => {
  dispatch({
    type: ADD_FILTERS,
    filterList,
  });
};

export const applyFilters = () => dispatch => {
  dispatch({
    type: APPLY_FILTERS,
  });
};

export const applyFiltersAndGetRentals =
  ({ count = 8, weekend = false } = {}) =>
  async dispatch => {
    await dispatch(applyFilters());
    await dispatch(getRentals({ count, weekend }));
  };

export const resetFilters = () => async dispatch => {
  dispatch({
    type: RESET_FILTERS,
  });
};

export const setLocation = (lat, lng) => async dispatch => {
  dispatch({
    type: SET_LOCATION,
    location: [lat, lng].join(','),
  });
};
