import { SAVED_RENTALS, WISHLISTS_RECENTLY_SAVED } from '@/constants/urls';
import apiRequest from '@/services/apiRequest';
import { getCoreApi } from '@/utility/getCoreApi';

import { IData } from './types/search/rentals/id';

export const savedRentalsRequest = async () => {
  const rentalsData = await apiRequest<IData[]>(
    {
      url: `${getCoreApi()}${SAVED_RENTALS}`,
    },
    true,
    true,
  );

  return rentalsData?.data;
};

export const wishlistsRecentlySavedRequest = async () => {
  const response = await apiRequest<number[]>(
    {
      url: `${getCoreApi()}${WISHLISTS_RECENTLY_SAVED}`,
    },
    true,
    true,
  );

  // If we receive just IDs, we need to fetch the full rental details
  if (response?.data && Array.isArray(response.data)) {
    // If the response contains only IDs (numbers), fetch full rental details
    if (response.data.length > 0 && typeof response.data[0] === 'number') {
      // Fetch full rental details for these IDs
      const rentalsData = await apiRequest<IData[]>(
        {
          url: `${getCoreApi()}/rentals?ids=${response.data.join(',')}&full=true`,
        },
        true,
        true,
      );

      return rentalsData?.data;
    }

    // If the response already contains full rental objects, just return them
    return response.data as unknown as IData[];
  }

  return [];
};
