import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import uniq from 'lodash/uniq';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import FilterCheckbox from '@/components/route/search/FilterCheckbox/FilterCheckbox';
import Text from '@/components/switchback/Text/Text';
import TextInput from '@/components/switchback/TextInput/TextInput';
import {
  EVehicleHookupsCampgrounds,
  vehicleHookupsToCampgroundVehicleHookupsMap,
} from '@/constants/amenities';
import { TAmenitySlug } from '@/constants/amenitiesFilter';
import {
  ERulesFiltersForCampgrounds,
  getRulesFiltersForCampgrounds,
  THookupFilters,
  vehicleDetailsHookupFilters,
} from '@/constants/moreFilter';
import { ESearchFilters } from '@/constants/searchFilters';
import { EFilterMethod, setFormFilter } from '@/redux/modules/searchForm';
import { getSearchFormCampgroundsAmenitiesFilter } from '@/redux/selectors/search/searchForm';
import { TVehicleType } from '@/services/types/search/rentals/id';
import { IVehicleCampgrounds } from '@/services/types/search/vehicleCampgrounds';
import { addOrRemoveItem } from '@/utility/addOrRemoveItem';

import { INavbarSearch } from '../../../SearchHeader';

interface VehicleCampgroundsContentProps {
  className?: string;
  open?: boolean;
  onChangeVehicle?: INavbarSearch['onChangeVehicleCampgrounds'];
  onChangeVehicleDetail?: (detail: Partial<IVehicleCampgrounds>) => void;
  vehicle?: IVehicleCampgrounds;
  onConfirm?: () => void;
}

const VehicleCampgroundsContent: React.FC<
  React.PropsWithChildren<VehicleCampgroundsContentProps>
> = ({ open, onChangeVehicle, onChangeVehicleDetail, onConfirm, vehicle }) => {
  const intl = useIntl();

  const hookupOptions = Object.keys(vehicleDetailsHookupFilters).map(key => {
    const hookupDef = vehicleDetailsHookupFilters[key as THookupFilters];
    return {
      label: intl.formatMessage(hookupDef.label),
      icon: hookupDef.icon,
      value: vehicleHookupsToCampgroundVehicleHookupsMap[key as THookupFilters],
    };
  });

  const { allows_slideouts } = getRulesFiltersForCampgrounds();
  const campgroundsAmenitiesFilter = useSelector(getSearchFormCampgroundsAmenitiesFilter);
  const dispatch = useDispatch();

  const handleChange = (value: string, key: keyof IVehicleCampgrounds) => {
    const newData: IVehicleCampgrounds = {};
    if (key === 'type') {
      newData.type = value as TVehicleType;
    } else if (key === 'vehicle_length') {
      newData.vehicle_length = Number(value);
    } else if (key === 'hookups') {
      newData.hookups = addOrRemoveItem<EVehicleHookupsCampgrounds>(
        value as EVehicleHookupsCampgrounds,
        vehicle?.hookups || [],
      );
    }
    onChangeVehicle?.({ ...(vehicle || {}), ...newData });
    onChangeVehicleDetail?.({ [key]: value });
  };

  const handleSlideoutsCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = e.target;
      let updatedSelection = campgroundsAmenitiesFilter || [];

      if (checked) updatedSelection.push(value as TAmenitySlug);
      else updatedSelection = updatedSelection.filter(item => item !== value);
      dispatch(
        setFormFilter({
          filters: {
            [ESearchFilters.CAMPGROUND_FEATURES]: uniq(updatedSelection),
          },
          filterMethod: EFilterMethod.CHECKBOX,
        }),
      );
    },
    [campgroundsAmenitiesFilter, dispatch],
  );

  return (
    <div className={`${open ? 'block' : 'hidden'} p-2 md:p-6`}>
      <div className="grid gap-4 md:grid-cols-3">
        <div>
          <Text
            type="paragraph"
            className="mb-5 text-xs font-bold text-gray-500 uppercase semiHighlight">
            <FormattedMessage
              defaultMessage="Vehicle information"
              id="RFCys+"
              description="Guests filter > reset button"
            />
          </Text>

          <TextInput
            className="mt-4"
            name="length"
            type="number"
            max={100}
            min={0}
            label={intl.formatMessage({ defaultMessage: 'Vehicle Length', id: 'Yql79B' })}
            placeholder={intl.formatMessage({ defaultMessage: 'length in ft', id: 'pZInx+' })}
            value={vehicle?.vehicle_length}
            onChange={e => handleChange(e.target.value, 'vehicle_length')}
          />
        </div>

        <div>
          <Text
            type="paragraph"
            className="mb-5 text-xs font-bold text-gray-500 uppercase semiHighlight">
            <FormattedMessage
              defaultMessage="Site Essentials"
              id="4xlIuW"
              description="Site essentials filter"
            />
          </Text>
          {hookupOptions.map(option => {
            const key = option.value;
            const label = option.label;
            return (
              <FilterCheckbox
                key={key}
                name={key}
                checked={vehicle?.hookups?.includes(key)}
                label={label}
                icon={option.icon}
                onChange={() => handleChange(key, 'hookups')}
              />
            );
          })}
          {allows_slideouts && (
            <FilterCheckbox
              name={ERulesFiltersForCampgrounds.ALLOWS_SLIDEOUTS}
              checked={Boolean(
                campgroundsAmenitiesFilter?.includes(ERulesFiltersForCampgrounds.ALLOWS_SLIDEOUTS),
              )}
              icon={allows_slideouts.icon}
              BonfireIcon={allows_slideouts.BonfireIcon}
              label={intl.formatMessage(allows_slideouts.label)}
              onChange={handleSlideoutsCheckbox}
            />
          )}
        </div>
      </div>
      {!!onConfirm && (
        <Button
          className="mt-6 md:hidden"
          variant={EButtonColorVariant.Primary}
          label={intl.formatMessage({ defaultMessage: 'Confirm', id: 'N2IrpM' })}
          fullWidth
          onClick={onConfirm}
        />
      )}
    </div>
  );
};

export default React.memo(VehicleCampgroundsContent);
