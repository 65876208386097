import React from 'react';

import { IAutocompleteOption } from '@/components/switchback/Autocomplete/AutocompleteOptions';
import { IHeaderSearchFilterDates, IHeaderSearchFilterGuests } from '@/redux/modules/globalHeader';

import DesktopNavbarSearch from './DesktopNavbarSearch/DesktopNavbarSearch';
import MobileNavbarSearch from './MobileNavbarSearch/MobileNavbarSearch';

interface INavbarSearchProps {
  onSubmit?: () => void;
  handleRecentSearchNavigation?: (url: string) => void;
  location?: { lat: number; lng: number };
  address?: string;
  guests?: IHeaderSearchFilterGuests;
  dates?: IHeaderSearchFilterDates;
  recentSearches?: IAutocompleteOption[];
  maxRecentSearches: number;
  loading?: boolean;
  isHomePage?: boolean;
  onChangeAddress?: (address?: string) => void;
  onChangeDates: (address?: IHeaderSearchFilterDates) => void;
  onChangeGuests?: (guests?: IHeaderSearchFilterGuests) => void;
  onConfirmAddress?: (address?: string) => void;
  onConfirmDates?: (dates?: IHeaderSearchFilterDates) => void;
  onConfirmGuests?: (guests: IHeaderSearchFilterGuests) => void;
  className?: string;
  onClose?: () => void;
  onOpen?: () => void;
  popularDestinations?: { label: string }[];
  alertStatus?: boolean;
  isPromoSearch?: boolean;
  isCampgroundSearch?: boolean;
  isRvBundlesSearch?: boolean;
  isGuestOccupancyAvailable?: boolean;
  isSerpPage?: boolean;
  scrollToSearchFilter?: () => void;
}

const NavbarSearch: React.FC<INavbarSearchProps> = ({
  onSubmit,
  location,
  address,
  dates,
  guests,
  recentSearches = [],
  maxRecentSearches,
  loading = false,
  onChangeAddress,
  onChangeDates,
  onChangeGuests,
  onConfirmAddress,
  onConfirmDates,
  onConfirmGuests,
  className,
  onClose,
  onOpen,
  popularDestinations,
  alertStatus,
  handleRecentSearchNavigation,
  isPromoSearch,
  isCampgroundSearch,
  isRvBundlesSearch,
  isGuestOccupancyAvailable,
  isSerpPage,
  scrollToSearchFilter,
}) => {
  return (
    <>
      <div className={`hidden md:block ${className || ''}`}>
        <DesktopNavbarSearch
          location={location}
          address={address}
          dates={dates}
          guests={guests}
          recentSearches={recentSearches}
          maxRecentSearches={maxRecentSearches}
          onSubmit={onSubmit}
          loading={loading}
          onChangeAddress={onChangeAddress}
          onChangeDates={onChangeDates}
          onChangeGuests={onChangeGuests}
          onConfirmAddress={onConfirmAddress}
          onConfirmDates={onConfirmDates}
          onConfirmGuests={onConfirmGuests}
          popularDestinations={popularDestinations}
          alertStatus={alertStatus}
          handleRecentSearchNavigation={handleRecentSearchNavigation}
          isPromoSearch={isPromoSearch}
          isCampgroundSearch={isCampgroundSearch}
          isRvBundlesSearch={isRvBundlesSearch}
          isGuestOccupancyAvailable={isGuestOccupancyAvailable}
          isSerpPage={isSerpPage}
          scrollToSearchFilter={scrollToSearchFilter}
        />
      </div>
      <div className={`w-full md:hidden grid grid-flow-col auto-cols-fr ${className || ''}`}>
        <MobileNavbarSearch
          location={location}
          address={address}
          dates={dates}
          guests={guests}
          recentSearches={recentSearches}
          maxRecentSearches={maxRecentSearches}
          onChangeAddress={onChangeAddress}
          onChangeDates={onChangeDates}
          onChangeGuests={onChangeGuests}
          onClose={onClose}
          onOpen={onOpen}
          loading={loading}
          onSubmit={onSubmit}
          popularDestinations={popularDestinations}
          handleRecentSearchNavigation={handleRecentSearchNavigation}
          isCampgroundSearch={isCampgroundSearch}
          isRvBundlesSearch={isRvBundlesSearch}
          isGuestOccupancyAvailable={isGuestOccupancyAvailable}
          scrollToSearchFilter={scrollToSearchFilter}
        />
      </div>
    </>
  );
};

export default NavbarSearch;
