import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import { setODNEventProperties } from '@/services/analytics/campground/util';
import apiRequest from '@/services/apiRequest';
import searchApiRequest from '@/services/searchApiRequest';
import { IData as IListing, ISearchRentalsById } from '@/services/types/search/rentals/id';
import { getCoreApi } from '@/utility/getCoreApi';
import { logger } from '@/utility/logger';
import { IAction } from '@/utility/redux/action';
import { getAuthToken } from '@/utility/session';

import { getCurrency } from '../selectors/currency';

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
const LISTING_LOADING = 'listing/LISTING_LOADING';
const SET_LISTING = 'listing/SET_LISTING';
const ASK_OWNER_REQUEST = 'listing/ASK_OWNER_REQUEST';
const ASK_OWNER_RESPONSE = 'listing/ASK_OWNER_RESPONSE';
const ASK_OWNER_FAILURE = 'listing/ASK_OWNER_FAILURE';
const TOGGLE_MOBILE_BILL_MODULE = 'listing/OPEN_MOBILE_BILL';
const TOGGLE_CALENDAR = 'listing/TOGGLE_CALENDAR';
const TOGGLE_ASK_OWNER_MODAL = 'listing/TOGGLE_ASK_OWNER_MODAL';
const TOGGLE_INSURANCE_MODAL = 'listing/TOGGLE_INSURANCE_MODAL';
const SET_IS_LOADING = 'listing/SET_IS_LOADING';

interface ISetIsLoadingAction {
  type: typeof SET_IS_LOADING;
  payload: boolean;
}
interface IListingLoadingAction extends IAction {
  type: typeof LISTING_LOADING;
}

interface ISetListingAction extends IAction {
  type: typeof SET_LISTING;
  payload: IListing;
}
interface IToggleMobileBillAction extends IAction {
  type: typeof TOGGLE_MOBILE_BILL_MODULE;
  payload: boolean;
}
interface IToggleAskOwnerModalAction extends IAction {
  type: typeof TOGGLE_ASK_OWNER_MODAL;
  payload: boolean;
}

interface IToggleCalendarAction extends IAction {
  type: typeof TOGGLE_CALENDAR;
  payload: boolean;
}
interface IAskOwnerAction extends IAction {
  type: typeof ASK_OWNER_REQUEST;
}
interface IAskOwnerResponseAction extends IAction {
  type: typeof ASK_OWNER_RESPONSE;
}
interface IAskOwnerFailAction extends IAction {
  type: typeof ASK_OWNER_FAILURE;
  payload: string;
  error: true;
}

interface IToggleInsuranceModalAction extends IAction {
  type: typeof TOGGLE_INSURANCE_MODAL;
  payload: boolean;
}

type TAction =
  | IListingLoadingAction
  | ISetListingAction
  | IToggleMobileBillAction
  | IToggleCalendarAction
  | IToggleAskOwnerModalAction
  | IAskOwnerAction
  | IAskOwnerResponseAction
  | IAskOwnerFailAction
  | IToggleInsuranceModalAction
  | ISetIsLoadingAction;

export const setIsLoading = (payload: boolean): ISetIsLoadingAction => ({
  type: SET_IS_LOADING,
  payload,
});

const setListing = (payload: IListing): ISetListingAction => ({
  type: SET_LISTING,
  payload,
});

export const openMobileBillModule = (): IToggleMobileBillAction => ({
  type: TOGGLE_MOBILE_BILL_MODULE,
  payload: true,
});

export const closeMobileBillModule = (): IToggleMobileBillAction => ({
  type: TOGGLE_MOBILE_BILL_MODULE,
  payload: false,
});

export const openAskOwnerModal = (): IToggleAskOwnerModalAction => ({
  type: TOGGLE_ASK_OWNER_MODAL,
  payload: true,
});

export const closeAskHostModal = (): IToggleAskOwnerModalAction => ({
  type: TOGGLE_ASK_OWNER_MODAL,
  payload: false,
});

export const openCalendar = (): IToggleCalendarAction => ({
  type: TOGGLE_CALENDAR,
  payload: true,
});

export const closeCalendar = (): IToggleCalendarAction => ({
  type: TOGGLE_CALENDAR,
  payload: false,
});

export const getListing =
  (
    rentalId: string,
    locale?: string,
  ): ThunkAction<Promise<IListing>, TRootState, void, ISetListingAction | IListingLoadingAction> =>
  async (dispatch, getState) => {
    dispatch<IListingLoadingAction>({
      type: LISTING_LOADING,
    });

    const currency = getCurrency(getState());
    const endpoint = `rentals/${rentalId}`;

    return new Promise((resolve, reject) =>
      searchApiRequest<ISearchRentalsById>(endpoint, {
        currency,
        rejectOnError: true,
        locale,
      })
        .then(response => {
          if (!response?.data) {
            throw new Error('404');
          }

          dispatch(setListing(response.data));

          return resolve(response.data);
        })
        .catch(error => {
          logger.captureExceptionWithDatadog(error, { url: error.request?._currentUrl });

          return reject(error);
        }),
    );
  };

export const askHost =
  (
    message: string,
  ): ThunkAction<
    void,
    TRootState,
    void,
    IAskOwnerFailAction | IAskOwnerAction | IAskOwnerResponseAction
  > =>
  async (dispatch, getState) => {
    const state = getState();
    const token = getAuthToken();

    if (!state.listing.data || !token) {
      dispatch<IAskOwnerFailAction>({
        type: ASK_OWNER_FAILURE,
        payload: 'Missing authentication.',
        error: true,
      });
      return;
    }

    dispatch<IAskOwnerAction>({
      type: ASK_OWNER_REQUEST,
    });

    const {
      id: rentalId,
      owner: { id: ownerId },
    } = state.listing.data;

    const requestUrl = `${getCoreApi()}/bookings`;
    const requestData = {
      message,
      rental_id: rentalId,
      owner_id: ownerId,
      status: 'conversation',
    };

    apiRequest(
      {
        headers: {
          authorization: `Token=${token}`,
        },
        url: requestUrl,
        method: 'POST',
        data: requestData,
      },
      true,
    )
      .then(() => dispatch<IAskOwnerResponseAction>({ type: ASK_OWNER_RESPONSE }))
      .catch(error =>
        dispatch<IAskOwnerFailAction>({
          type: ASK_OWNER_FAILURE,
          payload: error?.error || error,
          error: true,
        }),
      );
  };

export interface IState {
  data: IListing | null;
  isAskingHost?: boolean;
  isCalendarOpen: boolean;
  hasAskHostSucceeded?: boolean | undefined;
  askHostError?: string | undefined;
  isMobileBillModuleOpen: boolean;
  isAskHostModalOpen: boolean;
  isDeliveryModalOpen: boolean;
  isInsuranceModalOpen: boolean;
  isLoading: boolean;
  isListingPage?: boolean;
}

export const initialState: IState = {
  data: null,
  isAskingHost: false,
  isCalendarOpen: false,
  isMobileBillModuleOpen: false,
  isAskHostModalOpen: false,
  isDeliveryModalOpen: false,
  isInsuranceModalOpen: false,
  isLoading: true,
};

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
export default function reducer(state = initialState, action: TAction): IState {
  switch (action.type) {
    case LISTING_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_LISTING:
      setODNEventProperties(action.payload.campground);
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case TOGGLE_MOBILE_BILL_MODULE:
      return {
        ...state,
        isMobileBillModuleOpen: action.payload,
      };
    case TOGGLE_CALENDAR:
      return {
        ...state,
        isCalendarOpen: action.payload,
      };
    case TOGGLE_ASK_OWNER_MODAL:
      return {
        ...state,
        isAskingHost: false,
        hasAskHostSucceeded: undefined,
        askHostError: undefined,
        isAskHostModalOpen: action.payload,
      };
    case ASK_OWNER_REQUEST:
      return {
        ...state,
        isAskingHost: true,
        hasAskHostSucceeded: undefined,
        askHostError: undefined,
      };
    case ASK_OWNER_RESPONSE:
      return {
        ...state,
        isAskingHost: false,
        hasAskHostSucceeded: true,
        askHostError: undefined,
      };
    case ASK_OWNER_FAILURE:
      return {
        ...state,
        isAskingHost: false,
        hasAskHostSucceeded: false,
        askHostError: action.payload,
      };
    case TOGGLE_INSURANCE_MODAL:
      return {
        ...state,
        isInsuranceModalOpen: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return { ...state };
  }
}
