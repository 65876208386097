import React, { createContext, useCallback, useContext, useState } from 'react';

interface IAppLayoutContextProps {
  headerHeight: number;
  setHeaderHeight: (height: number) => void;
}

const AppLayoutContext = createContext<IAppLayoutContextProps>({
  headerHeight: 0,
  setHeaderHeight: () => {},
});

export const AppLayoutProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<Omit<IAppLayoutContextProps, 'setHeaderHeight'>>({
    headerHeight: 0,
  });

  const setHeaderHeight = useCallback((height: number) => {
    setState(prevState => ({ ...prevState, headerHeight: height }));
  }, []);

  return (
    <AppLayoutContext.Provider value={{ ...state, setHeaderHeight }}>
      {children}
    </AppLayoutContext.Provider>
  );
};

export const useAppLayout = (): IAppLayoutContextProps => {
  return useContext(AppLayoutContext);
};
