import {
  AmenitiesAdaAdaIcon,
  AmenitiesPetsPetFriendlyIcon,
  Divider,
  ETextStyleVariant,
  NumberInput,
  Text,
  Toggle,
} from '@outdoorsyco/bonfire';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ESearchFilters } from '@/constants/searchFilters';
import { IHeaderSearchFilterGuests } from '@/redux/modules/globalHeader';
import { EFilterMethod } from '@/redux/modules/searchForm';
import { trackRenterSearchFilterUpdatedEvent } from '@/services/analytics/search';
import { ERentalType } from '@/services/analytics/types';

interface IGuestsContentProps {
  open: boolean;
  guests?: Partial<IHeaderSearchFilterGuests>;
  onChangeGuests?: (guests: IHeaderSearchFilterGuests) => void;
  isGuestOccupancyAvailable?: boolean;
}

const GuestsContent: React.FC<IGuestsContentProps> = ({
  open,
  guests,
  onChangeGuests,
  isGuestOccupancyAvailable,
}) => {
  const intl = useIntl();

  const adultsValue = guests?.adults || 0;
  const childrenValue = guests?.children || 0;
  const infantsValue = guests?.infants || 0;
  const petFriendlyValue = guests?.pet_friendly || false;
  const accessibleValue = guests?.accessible || false;
  const seatbeltsValue = guests?.seatbelts || 0;

  const handleChangeFilters = useCallback(
    (newValue: Partial<IHeaderSearchFilterGuests>) => {
      onChangeGuests?.({
        adults: adultsValue,
        children: childrenValue,
        ...(isGuestOccupancyAvailable && { infants: infantsValue }),
        pet_friendly: petFriendlyValue,
        accessible: accessibleValue,
        seatbelts: seatbeltsValue,
        ...newValue,
      });
    },
    [
      onChangeGuests,
      adultsValue,
      childrenValue,
      isGuestOccupancyAvailable,
      infantsValue,
      petFriendlyValue,
      accessibleValue,
      seatbeltsValue,
    ],
  );

  if (!open) {
    return null;
  }

  const handleChangeAdults = (value: number) => {
    trackRenterSearchFilterUpdatedEvent({
      rentalType: ERentalType.RV,
      newFilters: { [ESearchFilters.GUESTS_ADULTS]: value },
      previousFilters: { [ESearchFilters.GUESTS_ADULTS]: guests?.adults },
      filterType: [ESearchFilters.GUESTS_ADULTS],
      isSEO: false,
      filterMethod: EFilterMethod.INPUT,
    });
    handleChangeFilters({ adults: value });
  };

  const handleChangeChildren = (value: number) => {
    trackRenterSearchFilterUpdatedEvent({
      rentalType: ERentalType.RV,
      newFilters: { [ESearchFilters.GUESTS_CHILDREN]: value },
      previousFilters: { [ESearchFilters.GUESTS_CHILDREN]: guests?.children },
      filterType: [ESearchFilters.GUESTS_CHILDREN],
      isSEO: false,
      filterMethod: EFilterMethod.INPUT,
    });
    handleChangeFilters({ children: value });
  };

  const handleChangeInfants = (value: number) => {
    trackRenterSearchFilterUpdatedEvent({
      rentalType: ERentalType.RV,
      newFilters: { [ESearchFilters.GUESTS_INFANTS]: value },
      previousFilters: { [ESearchFilters.GUESTS_INFANTS]: guests?.infants },
      filterType: [ESearchFilters.GUESTS_INFANTS],
      isSEO: false,
      filterMethod: EFilterMethod.INPUT,
    });
    handleChangeFilters({ infants: value });
  };

  const handleChangePetFriendly = (value: boolean) => {
    trackRenterSearchFilterUpdatedEvent({
      rentalType: ERentalType.RV,
      newFilters: {
        [ESearchFilters.FILTER_FEATURE]: value ? ['pet_friendly'] : [],
      },
      previousFilters: {
        [ESearchFilters.FILTER_FEATURE]: guests?.accessible ? ['pet_friendly'] : [],
      },
      filterType: [ESearchFilters.FILTER_FEATURE],
      isSEO: false,
      filterMethod: EFilterMethod.RADIO,
    });
    handleChangeFilters({ pet_friendly: value });
  };

  const handleChangeAccessible = (value: boolean) => {
    trackRenterSearchFilterUpdatedEvent({
      rentalType: ERentalType.RV,
      newFilters: {
        [ESearchFilters.FILTER_FEATURE]: value ? ['accessible'] : [],
      },
      previousFilters: {
        [ESearchFilters.FILTER_FEATURE]: guests?.accessible ? ['accessible'] : [],
      },
      filterType: [ESearchFilters.FILTER_FEATURE],
      isSEO: false,
      filterMethod: EFilterMethod.RADIO,
    });
    handleChangeFilters({ accessible: value });
  };

  return (
    <div className="flex flex-col p-0 pointer-events-auto md:p-2 gap-4 w-80">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <Text variant={ETextStyleVariant.MediumBold}>
            <FormattedMessage defaultMessage="Adults" id="rePX4c" />
          </Text>
          <Text variant={ETextStyleVariant.SmallRegular} className="text-gray-50">
            <FormattedMessage defaultMessage="Age 13 or above" id="ttiMmk" />
          </Text>
        </div>
        <NumberInput
          ariaLabel={intl.formatMessage({
            defaultMessage: 'Adults number input',
            id: 'QI9jVh',
          })}
          value={adultsValue}
          onChange={handleChangeAdults}
          maxValue={10}
        />
      </div>
      <Divider />
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <Text variant={ETextStyleVariant.MediumBold}>
            <FormattedMessage defaultMessage="Children" id="kzlY5R" />
          </Text>
          <Text variant={ETextStyleVariant.SmallRegular} className="text-gray-50">
            <FormattedMessage defaultMessage="Age 3-12" id="xtI7bT" />
          </Text>
        </div>
        <NumberInput
          ariaLabel={intl.formatMessage({
            defaultMessage: 'Children number input',
            id: 'ElS1Or',
          })}
          value={childrenValue}
          onChange={handleChangeChildren}
          maxValue={10}
        />
      </div>
      <Divider />
      {isGuestOccupancyAvailable && (
        <>
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <Text variant={ETextStyleVariant.MediumBold}>
                <FormattedMessage defaultMessage="Infants" id="wHlQeN" />
              </Text>
              <Text variant={ETextStyleVariant.SmallRegular} className="text-gray-50">
                <FormattedMessage defaultMessage="Ages 2 or under" id="/cJQwH" />
              </Text>
            </div>
            <NumberInput
              ariaLabel={intl.formatMessage({
                defaultMessage: 'Infants number input',
                id: 'EBh6e/',
              })}
              value={infantsValue}
              onChange={handleChangeInfants}
              maxValue={10}
            />
          </div>
          <Divider />
        </>
      )}
      <div className="flex items-center justify-between my-3">
        <div className="flex items-center gap-2">
          <AmenitiesPetsPetFriendlyIcon className="text-xl font-medium" />
          <Text variant={ETextStyleVariant.MediumBold}>
            <FormattedMessage defaultMessage="Pet friendly" id="c8hn0D" />
          </Text>
        </div>
        <Toggle
          isSelected={petFriendlyValue}
          ariaLabel={intl.formatMessage({
            defaultMessage: 'Pet friendly toggle',
            id: 'gOLubl',
          })}
          onChange={handleChangePetFriendly}
        />
      </div>
      <Divider />
      <div className="flex items-center justify-between my-3">
        <div className="flex items-center gap-2">
          <AmenitiesAdaAdaIcon className="text-xl font-medium" />
          <Text variant={ETextStyleVariant.MediumBold}>
            <FormattedMessage defaultMessage="ADA accessible" id="MlZmsn" />
          </Text>
        </div>
        <Toggle
          isSelected={accessibleValue}
          ariaLabel={intl.formatMessage({
            defaultMessage: 'ADA accessible toggle',
            id: 'R6Zaoq',
          })}
          onChange={handleChangeAccessible}
        />
      </div>
    </div>
  );
};

export default GuestsContent;
