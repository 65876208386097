import {
  VehiclesVehiclesCamperVanDetailedIcon,
  VehiclesVehiclesClassADetailedIcon,
  VehiclesVehiclesClassBDetailedIcon,
  VehiclesVehiclesClassCDetailedIcon,
  VehiclesVehiclesFifthWheelDetailedIcon,
  VehiclesVehiclesFoldingTrailerDetailedIcon,
  VehiclesVehiclesRoofTentDetailedIcon,
  VehiclesVehiclesToyHaulerDetailedIcon,
  VehiclesVehiclesTravelTrailerDetailedIcon,
  VehiclesVehiclesTruckCamperDetailedIcon,
  VehiclesVehiclesUtilityTrailerDetailedIcon,
} from '@outdoorsyco/bonfire';
import { SVGProps } from 'react';
import { defineMessage, MessageDescriptor } from 'react-intl';

import {
  ARROW_LEFT_RIGHT,
  BOOKER_TOWABLE,
  CABIN,
  CANVAS_TENT,
  FRESH_WATER,
  FUEL,
  FUEL_TANK,
  GROSS_WEIGHT,
  MILEAGE,
  PROPANE_TANK,
  SEATBELTS,
  SEWAGE_TANK,
  SLEEPS,
  TRANSMISSION,
  TREEHOUSE,
  WATER_TANK,
} from '@/components/switchback/Icon/assets';
import { ICONS } from '@/components/switchback/Icon/Icon.constants';
import { IconType } from '@/components/switchback/Icon/IconComponent';
import {
  TStayCategories,
  TStayType,
  TVehicleStyle,
  TVehicleType,
} from '@/services/types/search/rentals/id';

export enum EVehicleDetailCategory {
  GENERAL = 'General',
  DRIVABLE = 'Drivable',
  Towable = 'Towable',
}

export enum EVehicleDetails {
  'SLEEPS' = 'sleeps',
  'SEATBELTS' = 'seatbelts',
  'VEHICLE_LENGTH' = 'vehicle_length',
  'WATER_TANK' = 'water_tank',
  'MILEAGE' = 'mileage',
  'FUEL_TYPE' = 'fuel_type',
  'FUEL_TANK' = 'fuel_tank',
  'TRANSMISSION' = 'transmission',
  'GRAY_TANK' = 'gray_tank',
  'SEWAGE_TANK' = 'sewage_tank',
  'PROPANE_TANK' = 'propane_tank',
  'GROSS_WEIGHT' = 'gross_weight',
  'HITCH_WEIGHt' = 'hitch_weight',
}

export const VEHICLE_DETAILS_ICONS: Record<EVehicleDetails, IconType> = {
  [EVehicleDetails.SLEEPS]: SLEEPS,
  [EVehicleDetails.SEATBELTS]: SEATBELTS,
  [EVehicleDetails.WATER_TANK]: FRESH_WATER,
  [EVehicleDetails.MILEAGE]: MILEAGE,
  [EVehicleDetails.FUEL_TYPE]: FUEL,
  [EVehicleDetails.FUEL_TANK]: FUEL_TANK,
  [EVehicleDetails.TRANSMISSION]: TRANSMISSION,
  [EVehicleDetails.GRAY_TANK]: WATER_TANK,
  [EVehicleDetails.SEWAGE_TANK]: SEWAGE_TANK,
  [EVehicleDetails.PROPANE_TANK]: PROPANE_TANK,
  [EVehicleDetails.GROSS_WEIGHT]: GROSS_WEIGHT,
  [EVehicleDetails.HITCH_WEIGHt]: GROSS_WEIGHT,
  [EVehicleDetails.VEHICLE_LENGTH]: ARROW_LEFT_RIGHT,
};

export const POSSIBLE_FUEL_TYPES = ['gas', 'gasoline', 'diesel', 'electric'];

export const SEO_VEHICLES = {
  airstream: 'Airstream',
  'travel-trailer': 'Travel Trailer',
  'camper-van': 'Camper Van',
  'sprinter-van': 'Sprinter Van',
  camper: 'Camper',
  'mercedes-van': 'Mercedes Van',
  'truck-camper': 'Truck Camper',
  'scamp-trailer': 'Scamp Trailer',
  thor: 'Thor',
  winnebago: 'Winnebago',
  'teardrop-trailer': 'Teardrop Trailer',
  trailer: 'Trailer',
};
interface IVehicleContent {
  icon: ICONS;
  label: MessageDescriptor;
  description?: MessageDescriptor;
}
interface IStayContent {
  icon: IconType;
  label: MessageDescriptor;
  description?: MessageDescriptor;
  types: TStayType[];
}

export const vehicleTypes: Record<TVehicleType, IVehicleContent> = {
  a: {
    icon: ICONS.CLASS_A,
    label: defineMessage({ defaultMessage: 'Class A', id: 'zPFmT8' }),
    description: defineMessage({
      defaultMessage:
        'A big RV with the amenities of a home. Drives like a bus. Great for delivery.',
      id: 'Ryr+tD',
    }),
  },
  b: {
    icon: ICONS.CLASS_B,
    label: defineMessage({ defaultMessage: 'Class B', id: 'mvi+fA' }),
    description: defineMessage({
      defaultMessage: 'A little bigger than a camper van but just as photogenic.',
      id: 'SAYsLj',
    }),
  },
  c: {
    icon: ICONS.CLASS_C,
    label: defineMessage({ defaultMessage: 'Class C', id: 'JfSTrp' }),
    description: defineMessage({
      defaultMessage:
        'Great for first timers and families who need more space. Drives like a truck.',
      id: 'rzM0R9',
    }),
  },
  'camper-van': {
    icon: ICONS.CAMPER_VAN,
    label: defineMessage({ defaultMessage: 'Camper van', id: 'nddYs1' }),
    description: defineMessage({
      defaultMessage:
        'The smallest fully enclosed RV. Drives like a van and loves posing for selfies.',
      id: 'el0kjX',
    }),
  },
  'fifth-wheel': {
    icon: ICONS.FIFTH_WHEEL,
    label: defineMessage({ defaultMessage: 'Fifth-Wheel', id: '+0WsJR' }),
    description: defineMessage({
      defaultMessage: 'Larger trailers that attach to a gooseneck extension in the truck bed.',
      id: 'bub7Dd',
    }),
  },
  'folding-trailer': {
    icon: ICONS.FOLDING_TRAILER,
    label: defineMessage({ defaultMessage: 'Folding Trailer', id: '5MnaZz' }),
    description: defineMessage({
      defaultMessage: 'A great way to transform average cars into adventure cars.',
      id: 't1fM5V',
    }),
  },
  other: {
    icon: ICONS.OTHER,
    label: defineMessage({ defaultMessage: 'Other', id: '/VnDMl' }),
    description: defineMessage({
      defaultMessage: 'All other types of drivable RVs. (We’re just as curious as you are.)',
      id: 'xdy+ht',
    }),
  },
  trailer: {
    icon: ICONS.TRAILER,
    label: defineMessage({ defaultMessage: 'Travel Trailer', id: 'UHTuE2' }),
    description: defineMessage({
      defaultMessage: 'Trailers for all types of towing vehicles, including SUVs and pickups.',
      id: 'cSB4HQ',
    }),
  },
  'tow-vehicle': {
    icon: ICONS.UTILITY_TRAILER,
    label: defineMessage({ defaultMessage: 'Tow Vehicle', id: 'dLS4Tw' }),
  },
  'toy-hauler': {
    icon: ICONS.TOY_HAULER,
    label: defineMessage({ defaultMessage: 'Toy Hauler', id: 'UxLgiU' }),
    description: defineMessage({
      defaultMessage: 'Living quarters in the front with space for hauling “toys” in the back.',
      id: 'To4sOX',
    }),
  },
  'truck-camper': {
    icon: ICONS.TRUCK_CAMPER,
    label: defineMessage({ defaultMessage: 'Truck Camper', id: 'U6Ddxd' }),
    description: defineMessage({
      defaultMessage: 'Drives like a truck and makes roughing it significantly less rough.',
      id: 'CYGYIj',
    }),
  },
};

export const stayCategories: Record<TStayCategories, IStayContent> = {
  cabins: {
    icon: CABIN,
    label: defineMessage({ defaultMessage: 'Cabins', id: 'gzzfAP' }),
    description: defineMessage({
      defaultMessage: 'Cozy tiny homes. Rustic barns. Plus, A-frames, cabins, and cottages.',
      id: 'MzCAjF',
    }),
    types: ['cabin', 'tiny-home', 'barn', 'a-frame', 'ranch', 'house', 'cottage'],
  },
  tents: {
    icon: CANVAS_TENT,
    label: defineMessage({ defaultMessage: 'Tents', id: 'Gzraks' }),
    description: defineMessage({
      defaultMessage: 'From basic camping to upscale glamping — tents, yurts, and tipis.',
      id: '9TOHdF',
    }),
    types: ['camping-tent', 'canvas-tent', 'yurt', 'tipi'],
  },
  campers: {
    icon: BOOKER_TOWABLE,
    label: defineMessage({ defaultMessage: 'Stationary campers', id: '47jy/8' }),
    description: defineMessage({
      defaultMessage: 'RVs and camping trailers all set up — and decked out — in a fixed location.',
      id: 'mflnsc',
    }),
    types: ['camper-rv'],
  },
  'unique-stays': {
    icon: TREEHOUSE,
    label: defineMessage({ defaultMessage: 'Unique stays', id: '8xarcd' }),
    description: defineMessage({
      defaultMessage: 'Built for oohs and aahs — treehouses, earth domes, huts, and more.',
      id: '6UltUu',
    }),
    types: ['hut', 'treehouse', 'dome', 'other'],
  },
};

export const vehicleStyleToTypeMap: Record<TVehicleStyle, TVehicleType[]> = {
  drivable: ['c', 'a', 'camper-van', 'b', 'truck-camper', 'other'],
  towable: ['trailer', 'fifth-wheel', 'toy-hauler', 'folding-trailer'],
};

export const newVehicleStyleToTypeMap: Record<TVehicleStyle, TVehicleType[]> = {
  drivable: ['c', 'b', 'a', 'truck-camper', 'camper-van', 'other'],
  towable: ['trailer', 'folding-trailer', 'fifth-wheel', 'toy-hauler'],
};

export const allVehicleStyles: TVehicleType[] = [
  ...newVehicleStyleToTypeMap.drivable,
  ...newVehicleStyleToTypeMap.towable,
];

interface IBonfireVehicleContent {
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  label: MessageDescriptor;
}

export const vehicleTypesBonfire: Record<TVehicleType, IBonfireVehicleContent> = {
  a: {
    icon: VehiclesVehiclesClassADetailedIcon,
    label: defineMessage({ defaultMessage: 'Class A', id: 'zPFmT8' }),
  },
  b: {
    icon: VehiclesVehiclesClassBDetailedIcon,
    label: defineMessage({ defaultMessage: 'Class B', id: 'mvi+fA' }),
  },
  c: {
    icon: VehiclesVehiclesClassCDetailedIcon,
    label: defineMessage({ defaultMessage: 'Class C', id: 'JfSTrp' }),
  },
  'camper-van': {
    icon: VehiclesVehiclesCamperVanDetailedIcon,
    label: defineMessage({ defaultMessage: 'Camper van', id: 'nddYs1' }),
  },
  'fifth-wheel': {
    icon: VehiclesVehiclesFifthWheelDetailedIcon,
    label: defineMessage({ defaultMessage: 'Fifth-Wheel', id: '+0WsJR' }),
  },
  'folding-trailer': {
    icon: VehiclesVehiclesFoldingTrailerDetailedIcon,
    label: defineMessage({ defaultMessage: 'Folding Trailer', id: '5MnaZz' }),
  },
  other: {
    icon: VehiclesVehiclesRoofTentDetailedIcon,
    label: defineMessage({ defaultMessage: 'Other', id: '/VnDMl' }),
  },
  trailer: {
    icon: VehiclesVehiclesTravelTrailerDetailedIcon,
    label: defineMessage({ defaultMessage: 'Travel Trailer', id: 'UHTuE2' }),
  },
  'tow-vehicle': {
    icon: VehiclesVehiclesUtilityTrailerDetailedIcon,
    label: defineMessage({ defaultMessage: 'Tow Vehicle', id: 'dLS4Tw' }),
  },
  'toy-hauler': {
    icon: VehiclesVehiclesToyHaulerDetailedIcon,
    label: defineMessage({ defaultMessage: 'Toy Hauler', id: 'UxLgiU' }),
  },
  'truck-camper': {
    icon: VehiclesVehiclesTruckCamperDetailedIcon,
    label: defineMessage({ defaultMessage: 'Truck Camper', id: 'U6Ddxd' }),
  },
};
