import { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';

import { EDriverFailedReasons, EDriverVerificationSection } from '@/constants/driverVerification';
import * as BookingActions from '@/redux/modules/booking/type';
import { TRootState } from '@/redux/rootReducer';
import { authUserSelector } from '@/redux/selectors/auth/verifications';
import { trackVerification } from '@/services/analytics/renter-dashboard';
import { RenterDashboardEventName } from '@/services/analytics/renter-dashboard/types';
import { parseRentalType } from '@/services/analytics/utils';
import apiRequest from '@/services/apiRequest';
import { ICancellationDetail } from '@/services/types/booking/cancellationDetail';
import { ICard } from '@/services/types/booking/card';
import type {
  IBooking,
  IBookingGuest,
  IUpdateBookingStatus,
} from '@/services/types/booking/details';
import { IFile } from '@/services/types/booking/file';
import { IInsuranceBundle } from '@/services/types/booking/insuranceBundle';
import { IBookingPayment } from '@/services/types/booking/payment';
import { IBookingService } from '@/services/types/booking/services';
import { IWeather } from '@/services/types/booking/weather';
import { IWithdrawalReason } from '@/services/types/booking/withdrawalReason';
import { IOwner } from '@/services/types/owner';
import { getRawBookingPayment } from '@/utility/booking';
import { getCoreApi } from '@/utility/getCoreApi';
import { getAuthToken } from '@/utility/session';

const FETCH_BOOKING_URL = `${getCoreApi()}/bookings/`;

interface IInsuranceBundleResponse {
  bundles: IInsuranceBundle[];
  requireSelection: boolean;
}

/**
 * fetch booking
 */
const fetchBookingPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_BOOKING_PENDING,
});

const fetchBookingFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_BOOKING_FAILURE,
  error: true,
});

const fetchBookingSuccessAction = (
  booking: IBooking,
  payment?: IBookingPayment,
): BookingActions.IBookingAction => {
  return {
    type: BookingActions.FETCH_BOOKING_SUCCESS,
    payload: { booking, payment },
  };
};

export const getBooking = (bookingId: string | number) => {
  return apiRequest<IBooking>(
    {
      url: `${FETCH_BOOKING_URL}${bookingId}`,
      method: 'GET',
    },
    true,
    true,
  );
};

export const fetchBooking =
  (
    bookingId: string,
    shouldSkipLoading = false,
  ): ThunkAction<void, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    // If we're refreshing the booking, don't force an intermediate loading state
    if (!shouldSkipLoading) {
      dispatch(fetchBookingPendingAction());
    }
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchBookingFailureAction());
      return;
    }

    try {
      const response = await getBooking(bookingId);

      if (response) {
        const payment = getRawBookingPayment(response.headers);
        dispatch(fetchBookingSuccessAction(response.data, payment));
      } else {
        dispatch(fetchBookingFailureAction());
      }
    } catch {
      dispatch(fetchBookingFailureAction());
    }
  };

/**
 * fetch booking services
 */
const fetchBookingServicesPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_BOOKING_SERVICES_PENDING,
});

const fetchBookingServicesFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_BOOKING_SERVICES_FAILURE,
  error: true,
});

const fetchBookingServicesSuccessAction = (
  bookingServices: Array<IBookingService>,
): BookingActions.IBookingAction => {
  return {
    type: BookingActions.FETCH_BOOKING_SERVICES_SUCCESS,
    payload: bookingServices,
  };
};

export const fetchBookingService =
  (
    bookingId: string | number,
  ): ThunkAction<void, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    dispatch(fetchBookingServicesPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchBookingServicesFailureAction());
      return;
    }

    try {
      const bookingServices: Array<IBookingService> | undefined = await apiRequest<
        Array<IBookingService>
      >(
        {
          url: `${FETCH_BOOKING_URL}${bookingId}/services/`,
          method: 'GET',
        },
        true,
      );
      if (bookingServices) {
        dispatch(fetchBookingServicesSuccessAction(bookingServices));
      } else {
        dispatch(fetchBookingServicesFailureAction());
      }
    } catch {
      dispatch(fetchBookingServicesFailureAction());
    }
  };

/**
 * fetch booking owner
 */
const FETCH_USER_URL = `${getCoreApi()}/users/`;
const fetchBookingOwnerPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_BOOKING_OWNER_PENDING,
});

const fetchBookingOwnerFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_BOOKING_OWNER_FAILURE,
  error: true,
});

const fetchBookingOwnerSuccessAction = (owner: IOwner): BookingActions.IBookingAction => {
  return {
    type: BookingActions.FETCH_BOOKING_OWNER_SUCCESS,
    payload: owner,
  };
};

export const fetchBookingOwner =
  (ownerId: number): ThunkAction<void, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    dispatch(fetchBookingOwnerPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchBookingOwnerFailureAction());
      return;
    }

    try {
      const owner = await apiRequest<IOwner>(
        {
          url: `${FETCH_USER_URL}${ownerId}`,
          method: 'GET',
        },
        true,
      );
      if (owner) {
        dispatch(fetchBookingOwnerSuccessAction(owner));
      } else {
        dispatch(fetchBookingOwnerFailureAction());
      }
    } catch {
      dispatch(fetchBookingOwnerFailureAction());
    }
  };

/**
 * fetch booking passengers
 */
const fetchBookingPassengerPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_BOOKING_PASSENGER_PENDING,
});

const fetchBookingGuestsuccessAction = (
  passenger: IBookingGuest[],
): BookingActions.IBookingAction => {
  return {
    type: BookingActions.FETCH_BOOKING_PASSENGER_SUCCESS,
    payload: passenger,
  };
};

const fetchBookingPassengerFailureAction = (): BookingActions.IBookingAction => {
  return {
    type: BookingActions.FETCH_BOOKING_PASSENGER_FAILURE,
    error: true,
  };
};

export const fetchBookingGuests =
  (bookingId: number): ThunkAction<void, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    dispatch(fetchBookingPassengerPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchBookingPassengerFailureAction());
      return;
    }

    try {
      const passengers = await apiRequest<IBookingGuest[]>(
        {
          url: `${FETCH_BOOKING_URL}${bookingId}/passengers`,
          method: 'GET',
        },
        true,
      );
      if (passengers) {
        dispatch(fetchBookingGuestsuccessAction(passengers));
      } else {
        dispatch(fetchBookingPassengerFailureAction());
      }
    } catch {
      dispatch(fetchBookingPassengerFailureAction());
    }
  };

/**
 * fetch insurance bundles
 */
const fetchInsuranceBundlesPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_INSURANCE_BUNDLES_PENDING,
});

const fetchInsuranceBundlesFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_INSURANCE_BUNDLES_FAILURE,
  error: true,
});

const fetchInsuranceBundlesSuccessAction = (
  bundles: IInsuranceBundle[],
): BookingActions.IBookingAction => {
  return {
    type: BookingActions.FETCH_INSURANCE_BUNDLES_SUCCESS,
    payload: bundles,
  };
};

export const fetchInsuranceBundles =
  (bookingId: number): ThunkAction<void, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    dispatch(fetchInsuranceBundlesPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchInsuranceBundlesFailureAction());
      return;
    }

    try {
      const { bundles } =
        (await apiRequest<IInsuranceBundleResponse>(
          {
            url: `${FETCH_BOOKING_URL}${bookingId}/bundles`,
            method: 'GET',
          },
          true,
        )) || {};
      if (bundles) dispatch(fetchInsuranceBundlesSuccessAction(bundles));
      else dispatch(fetchInsuranceBundlesFailureAction());
    } catch {
      dispatch(fetchInsuranceBundlesFailureAction());
    }
  };

/**
 * fetch booking files
 */
const FETCH_FILES_URL = `${getCoreApi()}/files`;
const fetchBookingFilesPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_BOOKING_FILES_PENDING,
});

const fetchBookingFilesFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_BOOKING_FILES_FAILURE,
  error: true,
});

const fetchBookingFilesSuccessAction = (
  bookingFile: IFile | undefined,
): BookingActions.IBookingAction => {
  return {
    type: BookingActions.FETCH_BOOKING_FILES_SUCCESS,
    payload: bookingFile,
  };
};

export const fetchBookingFiles =
  (bookingId: number): ThunkAction<void, TRootState, void, BookingActions.IBookingAction> =>
  async (dispatch, getStore) => {
    dispatch(fetchBookingFilesPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchBookingFilesFailureAction());
    }
    try {
      const files = await apiRequest<IFile[]>(
        {
          url: `${FETCH_FILES_URL}`,
          method: 'GET',
          params: {
            booking_id: bookingId,
            signable: true,
          },
        },
        true,
      );
      let file;
      if (files) {
        const authUser = authUserSelector(getStore());
        file = files.find(
          file =>
            file.signable &&
            file.signers?.some(signer => signer.signer_email_address === authUser?.email),
        );
        if (file) {
          dispatch(fetchBookingFilesSuccessAction(file));
        }
      }
    } catch {
      dispatch(fetchBookingFilesFailureAction());
    }
  };

/**
 * update booking insurance bundle
 */
const updateBookingInsuranceBundlePendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.UPDATE_BOOKING_INSURANCE_BUNDLE_PENDING,
});

const updateBookingInsuranceBundleFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.UPDATE_BOOKING_INSURANCE_BUNDLE_FAILURE,
  error: true,
});

const updateBookingInsuranceBundleSuccessAction = (
  bundle: IInsuranceBundle,
): BookingActions.IBookingAction => ({
  type: BookingActions.UPDATE_BOOKING_INSURANCE_BUNDLE_SUCCESS,
  payload: bundle,
});

export const updateBookingInsuranceBundle =
  (
    bookingId: number,
    bundleId: string,
  ): ThunkAction<Promise<boolean>, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    dispatch(updateBookingInsuranceBundlePendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(updateBookingInsuranceBundleFailureAction());
      return false;
    }

    try {
      const updatedBundle = await apiRequest<IInsuranceBundle>(
        {
          url: `${FETCH_BOOKING_URL}${bookingId}/bundles`,
          method: 'POST',
          data: {
            bundle_id: bundleId,
          },
        },
        true,
      );
      if (updatedBundle) {
        dispatch(updateBookingInsuranceBundleSuccessAction(updatedBundle));
        dispatch(fetchBooking(String(bookingId), true));
        return true;
      } else {
        dispatch(updateBookingInsuranceBundleFailureAction());
        return false;
      }
    } catch {
      dispatch(updateBookingInsuranceBundleFailureAction());
      return false;
    }
  };

/**
 * fetch user card
 */
const fetchUserCardPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_USER_CARD_PENDING,
});

const fetchUserCardFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_USER_CARD_FAILURE,
  error: true,
});

const fetchUserCardSuccessAction = (cards: ICard[]): BookingActions.IBookingAction => {
  return {
    type: BookingActions.FETCH_USER_CARD_SUCCESS,
    payload: cards,
  };
};

export const fetchUserCard =
  (ownerId: number): ThunkAction<void, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    dispatch(fetchUserCardPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchUserCardFailureAction());
    }
    try {
      const cards = await apiRequest<ICard[]>(
        {
          url: `${FETCH_USER_URL}${ownerId}/cards`,
          method: 'GET',
        },
        true,
      );
      if (cards) {
        dispatch(fetchUserCardSuccessAction(cards));
      } else {
        dispatch(fetchUserCardFailureAction());
      }
    } catch {
      dispatch(fetchUserCardFailureAction());
    }
    return;
  };

const addBookingServicesPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.ADD_BOOKING_SERVICES_PENDING,
});

const addBookingServicesFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.ADD_BOOKING_SERVICES_FAILURE,
  error: true,
});

const addBookingSuccessSuccessAction = (
  payload: IBookingService,
): BookingActions.IBookingAction => {
  return {
    type: BookingActions.ADD_BOOKING_SERVICES_SUCCESS,
    payload,
  };
};

export const addBookingService =
  (
    bookingId: number,
    serviceId: number,
    serviceData?: { [key: string]: any },
  ): ThunkAction<Promise<boolean>, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    dispatch(addBookingServicesPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchBookingFailureAction());
      return false;
    }

    try {
      const bookingService = await apiRequest<IBookingService>(
        {
          url: `${FETCH_BOOKING_URL}${bookingId}/services/${serviceId}`,
          method: 'POST',
          data: { data: serviceData || {} },
        },
        true,
      );
      if (bookingService) {
        await dispatch(addBookingSuccessSuccessAction(bookingService));
        await dispatch(fetchBooking(String(bookingId), true));
        return true;
      } else {
        dispatch(addBookingServicesFailureAction());
        return false;
      }
    } catch {
      dispatch(addBookingServicesFailureAction());
      return false;
    }
  };

const removeBookingServicesPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.REMOVE_BOOKING_SERVICES_PENDING,
});

const removeBookingServicesFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.REMOVE_BOOKING_SERVICES_FAILURE,
  error: true,
});

const removeBookingServicesSuccessAction = (payload: number): BookingActions.IBookingAction => {
  return {
    type: BookingActions.REMOVE_BOOKING_SERVICES_SUCCESS,
    payload,
  };
};

export const removeBookingService =
  (
    bookingId: number,
    serviceId: number,
  ): ThunkAction<Promise<boolean>, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    await dispatch(removeBookingServicesPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      await dispatch(fetchBookingFailureAction());
      return false;
    }

    try {
      await apiRequest(
        {
          url: `${FETCH_BOOKING_URL}${bookingId}/services/${serviceId}`,
          method: 'DELETE',
        },
        true,
      );
      await dispatch(removeBookingServicesSuccessAction(serviceId));
      await dispatch(fetchBooking(String(bookingId), true));
      return true;
    } catch {
      dispatch(removeBookingServicesFailureAction());
      return false;
    }
  };

const addBookingPassengersPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.ADD_BOOKING_PASSENGERS_PENDING,
});

const addBookingPassengersFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.ADD_BOOKING_PASSENGERS_FAILURE,
  error: true,
});

const addBookingPassengersSuccessAction = (
  payload: IBookingGuest,
): BookingActions.IBookingAction => {
  return {
    type: BookingActions.ADD_BOOKING_PASSENGERS_SUCCESS,
    payload,
  };
};

export const addBookingPassengers =
  ({
    bookingId,
    email,
    name,
    source,
    driver,
  }: {
    bookingId: number;
    email: string;
    name: string;
    source: string;
    driver: boolean;
  }): ThunkAction<Promise<boolean>, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    dispatch(addBookingPassengersPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(addBookingPassengersFailureAction());
      return false;
    }
    try {
      const passenger = await apiRequest<IBookingGuest>(
        {
          url: `${FETCH_BOOKING_URL}${bookingId}/invite`,
          method: 'POST',
          data: {
            email,
            name,
            driver,
            source,
          },
        },
        true,
      );
      if (!passenger) {
        await dispatch(addBookingPassengersFailureAction());
        return false;
      }
      await dispatch(addBookingPassengersSuccessAction(passenger));
      await dispatch(fetchBookingGuests(bookingId));
      return true;
    } catch {
      dispatch(addBookingPassengersFailureAction());
      return false;
    }
  };

const removeBookingPassengersPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.REMOVE_BOOKING_PASSENGERS_PENDING,
});

const removeBookingPassengersFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.REMOVE_BOOKING_PASSENGERS_FAILURE,
  error: true,
});

const removeBookingPassengersSuccessAction = (payload: number): BookingActions.IBookingAction => {
  return {
    type: BookingActions.REMOVE_BOOKING_PASSENGERS_SUCCESS,
    payload,
  };
};

export const removeBookingPassengers =
  ({
    bookingId,
    userId,
  }: {
    bookingId: number;
    userId: number;
  }): ThunkAction<Promise<boolean>, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    dispatch(removeBookingPassengersPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(removeBookingPassengersFailureAction());
      return false;
    }
    try {
      await apiRequest<IBookingGuest>(
        {
          url: `${FETCH_BOOKING_URL}${bookingId}/users/${userId}`,
          method: 'DELETE',
        },
        true,
      );

      dispatch(removeBookingPassengersSuccessAction(userId));
      await dispatch(fetchBookingGuests(bookingId));
      return true;
    } catch {
      dispatch(removeBookingPassengersFailureAction());
      return false;
    }
  };

export const resetBookingPassengersStatusAction = (): BookingActions.IBookingAction => {
  return {
    type: BookingActions.RESET_BOOKING_PASSENGERS_STATUS,
  };
};

/**
 * fetch withdrawal reasons
 */
const FETCH_WITHDRWAL_REASONS_URL = `${getCoreApi()}/static/cancellation-reasons/renter`;

const fetchWithdrawalReasonsPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_WITHDRAWAL_REASONS_PENDING,
});

const fetchWithdrawalReasonsFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_WITHDRAWAL_REASONS_FAILURE,
  error: true,
});

const fetchWithdrawalReasonsSuccessAction = (
  withdrawalReaons: IWithdrawalReason[],
): BookingActions.IBookingAction => {
  return {
    type: BookingActions.FETCH_WITHDRAWAL_REASONS_SUCCESS,
    payload: withdrawalReaons,
  };
};

type TFetchWithdrawalReasonsArgs = {
  isTowable?: boolean;
  isStay?: boolean;
};

export const fetchWithdrawalReasons =
  ({
    isTowable,
    isStay,
  }: TFetchWithdrawalReasonsArgs): ThunkAction<
    void,
    TRootState,
    void,
    BookingActions.IBookingAction
  > =>
  async dispatch => {
    dispatch(fetchWithdrawalReasonsPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchWithdrawalReasonsFailureAction());
      return;
    }
    try {
      const withdrawalReaons = await apiRequest<IWithdrawalReason[]>(
        {
          url: FETCH_WITHDRWAL_REASONS_URL,
          method: 'GET',
          params: {
            decline: true,
            is_towable: !!isTowable,
            rental_category: isStay ? 'stay' : 'rv',
          },
        },
        true,
      );
      if (withdrawalReaons) {
        dispatch(fetchWithdrawalReasonsSuccessAction(withdrawalReaons));
      } else {
        dispatch(fetchWithdrawalReasonsFailureAction());
      }
    } catch {
      dispatch(fetchWithdrawalReasonsFailureAction());
    }
  };

/**
 * update booking status
 */
const updateBookingStatusPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.UPDATE_BOOKING_STATUS_PENDING,
});

const updateBookingStatusFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.UPDATE_BOOKING_STATUS_FAILURE,
  error: true,
});

const updateBookingStatusSuccessAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.UPDATE_BOOKING_STATUS_SUCCESS,
});

export const updateBookingStatus =
  (
    bookingId: number,
    status: IUpdateBookingStatus,
    shouldSkipLoading = false,
  ): ThunkAction<Promise<boolean>, TRootState, void, BookingActions.IBookingAction> =>
  async (dispatch, getState) => {
    dispatch(updateBookingStatusPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(updateBookingStatusFailureAction());
      return false;
    }
    try {
      const rootState = getState();
      const userNoticeEvents =
        rootState.checkout.booking?.user_notice_events ||
        rootState.booking?.details?.data?.user_notice_events;

      const updatedBooking = await apiRequest<IBooking>(
        {
          url: `${getCoreApi()}/bookings/${bookingId}/status`,
          method: 'PATCH',
          data: {
            ...status,
            ...(!!userNoticeEvents?.length && {
              user_notices_acknowledged: userNoticeEvents
                .filter(event => event.guest_need_to_acknowledge)
                .map(event => event.id),
            }),
          },
        },
        true,
      );
      if (updatedBooking) {
        dispatch(updateBookingStatusSuccessAction());
        dispatch(fetchBooking(`${bookingId}`, shouldSkipLoading));
        return true;
      } else {
        dispatch(updateBookingStatusFailureAction());
        return false;
      }
    } catch {
      dispatch(updateBookingStatusFailureAction());
      return false;
    }
  };

/**
 * update driver verfication status
 */
const updateDriverVerificationPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.UPDATE_DRIVER_VERIFICATION_PENDING,
});

const updateDriverVerificationFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.UPDATE_DRIVER_VERIFICATION_FAILURE,
  error: true,
});

export const updateDriverVerification =
  (
    userId: number,
    inquiryId: string,
    bookingId: number,
    section: EDriverVerificationSection,
    isStay?: boolean,
    isCampground?: boolean,
  ): ThunkAction<void, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    dispatch(updateDriverVerificationPendingAction());
    const authToken = getAuthToken();
    const rentalType = parseRentalType(!!isStay, !!isCampground);
    if (!authToken) {
      dispatch(updateDriverVerificationFailureAction());
      trackVerification(
        {
          bookingID: bookingId,
          source: section,
          failReason: EDriverFailedReasons.NOT_AUTHORIZED,
          rentalType,
        },
        RenterDashboardEventName.ID_VERIFY_FAILED,
      );
      return;
    }

    try {
      await apiRequest<IBooking>(
        {
          url: `${getCoreApi()}/drivers/${userId}/id-verification?inquiry_id=${inquiryId}`,
          method: 'POST',
        },
        true,
      );
      dispatch(fetchBooking(`${bookingId}`, true));
      trackVerification(
        {
          bookingID: bookingId,
          source: section,
          rentalType,
        },
        RenterDashboardEventName.ID_VERIFY_COMPLETED,
      );
    } catch (error) {
      dispatch(updateDriverVerificationFailureAction());
      trackVerification(
        {
          bookingID: bookingId,
          source: section,
          failReason: (error as AxiosError).response?.data.error || EDriverFailedReasons.GENERIC,
          rentalType,
        },
        RenterDashboardEventName.ID_VERIFY_FAILED,
      );
    }
  };

/**
 * Fetch cancellation detail
 */
const fetchCancellationDetailPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_CANCELLATION_DETAIL_PENDING,
});

const fetchCancellationDetailSuccessAction = (
  cancellationDetail: ICancellationDetail,
): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_CANCELLATION_DETAIL_SUCCESS,
  payload: cancellationDetail,
});

const fetchCancellationDetailFailureAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_CANCELLATION_DETAIL_FAILURE,
  error: true,
});

export const fetchCancellationDetail =
  (bookingId: number): ThunkAction<void, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    dispatch(fetchCancellationDetailPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchCancellationDetailFailureAction());
      return;
    }

    try {
      const cancellationDetail = await apiRequest<ICancellationDetail>(
        {
          url: `${getCoreApi()}/bookings/${bookingId}/cancellation-details`,
          method: 'GET',
        },
        true,
      );
      if (!cancellationDetail) {
        dispatch(fetchCancellationDetailFailureAction());
      } else {
        dispatch(fetchCancellationDetailSuccessAction(cancellationDetail));
      }
    } catch {
      dispatch(fetchCancellationDetailFailureAction());
    }
  };

export const resetBookingData = (): BookingActions.IBookingAction => ({
  type: BookingActions.RESET_BOOKING_DATA,
});

// Weather

const fetchBookingWeatherSuccessAction = (payload: IWeather): BookingActions.IBookingAction => {
  return {
    type: BookingActions.FETCH_BOOKING_WEATHER_SUCCESS,
    payload,
  };
};

const fetchBookingWeatherPendingAction = (): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_BOOKING_WEATHER_PENDING,
});

export const fetchBookingWeatherFailureAction = (err: any): BookingActions.IBookingAction => ({
  type: BookingActions.FETCH_BOOKING_WEATHER_FAILURE,
  error: true,
  payload: err,
});

export const getWeatherService =
  (
    bookingId: number,
    data: { [key: string]: any },
  ): ThunkAction<Promise<any>, TRootState, void, BookingActions.IBookingAction> =>
  async dispatch => {
    dispatch(fetchBookingWeatherPendingAction());
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchBookingFailureAction());
    }

    try {
      const weather = await apiRequest<IWeather>(
        {
          url: `${FETCH_BOOKING_URL}${bookingId}/weather`,
          method: 'POST',
          data: data,
        },
        true,
      );
      if (weather) {
        await dispatch(fetchBookingWeatherSuccessAction(weather));
        return null;
      } else {
        dispatch(fetchBookingWeatherFailureAction({ error: 'error' }));
      }
    } catch (error) {
      dispatch(fetchBookingWeatherFailureAction(error));
      if (error.original_error) {
        if (error.original_error.includes('risk of adverse weather too high')) {
          return 'adverse_weather';
        }
        if (error.original_error.includes('time constraint error')) {
          return 'time_constraint';
        }
      }
    }

    return 'error';
  };
