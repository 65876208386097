import { IOwnerLocationsResponse } from '@/services/types/core/ownerLocations';
import { IReview } from '@/services/types/core/reviews/id';
import { IRentalResponse } from '@/services/types/search/rentals/id';
import { IUserResponse } from '@/services/types/search/users/id';
import { IAction } from '@/utility/redux/action';

export const FETCH_OWNER_PENDING = 'owner/FETCH_OWNER_PENDING';
export const FETCH_OWNER_FAILURE = 'owner/FETCH_OWNER_FAILURE';
export const FETCH_OWNER_SUCCESS = 'owner/FETCH_OWNER_SUCCESS';

export const FETCH_OWNER_RENTALS_PENDING = 'owner/FETCH_OWNER_RENTALS_PENDING';
export const FETCH_OWNER_RENTALS_FAILURE = 'owner/FETCH_OWNER_RENTALS_FAILURE';
export const FETCH_OWNER_RENTALS_SUCCESS = 'owner/FETCH_OWNER_RENTALS_SUCCESS';

export const FETCH_OWNER_STAYS_PENDING = 'owner/FETCH_OWNER_STAYS_PENDING';
export const FETCH_OWNER_STAYS_FAILURE = 'owner/FETCH_OWNER_STAYS_FAILURE';
export const FETCH_OWNER_STAYS_SUCCESS = 'owner/FETCH_OWNER_STAYS_SUCCESS';

export const FETCH_OWNER_REVIEWS_PENDING = 'owner/FETCH_OWNER_REVIEWS_PENDING';
export const FETCH_OWNER_REVIEWS_FAILURE = 'owner/FETCH_OWNER_REVIEWS_FAILURE';
export const FETCH_OWNER_REVIEWS_SUCCESS = 'owner/FETCH_OWNER_REVIEWS_SUCCESS';

export const FETCH_OWNER_LOCATIONS_PENDING = 'owner/FETCH_OWNER_LOCATIONS_PENDING';
export const FETCH_OWNER_LOCATIONS_FAILURE = 'owner/FETCH_OWNER_LOCATIONS_FAILURE';
export const FETCH_OWNER_LOCATIONS_SUCCESS = 'owner/FETCH_OWNER_LOCATIONS_SUCCESS';

export const FETCH_OWNER_RESET = 'owner/FETCH_OWNER_RESET';

interface IOwnerPendingAction extends IAction {
  type: typeof FETCH_OWNER_PENDING;
}

interface IOwnerSuccessAction extends IAction {
  type: typeof FETCH_OWNER_SUCCESS;
  payload: IUserResponse;
}

interface IOwnerFailureAction extends IAction {
  type: typeof FETCH_OWNER_FAILURE;
  error: boolean;
}

interface IOwnerRentalsPendingAction extends IAction {
  type: typeof FETCH_OWNER_RENTALS_PENDING;
}

interface IOwnerRentalsSuccessAction extends IAction {
  type: typeof FETCH_OWNER_RENTALS_SUCCESS;
  payload: IRentalResponse;
}

interface IOwnerRentalsFailureAction extends IAction {
  type: typeof FETCH_OWNER_RENTALS_FAILURE;
  error: boolean;
}

interface IOwnerStaysPendingAction extends IAction {
  type: typeof FETCH_OWNER_STAYS_PENDING;
}

interface IOwnerStaysSuccessAction extends IAction {
  type: typeof FETCH_OWNER_STAYS_SUCCESS;
  payload: IRentalResponse;
}

interface IOwnerStaysFailureAction extends IAction {
  type: typeof FETCH_OWNER_STAYS_FAILURE;
  error: boolean;
}

interface IOwnerReviewsPendingAction extends IAction {
  type: typeof FETCH_OWNER_REVIEWS_PENDING;
}

interface IOwnerReviewsSuccessAction extends IAction {
  type: typeof FETCH_OWNER_REVIEWS_SUCCESS;
  payload: IReview[];
}

interface IOwnerReviewsFailureAction extends IAction {
  type: typeof FETCH_OWNER_REVIEWS_FAILURE;
  error: boolean;
}

interface IOwnerLocationsPendingAction extends IAction {
  type: typeof FETCH_OWNER_LOCATIONS_PENDING;
}

interface IOwnerLocationsSuccessAction extends IAction {
  type: typeof FETCH_OWNER_LOCATIONS_SUCCESS;
  payload: IOwnerLocationsResponse;
}

interface IOwnerLocationsFailureAction extends IAction {
  type: typeof FETCH_OWNER_LOCATIONS_FAILURE;
  error: boolean;
}

interface IOwnerResetAction extends IAction {
  type: typeof FETCH_OWNER_RESET;
}

export type IOwnerAction =
  | IOwnerPendingAction
  | IOwnerSuccessAction
  | IOwnerFailureAction
  | IOwnerResetAction;
export type IOwnerRentalsAction =
  | IOwnerRentalsPendingAction
  | IOwnerRentalsSuccessAction
  | IOwnerRentalsFailureAction
  | IOwnerStaysPendingAction
  | IOwnerStaysSuccessAction
  | IOwnerStaysFailureAction;
export type IOwnerReviewsAction =
  | IOwnerReviewsPendingAction
  | IOwnerReviewsSuccessAction
  | IOwnerReviewsFailureAction;

export type IOwnerLocationsAction =
  | IOwnerLocationsPendingAction
  | IOwnerLocationsSuccessAction
  | IOwnerLocationsFailureAction;
