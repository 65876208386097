import { useEffect, useState } from 'react';

import { useBreakpoint } from './useBreakpoint';
import { ScrollDirection, useScrollDirection } from './useScrollDirection';

export const useFixedFooterVisibility = () => {
  const { isAboveDesktop } = useBreakpoint();

  // Use a state to make sure we don't have rehydration issues.
  const [visibility, setVisibility] = useState(false);
  const scrollingDirection = useScrollDirection({ skip: isAboveDesktop });

  useEffect(() => {
    if (isAboveDesktop) return setVisibility(false);

    // We need scroll top check only on initial render or when breakpoint changes,
    // so we are fine to use this check directly.
    setVisibility(window.scrollY === 0 || scrollingDirection === ScrollDirection.up);
  }, [isAboveDesktop, scrollingDirection]);

  return visibility;
};
