import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { APPLE_AUTH, FACEBOOK_COLORED, GOOGLE_COLORED } from '@/components/switchback/Icon/assets';
import Icon, { IconSize, IconType } from '@/components/switchback/Icon/IconComponent';
import { TLocale } from '@/config/locales';
import { LOGIN_PAGE, SIGN_UP_PAGE } from '@/constants/urls';
import { useLocaleSettings } from '@/hooks/useLocaleSettings';
import { TAccountType } from '@/services/types/auth/ILoginForm';
import { buildSocialAuthUrl } from '@/utility/buildSocialAuthUrl';
import { setItemInLocalStorage } from '@/utility/localstorage';

import Button, { ButtonSize, ButtonVariants } from '../switchback/Button/Button';
import ReCaptchaLegalNotice from './ReCaptchaLegalNotice';

interface IAuther {
  key: TAccountType;
  icon: IconType;
  onClick: () => void;
}

const socialAuthers: IAuther[] = [
  { key: 'apple', icon: APPLE_AUTH, onClick: () => null },
  { key: 'facebook', icon: FACEBOOK_COLORED, onClick: () => null },
  { key: 'google', icon: GOOGLE_COLORED, onClick: () => null },
];

const onSocialAuth = (method: TAccountType, locale: TLocale) => {
  setItemInLocalStorage('social_auth_method', method);
  setItemInLocalStorage(
    'social_auth_origin_url',
    window.location.pathname + window.location.search,
  );
  return (window.location.href = buildSocialAuthUrl(method, locale));
};

interface IProps {
  className?: string;
  signup?: boolean | undefined;
  onSignup?: () => void;
  onLogin?: () => void;
  isPage?: boolean;
  didAcceptTerms?: boolean;
}

const AccountFormTemplate: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  className = '',
  onSignup,
  onLogin,
  signup,
  isPage,
  didAcceptTerms,
}) => {
  const intl = useIntl();
  const labelLoginUsingSocialAuth = intl.formatMessage({
    defaultMessage: 'Or log in using',
    id: 'X0Gz6L',
  });
  const labelJoinUsingSocialAuth = intl.formatMessage({
    defaultMessage: 'Or join using...',
    id: 't9kXTd',
  });

  const linkLabelNoAccount = intl.formatMessage({
    defaultMessage: 'Don’t have an account yet?',
    id: 'qIp7NX',
  });
  const linkLabelHaveAccount = intl.formatMessage({
    defaultMessage: 'Already have an account?',
    id: 'uCk8r+',
  });

  const linkLabelCreateAccount = intl.formatMessage({ defaultMessage: 'Join now', id: 'd3LTWQ' });
  const linkLabelLoginAccount = intl.formatMessage({ defaultMessage: 'Login', id: 'AyGauy' });
  const { locale } = useLocaleSettings();

  return (
    <div className={`w-full ${className}`}>
      {children}
      <section className="px-8 mt-5 text-center social-auth">
        <p className="mb-4 text-gray-500">
          {signup ? labelJoinUsingSocialAuth : labelLoginUsingSocialAuth}
        </p>
        <div className="flex justify-center space-x-4">
          {socialAuthers.map((item: IAuther) => (
            <Button
              onClick={() => onSocialAuth(item.key, locale)}
              key={item.key}
              size={ButtonSize.small}
              disabled={signup && !didAcceptTerms}
              variant={ButtonVariants.darkGrayOutlined}>
              <Icon name={item.icon} size={IconSize.normal} />
            </Button>
          ))}
        </div>
        {signup && !didAcceptTerms && (
          <p className={'text-xs autotype400 text-left mt-3'}>
            <FormattedMessage
              defaultMessage="You must accept the Outdoorsy Terms of Service before signing up with a third-party account."
              id="5ek7Lz"
            />
          </p>
        )}
      </section>
      <div
        className={`flex flex-col items-center justify-center px-8  mt-5 border-t ${
          isPage ? 'mb-12' : ''
        }`}>
        <span className="py-5 leading-none text-gray-900 border-gray-100 md:px-8 transition-all duration-500">
          {signup ? linkLabelHaveAccount : linkLabelNoAccount}
        </span>
        {signup ? (
          <Button
            testid="login-btn"
            onClick={onLogin}
            href={onLogin ? undefined : LOGIN_PAGE}
            variant={ButtonVariants.primaryOutlined}>
            {linkLabelLoginAccount}
          </Button>
        ) : (
          <Button
            testid="join-now-btn"
            onClick={onSignup}
            href={onSignup ? undefined : SIGN_UP_PAGE}
            variant={ButtonVariants.primaryOutlined}>
            {linkLabelCreateAccount}
          </Button>
        )}
      </div>
      <div className="my-4">
        <ReCaptchaLegalNotice />
      </div>
    </div>
  );
};

export { AccountFormTemplate };
