import { useCallback, useEffect, useRef } from 'react';

import { useHelpMenuContext } from '@/components/common/help-menu/HelpMenuContext';
import { RV_CAMPSITE_BUNDLES_LANDING_PAGE } from '@/constants/urls';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useRouter } from '@/hooks/useRouter';

import { HelpMenu } from './HelpMenu';

const ROUTES_TO_HIDE_MENU = [
  '/',
  RV_CAMPSITE_BUNDLES_LANDING_PAGE,
  '/wishlists',
  '/wishlists/[id]',
  '/wishlists/recently-viewed',
];

export const HelpMenuContainer = () => {
  const helpContainerRef = useRef<HTMLDivElement>(null);
  const { isAboveDesktop } = useBreakpoint();
  const { style } = useHelpMenuContext();
  const router = useRouter();

  const hideHelpMenu = ROUTES_TO_HIDE_MENU.some(route => router.pathname === route);

  // Adjusts help menu position if sticky elements are near the bottom of the viewport
  const updatePositionAccordingToStickyElements = useCallback(() => {
    if (!helpContainerRef.current) return;
    const helpContainerElement = helpContainerRef.current;
    const stickyElements = document.querySelectorAll('[data-sticky="true"]');

    stickyElements.forEach(element => {
      const stickyElRect = element.getBoundingClientRect();
      // If the distance between the bottom of a sticky element and the bottom of the viewport is
      // less than or equal to THRESHOLD, we adjust the help menu's margin to make room for it. This avoids
      // overlap with sticky elements that stick to the bottom of the page.
      // In case, if a sticky element (like a floating button) is within 2 pixels of the bottom of the viewport,
      // the help menu will move up to prevent overlap, ensuring a clean UI.
      const THRESHOLD = 2;
      const distanceFromBottom = Math.abs(stickyElRect.bottom - window.innerHeight);
      const isNearBottom = distanceFromBottom <= THRESHOLD && distanceFromBottom !== 0;

      helpContainerElement.style.marginBottom = isNearBottom ? `${stickyElRect.height}px` : '0px';
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!isAboveDesktop) {
        updatePositionAccordingToStickyElements();
      }
    };

    const handleResize = () => {
      updatePositionAccordingToStickyElements();
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    updatePositionAccordingToStickyElements();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [updatePositionAccordingToStickyElements, isAboveDesktop]);

  return (
    !hideHelpMenu && (
      <div
        ref={helpContainerRef}
        className="fixed z-1299 bottom-6 right-6 transition-[margin-bottom] duration-300"
        style={style}>
        <HelpMenu />
      </div>
    )
  );
};
