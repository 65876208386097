import { ILodgingCampgrounds } from '@/services/types/search/lodgingCampgrounds';
import { IVehicle } from '@/services/types/search/vehicle';
import { IVehicleCampgrounds } from '@/services/types/search/vehicleCampgrounds';
import { IAction } from '@/utility/redux/action';

enum EHeaderSearchFilters {
  ADDRESS = 'address',
  DATES = 'dates',
  GUESTS = 'guests',
  VEHICLE_TYPE = 'vehicleType',
  VEHICLE = 'vehicle',
  VEHICLE_CAMPGROUNDS = 'vehicleCampgrounds',
  LODGING_CAMPGROUNDS = 'lodgingCampgrounds',
  DELIVERY = 'delivery',
  DELIVERY_ADDRESS = 'deliveryAddress',
  DELIVERY_STATIONARY = 'deliveryStationary',
}

const SET_SEARCH_FILTER = 'globalHeader/SET_SEARCH_FILTER';

export interface IHeaderSearchFilterDates {
  from?: Date;
  to?: Date;
  flexible_days?: number;
}

export interface IHeaderSearchFilterGuests {
  adults?: number;
  children?: number;
  pet_friendly?: boolean;
  accessible?: boolean;
  infants?: number;
  seatbelts?: number;
  sleeps?: number;
}

export enum EVehicleType {
  ALL = 'all',
  DRIVABLE = 'drivable',
  TOWABLE = 'towable',
  DELIVERABLES = 'deliverables',
}

interface IHeaderSearchFilters {
  [EHeaderSearchFilters.ADDRESS]?: string;
  [EHeaderSearchFilters.DATES]?: IHeaderSearchFilterDates;
  [EHeaderSearchFilters.GUESTS]?: IHeaderSearchFilterGuests;
  [EHeaderSearchFilters.VEHICLE_TYPE]?: EVehicleType;
  [EHeaderSearchFilters.VEHICLE]?: IVehicle;
  [EHeaderSearchFilters.VEHICLE_CAMPGROUNDS]?: IVehicleCampgrounds;
  [EHeaderSearchFilters.LODGING_CAMPGROUNDS]?: ILodgingCampgrounds;
}

interface ISetHeaderSearchFilterAction extends IAction {
  type: typeof SET_SEARCH_FILTER;
  payload: IHeaderSearchFilters;
}

type TAction = ISetHeaderSearchFilterAction;

export const setHeaderSearchFilter = (
  payload: IHeaderSearchFilters,
): ISetHeaderSearchFilterAction => ({
  type: SET_SEARCH_FILTER,
  payload,
});

type TState = IHeaderSearchFilters;

export const initialState: TState = {};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_SEARCH_FILTER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
