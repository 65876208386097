import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import { wishlistsRecentlySavedRequest } from '@/services/getSavedRentals';
import { IData } from '@/services/types/search/rentals/id';
import { IAction } from '@/utility/redux/action';

export const SET_SAVED_RENTALS = 'savedRentals/SET_SAVED_RENTALS';
export const FETCH_SAVED_RENTALS_SUCCESS = 'savedRentals/FETCH_SAVED_RENTALS_SUCCESS';
export const FETCH_SAVED_RENTALS_FAILURE = 'savedRentals/FETCH_SAVED_RENTALS_FAILURE';
export const FETCH_SAVED_RENTALS_LOAD = 'savedRentals/FETCH_SAVED_RENTALS_LOAD';

interface ISetSavedRentalsAction extends IAction {
  type: typeof SET_SAVED_RENTALS;
  payload: IData[];
}

interface IFetchSavedRentalsSuccessAction extends IAction {
  type: typeof FETCH_SAVED_RENTALS_SUCCESS;
  payload: IData[];
}

interface IFetchSavedRentalsLoadAction extends IAction {
  type: typeof FETCH_SAVED_RENTALS_LOAD;
}

interface IFetchSavedRentalsFailureAction extends IAction {
  type: typeof FETCH_SAVED_RENTALS_FAILURE;
}

type TActions =
  | ISetSavedRentalsAction
  | IFetchSavedRentalsSuccessAction
  | IFetchSavedRentalsLoadAction
  | IFetchSavedRentalsFailureAction;

export const setSavedRentals = (payload: IData[]): ISetSavedRentalsAction => ({
  type: SET_SAVED_RENTALS,
  payload,
});

export const fetchSavedRentalsSuccess = (payload: IData[]): IFetchSavedRentalsSuccessAction => ({
  type: FETCH_SAVED_RENTALS_SUCCESS,
  payload,
});

export const fetchSavedRentalsFailure = (payload: unknown): IFetchSavedRentalsFailureAction => ({
  type: FETCH_SAVED_RENTALS_FAILURE,
  payload,
});

export const fetchSavedRentalsLoad = (): IFetchSavedRentalsLoadAction => ({
  type: FETCH_SAVED_RENTALS_LOAD,
});

export const getSavedRentals =
  (): ThunkAction<void, TRootState, void, TActions> => async dispatch => {
    dispatch(fetchSavedRentalsLoad());

    try {
      const saveRentals = await wishlistsRecentlySavedRequest();
      dispatch(fetchSavedRentalsSuccess(saveRentals || []));
    } catch (error) {
      dispatch(fetchSavedRentalsFailure(error));
    }
  };

interface ISavedRentalState {
  data: IData[];
  isFetched: boolean;
  error: unknown;
}

export const initialState: ISavedRentalState = {
  data: [],
  isFetched: false,
  error: null,
};

export default function reducer(state = initialState, action: TActions) {
  switch (action.type) {
    case SET_SAVED_RENTALS:
      return {
        ...state,
        error: null,
        data: action.payload,
      };
    case FETCH_SAVED_RENTALS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isFetched: true,
      };
    case FETCH_SAVED_RENTALS_LOAD:
      return {
        ...state,
        error: null,
        isFetched: false,
      };
    case FETCH_SAVED_RENTALS_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload,
        isFetched: false,
      };
    default:
      return state;
  }
}
