import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { formatReviewData } from '@/utility/reviews';

type TListingData = TRootState['listing']['data'];
type TCampgroundListingData = TRootState['campgroundListing']['data'];
type TReviews = TRootState['reviews']['all']['data'];
type TQueryParams = TRootState['queryParams'];

export interface IReviewData {
  id: number;
  name: string;
  date: Date;
  photoUrl: string;
  ratingNumber: number;
  review: string;
  images: string[];
  tags: string[];
  ownerAnswer?: {
    name: string;
    answer: string;
  };
  openedImage: number | null;
  exclude_from_rating?: boolean;
}

export interface IGetReviewsData {
  averageRating: number;
  totalReviews: number;
  reviews: IReviewData[];
}

export const getReviewsData = (
  listing: TListingData | TCampgroundListingData,
  reviews: TReviews,
  queryParams: TQueryParams,
  limit?: number,
): IGetReviewsData | null => {
  if (!listing) {
    return null;
  }

  const { score, group_score, reviews_num } = listing;
  const childrenCount = 'children_count' in listing ? listing.children_count : 0;
  return {
    averageRating: childrenCount > 1 ? group_score : score,
    totalReviews: reviews_num,
    reviews:
      reviews?.slice(0, limit).map(review => formatReviewData(review, queryParams)) ||
      ([] as IReviewData[]),
  };
};

export const getReviewsSummary = createSelector<
  TRootState,
  TListingData,
  TReviews,
  TQueryParams,
  IGetReviewsData | null
>(
  state => state.listing.data,
  state => state.reviews.all.data,
  state => state.queryParams,
  (listing, reviews, queryParams) => getReviewsData(listing, reviews, queryParams),
);

export const getAllReviewsHeaderTitle = createSelector<
  TRootState,
  TListingData,
  string | undefined
>(
  state => state.listing.data,
  listing => listing?.name,
);

export const getAllReviews = createSelector<
  TRootState,
  TListingData,
  TReviews,
  TQueryParams,
  IGetReviewsData | null
>(
  state => state.listing.data,
  state => state.reviews.all.data,
  state => state.queryParams,
  getReviewsData,
);

export const getCurrentReview = (state: TRootState) => state.reviews.current;
