import { ThunkAction } from 'redux-thunk';

import { defaultLocale } from '@/config/locales';
import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { replaceListItemOrAdd } from '@/utility/array';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';

const GET_USER_CARDS_PENDING = 'creditCards/GET_USER_CARDS_PENDING';
export const GET_USER_CARDS_RESPONSE = 'creditCards/GET_USER_CARDS_RESPONSE';
const GET_USER_CARDS_FAILURE = 'creditCards/GET_USER_CARDS_FAILURE';

const ADD_USER_CARDS_PENDING = 'creditCards/ADD_USER_CARDS_PENDING';
const ADD_USER_CARDS_RESPONSE = 'creditCards/ADD_USER_CARDS_RESPONSE';
const ADD_USER_CARDS_FAILURE = 'creditCards/ADD_USER_CARDS_FAILURE';

const PATCH_USER_CARDS_PENDING = 'creditCards/PATCH_USER_CARDS_PENDING';
const PATCH_USER_CARDS_RESPONSE = 'creditCards/PATCH_USER_CARDS_RESPONSE';
const PATCH_USER_CARDS_FAILURE = 'creditCards/PATCH_USER_CARDS_FAILURE';

const DELETE_USER_CARDS_PENDING = 'creditCards/DELETE_USER_CARDS_PENDING';
const DELETE_USER_CARDS_RESPONSE = 'creditCards/DELETE_USER_CARDS_RESPONSE';
const DELETE_USER_CARDS_FAILURE = 'creditCards/DELETE_USER_CARDS_FAILURE';

export interface ICard {
  brand: string;
  created: string;
  default: boolean;
  exp_month: number;
  exp_year: number;
  id: number;
  last_four: string;
  owner_id: number;
  updated: string;
}

interface IAddCard {
  brand: string | null;
  default: boolean;
  exp_month: number | null;
  exp_year: number | null;
  last_four: string | null;
  owner_id: number;
  payment_processor: { country: string | null };
  stripe_token: string;
}

interface IPatchCard extends Partial<IAddCard> {
  id?: number;
}

interface IGetUserCardsPendingAction extends IAction {
  type: typeof GET_USER_CARDS_PENDING;
}

export interface IGetUserCardsResponseAction extends IAction {
  type: typeof GET_USER_CARDS_RESPONSE;
  payload: ICard[] | null;
}

interface IGetUserCardsFailAction extends IAction {
  type: typeof GET_USER_CARDS_FAILURE;
  payload: string;
  error: true;
  errorType?:
    | typeof GET_USER_CARDS_FAILURE
    | typeof ADD_USER_CARDS_FAILURE
    | typeof PATCH_USER_CARDS_FAILURE;
}

type TGetUserCardsAction =
  | IGetUserCardsPendingAction
  | IGetUserCardsResponseAction
  | IGetUserCardsFailAction;

interface IAddUserCardsPendingAction extends IAction {
  type: typeof ADD_USER_CARDS_PENDING;
}

interface IAddUserCardsResponseAction extends IAction {
  type: typeof ADD_USER_CARDS_RESPONSE;
  payload: ICard;
}

interface IAddUserCardsFailAction extends IAction {
  type: typeof ADD_USER_CARDS_FAILURE;
  payload: string;
  error: true;
}

type TAddUserCardsAction =
  | IAddUserCardsPendingAction
  | IAddUserCardsResponseAction
  | IAddUserCardsFailAction;

interface IPatchUserCardsPendingAction extends IAction {
  type: typeof PATCH_USER_CARDS_PENDING;
}

interface IPatchUserCardsResponseAction extends IAction {
  type: typeof PATCH_USER_CARDS_RESPONSE;
  payload: ICard;
}

interface IPatchUserCardsFailAction extends IAction {
  type: typeof PATCH_USER_CARDS_FAILURE;
  payload: string;
  error: true;
}

interface IDeleteUserCardsPendingAction extends IAction {
  type: typeof DELETE_USER_CARDS_PENDING;
}

interface IDeleteUserCardsResponseAction extends IAction {
  type: typeof DELETE_USER_CARDS_RESPONSE;
  payload: ICard;
}

interface IDeleteUserCardsFailAction extends IAction {
  type: typeof DELETE_USER_CARDS_FAILURE;
  payload: string;
  error: true;
}

type TPatchUserCardsAction =
  | IPatchUserCardsPendingAction
  | IPatchUserCardsResponseAction
  | IPatchUserCardsFailAction;

type TDeleteUserCardsAction =
  | IDeleteUserCardsPendingAction
  | IDeleteUserCardsResponseAction
  | IDeleteUserCardsFailAction;

type TAction =
  | TGetUserCardsAction
  | TAddUserCardsAction
  | TPatchUserCardsAction
  | TDeleteUserCardsAction;

type TGetUserCardsFunction = (
  userId: number,
  country?: string,
) => ThunkAction<Promise<void>, TRootState, void, TGetUserCardsAction>;

type TAddUserCardsFunction = (
  userId: number,
  cardData: IAddCard,
) => ThunkAction<Promise<ICard>, TRootState, void, TAddUserCardsAction>;

type TPatchUserCardsFunction = (
  userId: number,
  cardId: number,
  cardData: IPatchCard,
) => ThunkAction<Promise<ICard>, TRootState, void, TPatchUserCardsAction>;

type TDeleteUserCardsFunction = (
  userId: number,
  cardId: number,
) => ThunkAction<Promise<ICard>, TRootState, void, TDeleteUserCardsAction>;

export const fetchUserCards = (userId: number, country?: string) => {
  return apiRequest<ICard[]>(
    {
      url: `${getCoreApi()}/users/${userId}/cards`,
      params: { country: country || defaultLocale.country },
      method: 'GET',
    },
    true,
  );
};

export const getUserCards: TGetUserCardsFunction =
  (userId: number, country?: string) => async dispatch => {
    dispatch<IGetUserCardsPendingAction>({ type: GET_USER_CARDS_PENDING });

    return fetchUserCards(userId, country)
      .then(cards => {
        dispatch<IGetUserCardsResponseAction>({
          type: GET_USER_CARDS_RESPONSE,
          payload: cards,
        });
      })
      .catch(error => {
        dispatch<IGetUserCardsFailAction>({
          type: GET_USER_CARDS_FAILURE,
          payload: error,
          error: true,
        });
      });
  };

export const addUserCards: TAddUserCardsFunction = (userId, cardData) => async dispatch => {
  dispatch<IAddUserCardsPendingAction>({ type: ADD_USER_CARDS_PENDING });

  return new Promise((resolve, reject) =>
    apiRequest<ICard>(
      {
        url: `${getCoreApi()}/users/${userId}/cards`,
        method: 'POST',
        data: cardData,
      },
      true,
    )
      .then(response => {
        if (!response) {
          throw new Error('404');
        }
        return response;
      })
      .then(card => {
        dispatch<IAddUserCardsResponseAction>({
          type: ADD_USER_CARDS_RESPONSE,
          payload: card,
        });
        return resolve(card);
      })
      .catch(response => {
        dispatch<IAddUserCardsFailAction>({
          type: ADD_USER_CARDS_FAILURE,
          payload: response.error,
          error: true,
        });
        return reject(response.error);
      }),
  );
};

export const patchUserCards: TPatchUserCardsFunction =
  (userId, cardId, cardData) => async dispatch => {
    dispatch<IPatchUserCardsPendingAction>({ type: PATCH_USER_CARDS_PENDING });

    return new Promise((resolve, reject) =>
      apiRequest<ICard>(
        {
          url: `${getCoreApi()}/users/${userId}/cards/${cardId}`,
          method: 'PATCH',
          data: cardData,
        },
        true,
      )
        .then(response => {
          if (!response) {
            throw new Error('404');
          }
          return response;
        })
        .then(card => {
          dispatch<IPatchUserCardsResponseAction>({
            type: PATCH_USER_CARDS_RESPONSE,
            payload: card,
          });
          return resolve(card);
        })
        .catch(response => {
          dispatch<IPatchUserCardsFailAction>({
            type: PATCH_USER_CARDS_FAILURE,
            payload: response.error,
            error: true,
          });
          return reject(response.error);
        }),
    );
  };

export const deleteUserCards: TDeleteUserCardsFunction = (userId, cardId) => async dispatch => {
  dispatch<IDeleteUserCardsPendingAction>({ type: DELETE_USER_CARDS_PENDING });

  return new Promise((resolve, reject) =>
    apiRequest<ICard>(
      {
        url: `${getCoreApi()}/users/${userId}/cards/${cardId}`,
        method: 'DELETE',
      },
      true,
    )
      .then(card => {
        dispatch<IDeleteUserCardsResponseAction>({
          type: DELETE_USER_CARDS_RESPONSE,
          payload: card,
        });
        return resolve(card);
      })
      .catch(response => {
        dispatch<IDeleteUserCardsFailAction>({
          type: DELETE_USER_CARDS_FAILURE,
          payload: response.error,
          error: true,
        });
        return reject(response.error);
      }),
  );
};

interface ICreditCardState {
  cards: ICard[] | null;
  error?: string;
  isLoading: undefined | boolean;
}

export const initialState: ICreditCardState = {
  cards: null,
  isLoading: undefined,
};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case GET_USER_CARDS_PENDING:
    case ADD_USER_CARDS_PENDING:
    case PATCH_USER_CARDS_PENDING:
    case DELETE_USER_CARDS_PENDING:
      return {
        ...state,
        error: undefined,
        isLoading: true,
      };

    case GET_USER_CARDS_RESPONSE:
      return {
        ...state,
        error: undefined,
        isLoading: false,
        cards: action.payload,
      };

    case ADD_USER_CARDS_RESPONSE:
      return {
        ...state,
        error: undefined,
        isLoading: false,
        cards: state.cards ? [...state.cards, action.payload] : [action.payload],
      };

    case PATCH_USER_CARDS_RESPONSE:
      return {
        ...state,
        error: undefined,
        isLoading: false,
        cards: replaceListItemOrAdd(
          state.cards || [],
          action.payload,
          ({ id }) => id === action.payload.id,
        ),
      };

    case DELETE_USER_CARDS_RESPONSE:
      return {
        ...state,
        error: undefined,
        isLoading: false,
        cards: replaceListItemOrAdd(
          state.cards || [],
          action.payload,
          ({ id }) => id === action.payload.id,
        ),
      };

    case GET_USER_CARDS_FAILURE:
    case ADD_USER_CARDS_FAILURE:
    case PATCH_USER_CARDS_FAILURE:
    case DELETE_USER_CARDS_FAILURE:
      return {
        ...state,
        error: action.payload,
        errorType: action.type,
        isLoading: false,
      };

    default:
      return state;
  }
}
