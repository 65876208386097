import { useEffect, useState } from 'react';

import { breakpoint } from '@/constants/breakpoint';

import useWindowSize from './useWindowSize';

export function useBreakpoint() {
  const windowSize = useWindowSize();

  const [isMobile, setIsMobile] = useState(false);
  const [isAboveTablet, setIsAboveTablet] = useState(false);
  const [isAboveDesktop, setIsAboveDesktop] = useState(false);
  const [isAboveXL, setIsAboveXL] = useState(false);
  const [isAboveXXL, setIsAboveXXL] = useState(false);
  const [isAboveXXXL, setIsAboveXXXL] = useState(false);
  const [isAboveXXXXL, setIsAboveXXXXL] = useState(false);

  useEffect(() => {
    if (!windowSize.width) return;
    setIsMobile(windowSize.width < breakpoint.md);
    setIsAboveTablet(windowSize.width >= breakpoint.md);
    setIsAboveDesktop(windowSize.width >= breakpoint.lg);
    setIsAboveXL(windowSize.width >= breakpoint.xl);
    setIsAboveXXL(windowSize.width >= breakpoint.xxl);
    setIsAboveXXXL(windowSize.width >= breakpoint.xxxl);
    setIsAboveXXXXL(windowSize.width >= breakpoint.xxxxl);
  }, [windowSize.width]);

  return {
    isMobile,
    isAboveTablet,
    isAboveDesktop,
    isAboveXL,
    isAboveXXL,
    isAboveXXXL,
    isAboveXXXXL,
  };
}
