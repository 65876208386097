import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUser } from '@/hooks/useUser';
import { selectAuthenticated } from '@/redux/modules/auth/selectors';
import { fetchBookingsReview } from '@/redux/modules/bookingsReview/actions';
import { bookingsReviewSelector } from '@/redux/modules/bookingsReview/selectors';
import { removeCookie } from '@/redux/modules/cookies';
import { getUnreadConversations, getUser } from '@/redux/selectors/auth/user';
import { removeItemFromLocalStorage } from '@/utility/localstorage';
import { COOKIE_SESSION_NAME } from '@/utility/session';

import GlobalHeaderNav from './GlobalHeaderNav';

interface IProps {
  isHomePage?: boolean;
  noSearchNavbar?: boolean;
  isTransparent?: boolean;
  isHeaderWhite?: boolean;
  onClickSubItem?: () => void;
}

const GlobalHeaderNavContainer: React.FC<IProps> = ({
  isHomePage,
  noSearchNavbar,
  isTransparent,
  isHeaderWhite,
  onClickSubItem,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { logout } = useUser();

  const unreadConversations = useSelector(getUnreadConversations);
  const completedReviewTrips = useSelector(bookingsReviewSelector);
  const pendingReviews: number = (completedReviewTrips?.data || []).length || 0;

  const isAuthenticated = useSelector(selectAuthenticated);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(Boolean(unreadConversations));

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchBookingsReview());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    setHasUnreadMessages(Boolean(unreadConversations));
  }, [unreadConversations]);

  const handleSignout = () => {
    logout();
    dispatch(removeCookie(COOKIE_SESSION_NAME));
    removeItemFromLocalStorage('sessionRecentSearchesWithFilters');
  };

  return (
    <GlobalHeaderNav
      isHomePage={isHomePage}
      noSearchNavbar={noSearchNavbar}
      isTransparent={isTransparent}
      authenticated={isAuthenticated}
      userFirstName={user?.first_name}
      userLastName={user?.last_name}
      userAvatarUrl={user?.avatar_url}
      userCredits={user?.credits}
      userCurrency={user?.currency}
      hasUnreadMessages={hasUnreadMessages}
      hasPendingReviews={pendingReviews > 0}
      isOwner={user?.owner}
      isHeaderWhite={isHeaderWhite}
      unreadConversations={user?.unread_conversations}
      onClickSignout={handleSignout}
      onClickSubItem={onClickSubItem}
    />
  );
};

export default GlobalHeaderNavContainer;
