import { EToastVariant, Toast, useBonfire } from '@outdoorsyco/bonfire';
import React, { useCallback } from 'react';

export { EToastVariant } from '@outdoorsyco/bonfire';

export const useToast = () => {
  const { showToast, closeToast } = useBonfire();

  const customToast = useCallback(
    (content: JSX.Element) => {
      return showToast(content, {
        position: 'bottom-right',
        bodyClassName: 'w-[calc(100vw-2rem)] sm:w-[450px]',
        autoClose: 8000,
      });
    },
    [showToast],
  );

  return {
    showToast: useCallback(
      (variant: EToastVariant, title: string, content: string) => {
        return customToast(<Toast variant={variant} title={title} content={content} />);
      },
      [customToast],
    ),
    showCustomToast: customToast,
    closeToast,
  };
};
