import { ENotificationBadgeSize, NotificationBadge } from '@outdoorsyco/bonfire';
import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';

interface IProps {
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
  unreadConversations?: number;
}

const GlobalHeaderNavMyAccountAvatar: React.FC<IProps> = ({
  firstName,
  lastName,
  avatarUrl = '',
  unreadConversations,
}) => {
  const intl = useIntl();
  let Content: ReactElement = <>{intl.formatMessage({ defaultMessage: 'Me', id: 'TdbPNK' })}</>;

  if (avatarUrl) {
    Content = (
      <ResponsiveImage
        src={avatarUrl}
        sourceSizes={['square40', 'square80']}
        className="object-cover w-full"
        role="presentation"
        alt=""
      />
    );
  } else if (firstName || lastName) {
    Content = (
      <>
        {firstName?.[0]?.toUpperCase()}
        {lastName?.[0]?.toUpperCase()}
      </>
    );
  }

  return (
    <div className="relative inline-block">
      <div className="inline-flex items-center justify-center overflow-hidden text-primary-contrast bg-primary-800 rounded-[4px] w-[24px] h-[24px] lg:w-[32px] lg:h-[32px] autoType200">
        {Content}
      </div>
      {!!unreadConversations && (
        <div className="absolute [&>div]:bg-green-500 -top-3 right-[-8px]">
          <NotificationBadge
            ariaLabel={intl.formatMessage({
              defaultMessage: 'Unread messages',
              id: 'pUjlgQ',
            })}
            count={unreadConversations}
            size={ENotificationBadgeSize.Small}
          />
        </div>
      )}
    </div>
  );
};

export default GlobalHeaderNavMyAccountAvatar;
