import Script from 'next/script';
import React, { useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useIntl } from 'react-intl';

import { useRouter } from '@/hooks/useRouter';
import { isSSR } from '@/utility/isSSR';

import { OneTrustText } from './OneTrustText';

const COOKIE_POLICY_PAGES = ['/cookie-policy'];

export const OneTrust = () => {
  const intl = useIntl();
  const router = useRouter();

  const shouldShowCookiesBanner = COOKIE_POLICY_PAGES.includes(router.pathname);

  useEffect(() => {
    // Observes changes in the DOM to handle cases where the cookies banner is not initially rendered.
    // This ensures the cookies banner is hidden or shown based on the shouldShowCookiesBanner state,
    // even if it dynamically appears later.
    const observer = new MutationObserver(() => {
      const cookiesBanner = document.getElementById('ot-sdk-btn-floating');
      if (cookiesBanner) {
        cookiesBanner.style.display = !shouldShowCookiesBanner ? 'none' : '';
        observer.disconnect();
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
  }, [shouldShowCookiesBanner]);

  useEffect(() => {
    // Skip execution if the code is running on the server
    if (isSSR()) return;

    const observeBanner = () => {
      const observer = new MutationObserver(() => {
        const bannerSdk = document.getElementById('onetrust-banner-sdk');
        if (bannerSdk) {
          const policyText = document.getElementById('onetrust-policy-text');
          // Replace the default policy text with a custom message
          if (policyText?.innerHTML)
            // Use intl as a prop since renderToStaticMarkup is not wrapped in IntlProvider
            policyText.innerHTML = renderToStaticMarkup(<OneTrustText intl={intl} />);

          const acceptCookies = document.getElementById('onetrust-accept-btn-handler');
          if (acceptCookies?.innerText)
            acceptCookies.innerText = intl.formatMessage({
              defaultMessage: 'Accept cookies',
              id: 'H7k2cr',
            });

          const rejectAllCookies = document.getElementById('onetrust-reject-all-handler');
          if (rejectAllCookies?.innerText)
            rejectAllCookies.innerText = intl.formatMessage({
              defaultMessage: 'Reject All',
              id: 'htOj7a',
            });

          const doNotSellMyInformation = document.getElementById('onetrust-pc-btn-handler');
          if (doNotSellMyInformation?.innerText)
            doNotSellMyInformation.innerText = intl.formatMessage({
              defaultMessage: 'Do Not Sell My Information',
              id: 'gOKh4Y',
            });

          // Hide the "Do Not Sell My Information" button if the "Reject All" button is present
          // Some OneTrust locales do not have the "Reject All" button, so we need to check for its presence
          // before deciding to hide the "Do Not Sell My Information" button
          if (!rejectAllCookies && doNotSellMyInformation) {
            doNotSellMyInformation.classList.add('!block');
          } else if (doNotSellMyInformation) {
            doNotSellMyInformation.classList.add('!hidden');
          }

          // Stop observing changes once the banner has been updated
          observer.disconnect();
        }
      });

      // Observe the DOM for the presence of the OneTrust banner
      observer.observe(document.body, { childList: true, subtree: true });
    };

    observeBanner();
  }, [intl]);

  useEffect(() => {
    const adjustMargin = () => {
      const footer = document.querySelector('footer');
      const floatingButton = document.getElementById('ot-sdk-btn-floating');
      if (footer && floatingButton) {
        const footerVisible =
          footer.getBoundingClientRect().top < window.innerHeight &&
          footer.getBoundingClientRect().bottom > 0;

        if (footerVisible) {
          floatingButton.style.marginBottom = '60px'; // Hight of footer on mobile
        } else {
          floatingButton.style.marginBottom = '';
        }
      }
    };

    window.addEventListener('scroll', adjustMargin);
    return () => {
      window.removeEventListener('scroll', adjustMargin);
    };
  }, []);

  return (
    <Script
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-domain-script={process.env.oneTrustDomainScript}
    />
  );
};
