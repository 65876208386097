import { Text } from '@outdoorsyco/bonfire';
import { clsx } from 'clsx';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { AnimateInsertion } from '@/components/switchback/AnimateInsertion';
import { useUser } from '@/hooks/useUser';
import { getAuthenticatedUser } from '@/redux/selectors/auth/user';

export const Impersonated = memo(function Impersonated() {
  const user = useSelector(getAuthenticatedUser);
  const { logout } = useUser();

  if (!user?.impersonated) return null;

  const getEnv = () => {
    if (typeof window === 'undefined') return '';
    if (location.hostname.includes('staging')) return 'Staging';
    if (location.hostname.includes('preview')) return 'Preview';
    if (location.hostname.includes('outdoorsy')) return 'Production';
    return 'Development';
  };

  const handleSignout = () => {
    logout();
  };

  const isProduction = getEnv() === 'Production';
  const wrapperStyles = clsx('flex items-center justify-around w-full px-4 text-white h-[40px]', {
    'bg-yellow-600': !isProduction,
    'bg-[#ea3e44]': isProduction,
  });

  return (
    <AnimateInsertion>
      <div className={wrapperStyles}>
        <Text className="break-words">
          <span className="font-bold">Logged in as: </span>
          <span className="block sm:inline">{`${user.first_name} ${user.last_name}`}</span>
        </Text>
        <Text className="break-words">
          <span className="font-bold">Environment: </span>
          <span className="block sm:inline">{getEnv()}</span>
        </Text>
        <button onClick={handleSignout} className="text-white cursor-pointer">
          <FormattedMessage defaultMessage="Exit" id="E2sGaF" />
        </button>
      </div>
    </AnimateInsertion>
  );
});
