import {
  EIconButtonSize,
  EIconButtonVariant,
  GeneralHelpIcon,
  IconButton,
  TPopoverTriggerProps,
} from '@outdoorsyco/bonfire';
import { forwardRef } from 'react';

import css from './HelpButton.module.css';

export const HelpButton = forwardRef<HTMLButtonElement, TPopoverTriggerProps>(
  ({ className: _className, ...props }, ref) => {
    return (
      <IconButton
        ref={ref}
        icon={GeneralHelpIcon}
        size={EIconButtonSize.Medium}
        variant={EIconButtonVariant.Secondary}
        className={css.helpButton}
        {...props}
      />
    );
  },
);

HelpButton.displayName = 'HelpButton';
