import { IData } from '@/services/types/search/rentals/id';

/**
 * Calculates the updated favorite count for a rental item.
 *
 * This function adjusts the favorite count optimistically, without refetching the rental data from the server.
 * We use this approach to maintain a seamless user experience by avoiding unnecessary network requests.
 *
 * @param {IData | null} rentalTile - The rental data object, containing the favorite count and current favorite status.
 * @param {boolean} isFavorite - The current favorite status being applied (true for adding to favorites, false for removing).
 * @returns {number} - The adjusted favorite count.
 */
export const getOptimisticFavoriteCount = (
  rentalTile: IData | null,
  isFavorite: boolean,
): number => {
  if (!rentalTile) return 0;

  const { favorite_count = 0, favorite } = rentalTile;

  // Increase count if the item is being favorited and was not previously favorited.
  if (isFavorite && !favorite) return favorite_count + 1;

  // Decrease count if the item is being unfavorited and was previously favorited.
  if (!isFavorite && favorite) return Math.max(favorite_count - 1, 0);

  return favorite_count;
};
