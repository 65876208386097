// this interface keeps in line with the IFavorite to reuse the funtionality in maprentaltotiles
// added_at is not used for wishlists

export interface IFavorite {
  added_at: string;
  id: number;
  rental_id: number;
  wishlist_creator_id?: number;
  wishlist_name?: string;
  wishlist_id?: number;
}

interface IWishlistsState {
  data: IFavorite[];
}

const initialState: IWishlistsState = {
  data: [],
};

// Action Types
const SET_WISHLISTED_RENTALS = 'wishlists/SET_DATA';

interface ISetWishlistedRentalsAction {
  type: typeof SET_WISHLISTED_RENTALS;
  payload: IFavorite[];
}

type TWishlistsAction = ISetWishlistedRentalsAction;

// Action Creators

export const setWishlistedRentals = (data: IFavorite[]): ISetWishlistedRentalsAction => ({
  type: SET_WISHLISTED_RENTALS,
  payload: data,
});

export default function reducer(state = initialState, action: TWishlistsAction): IWishlistsState {
  switch (action.type) {
    case SET_WISHLISTED_RENTALS:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
}
