import {
  createContext,
  CSSProperties,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type THelpMenuContextValue = {
  style?: CSSProperties;
  setStyle: Dispatch<SetStateAction<CSSProperties>>;
};

const defaultHelpMenuContextValue: THelpMenuContextValue = {
  style: {},
  setStyle: () => {},
};

const HelpMenuContext = createContext<THelpMenuContextValue>(defaultHelpMenuContextValue);

type THelpMenuProviderProps = PropsWithChildren & {
  useWindowAsScrollContainer?: boolean;
};

export const HelpMenuProvider = ({ children }: THelpMenuProviderProps) => {
  const [style, setStyle] = useState<CSSProperties>({});

  return (
    <HelpMenuContext.Provider
      value={{
        style,
        setStyle,
      }}>
      {children}
    </HelpMenuContext.Provider>
  );
};

export const useHelpMenuContext = () => useContext(HelpMenuContext);
