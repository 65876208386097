import dayjs from 'dayjs';
import { ThunkAction } from 'redux-thunk';

import { ESearchFilters } from '@/constants/searchFilters';
import { ISetAddons, setAddons } from '@/redux/modules/addons';
import { TRootState } from '@/redux/rootReducer';
import {
  getDiscountMessage,
  getQuoteDuration,
  getQuoteEventData,
  getQuoteFromAndTo,
  getQuoteInput,
} from '@/redux/selectors/listing/bill';
import { getFromAndTo, getGuestsForOccupancy, getQueryParams } from '@/redux/selectors/queryParams';
import { getQuoteAddons } from '@/redux/selectors/quote';
import { trackListingQuoteUpdatedEvent } from '@/services/analytics/listings';
import apiRequest from '@/services/apiRequest';
import { trackEvent } from '@/services/track-event';
import { EDeliveryOption } from '@/services/types/core/delivery.types';
import { IDelivery, IDeliveryQueryParams, IOccupancy, IQuote } from '@/services/types/core/quotes';
import { IAddOnItem } from '@/services/types/quote/IAddons';
import { IQuoteDeliveryLocation } from '@/services/types/quote/IDeliveryLocation';
import formatDate from '@/utility/format-date';
import { getCoreApi } from '@/utility/getCoreApi';
import { getIntl } from '@/utility/i18n';
import { logger } from '@/utility/logger';
import { getUrlParams } from '@/utility/queryParams';
import { IAction } from '@/utility/redux/action';

import { getUserCurrency } from '../selectors/currency';
import { getAddons } from '../selectors/listing/addons';
import { getIsCampgroundListing, getIsCampsite } from '../selectors/listing/campsite';
import { getAvailableServices } from './availableServices';

const SET_QUOTE = 'quote/SET_QUOTE';
const QUOTE_REQUEST = 'quote/QUOTE_REQUEST';
const QUOTE_RESPONSE = 'quote/QUOTE_RESPONSE';
const QUOTE_ERROR = 'quote/QUOTE_ERROR';
const CLEAR_QUOTE = 'quote/CLEAR_QUOTE';
const SET_ADDONS = 'quote/SET_ADDONS';
const SET_DELIVERY = 'quote/SET_DELIVERY';
const CLEAR_DELIVERY = 'quote/CLEAR_DELIVERY';
const SET_INITIAL_DURATION = 'quote/SET_INITIAL_DURATION';

type TQuotePromise = Promise<IQuote | undefined>;
export interface IQuoteDelivery {
  address: string | null;
  deliveryCampgroundId?: number;
  location: IQuoteDeliveryLocation;
  stationary: boolean | undefined;
  estimated_distance?: number;
  comment?: string;
}

export interface ISetQuoteAction extends IAction {
  type: typeof SET_QUOTE;
  payload: IQuote | null;
}

interface IQuoteRequestAction extends IAction {
  type: typeof QUOTE_REQUEST;
  payload: TQuotePromise;
}

interface IQuoteResponseAction extends IAction {
  type: typeof QUOTE_RESPONSE;
  payload: IQuote;
}

interface IQuoteErrorAction extends IAction {
  type: typeof QUOTE_ERROR;
  error: boolean;
  payload: string;
}

interface IClearQuoteAction extends IAction {
  type: typeof CLEAR_QUOTE;
}

interface ISetAddonsAction extends IAction {
  type: typeof SET_ADDONS;
  payload: IAddOnItem[] | undefined;
}

export interface ISetDeliveryAction extends IAction {
  type: typeof SET_DELIVERY;
  payload: IQuoteDelivery;
}

interface ISetInitialDurationAction extends IAction {
  type: typeof SET_INITIAL_DURATION;
  payload: number | undefined;
}

interface IClearDeliveryAction extends IAction {
  type: typeof CLEAR_DELIVERY;
}

type TAction =
  | ISetQuoteAction
  | IQuoteRequestAction
  | IQuoteResponseAction
  | IQuoteErrorAction
  | IClearQuoteAction
  | ISetAddonsAction
  | ISetDeliveryAction
  | IClearDeliveryAction
  | ISetInitialDurationAction;

const setQuote = (payload: IQuote | null): ISetQuoteAction => ({
  type: SET_QUOTE,
  payload,
});

const fetchQuote = (payload: TQuotePromise): IQuoteRequestAction => ({
  type: QUOTE_REQUEST,
  payload,
});

const fetchQuoteResponse = (payload: IQuote): IQuoteResponseAction => ({
  type: QUOTE_RESPONSE,
  payload,
});

const fetchQuoteError = (payload: string): IQuoteErrorAction => ({
  type: QUOTE_ERROR,
  error: true,
  payload,
});

export const clearQuote = (): IClearQuoteAction => ({
  type: CLEAR_QUOTE,
});

export const setQuoteDelivery = (payload: IQuoteDelivery): ISetDeliveryAction => ({
  type: SET_DELIVERY,
  payload,
});

export const clearQuoteDelivery = (): IClearDeliveryAction => ({
  type: CLEAR_DELIVERY,
});

const setInitialDuration = (payload: number | undefined): ISetInitialDurationAction => ({
  type: SET_INITIAL_DURATION,
  payload,
});

type TGetQuoteAction =
  | ISetQuoteAction
  | IQuoteRequestAction
  | IQuoteResponseAction
  | IQuoteErrorAction
  | ISetAddons
  | ISetDeliveryAction;

export const getQuoteSuccess =
  (
    response: IQuote,
  ): ThunkAction<
    void,
    TRootState,
    void,
    IQuoteResponseAction | ISetAddons | ISetInitialDurationAction
  > =>
  async (dispatch, getState) => {
    await dispatch(fetchQuoteResponse(response));
    const state = getState();

    // side effect - set addons store
    const quoteAddons = getQuoteAddons(state);
    const addons = getAddons(state);
    dispatch(setAddons(quoteAddons || addons));

    // side effect - get available services
    dispatch(getAvailableServices());

    const discountMessage = getDiscountMessage(state);
    if (discountMessage) {
      const quoteDuration = getQuoteDuration(state);
      dispatch(setInitialDuration(quoteDuration));
    }

    // track quote event
    const eventData = getQuoteEventData(state);
    trackEvent(eventData);
  };

export const getQuote: (args?: {
  bundleId?: string;
  from?: Date;
  to?: Date;
  rentalId?: number;
  throwOnFetchFailure?: boolean;
  serviceId?: number;
  serviceShouldBeAdded?: boolean;
  tripCreditsDisabled?: boolean;
  guests?: IOccupancy;
  pets?: number;
  deliveryQueryParams?: IDeliveryQueryParams;
  removalServiceIds?: number[];
}) => ThunkAction<TQuotePromise, TRootState, void, TGetQuoteAction> =
  ({
    bundleId,
    from,
    to,
    rentalId: rawRentalId,
    deliveryQueryParams,
    throwOnFetchFailure,
    serviceId,
    serviceShouldBeAdded,
    tripCreditsDisabled = true,
    guests,
    pets,
    removalServiceIds = [],
  } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    if (state.quote.isLoadingQuote) {
      return state.quote.quotePromise;
    }
    // Quote's from and to are used as fallback in case user uses quote queryparam
    const { from: queryFrom, to: queryTo } = getFromAndTo(state);
    const { from: quoteFrom, to: quoteTo } = getQuoteFromAndTo(state);
    const parsedFrom = from || queryFrom || quoteFrom;
    const parsedTo = to || queryTo || quoteTo;

    const occupancy = guests || state.quote.data?.occupancy || getGuestsForOccupancy(state);

    const currency = getUserCurrency(state);
    const { discountCode, rentalId: inputRentalId, authToken } = getQuoteInput(state);
    const rentalId = rawRentalId || inputRentalId;
    const quoteDelivery = state.quote.delivery;
    const quoteBundle = state.quote.data?.bundle_id;
    const addons = getQuoteAddons(state);
    const prevQuote = state.quote.data;
    const quoteId = state.quote.data?.id;
    const isCampsite = getIsCampsite(state) || getIsCampgroundListing(state);
    const queryParams = getQueryParams(state);

    const {
      [ESearchFilters.FILTER_FEATURE]: featuresFromQueryParams,
      [ESearchFilters.SLEEPS_ADULTS]: sleepsAdults,
      [ESearchFilters.SLEEPS_CHILDREN]: sleepsChildren,
    } = queryParams;

    const petFriendly = featuresFromQueryParams?.includes('pet_friendly');
    let travelers = 0;

    if (sleepsAdults) {
      travelers += Number(sleepsAdults);
    }
    if (sleepsChildren) {
      travelers += Number(sleepsChildren);
    }

    // Ensure fields exist.
    // Protect dates from being reversed.
    let isDatesValid = !!(parsedFrom && parsedTo);

    if (isDatesValid) {
      const f = dayjs(parsedFrom);
      const t = dayjs(parsedTo);

      if (f.isAfter(t)) {
        isDatesValid = false;
      }
    }

    if (!isDatesValid) {
      dispatch(setQuote(null));
      return;
    }

    const url = `${getCoreApi()}/quotes`;

    let delivery;
    let deliveryCampgroundId;

    // If there's delivery in the existing quote in the state - persist it
    // else check for provided query params and use them
    if (quoteDelivery) {
      const { location, stationary } = quoteDelivery;
      deliveryCampgroundId = quoteDelivery?.deliveryCampgroundId;
      if (location)
        delivery = {
          stationary,
          location,
        };
    } else if (deliveryQueryParams) {
      delivery = {
        stationary: deliveryQueryParams.deliveryStationary === EDeliveryOption.STATIONARY,
        location: {
          ...deliveryQueryParams.deliveryDetails,
          lng: deliveryQueryParams.deliveryCenter?.lng,
          lat: deliveryQueryParams.deliveryCenter?.lat,
          formattedAddress: deliveryQueryParams.deliveryAddress,
        },
      };
      deliveryCampgroundId = deliveryQueryParams.deliveryCampgroundId;
    }

    let serviceIds = state.quote.data?.services.map(({ service_id }) => service_id) || [0];
    // TODO: Should we transfer services from existing quote here?
    if (serviceId) {
      if (serviceShouldBeAdded) {
        serviceIds.push(serviceId);
      } else {
        serviceIds = serviceIds.filter(s => s !== serviceId);
      }
    }

    if (removalServiceIds.length) {
      serviceIds = serviceIds.filter(s => !removalServiceIds.includes(s));
    }

    const data = {
      presentment_currency: currency,
      rental_id: rentalId,
      from: parsedFrom ? formatDate(parsedFrom, 'YYYY-MM-DD') : '',
      to: parsedTo ? formatDate(parsedTo, 'YYYY-MM-DD') : '',
      discount_code: discountCode,
      items: addons,
      reserve: true,
      delivery,
      bundle_id:
        bundleId === undefined && quoteBundle === '' ? undefined : (bundleId ?? quoteBundle),
      services: Array.from(new Set(serviceIds)),
      id: quoteId,
      trip_credits_disabled: tripCreditsDisabled,
      ...(isCampsite || deliveryCampgroundId
        ? {
            occupancy: occupancy,
            pets: pets,
          }
        : {}),
      ...(deliveryCampgroundId ? { delivery_campground_id: deliveryCampgroundId } : {}),
      ...(petFriendly ? { pet_friendly: petFriendly } : {}),
      ...(travelers ? { travelers: travelers } : {}),
    };

    const intl = getIntl();
    const defaultErrorMessage = intl.formatMessage({
      defaultMessage: 'Could not fetch quote. Please try again later.',
      id: 'qz2IkN',
    });

    const campsiteErrorMessage = intl.formatMessage({
      defaultMessage: 'This campsite is no longer available for the selected dates.',
      id: '87azFN',
    });

    const headers = authToken ? { authorization: `Token=${authToken}` } : undefined;
    const promise = apiRequest<IQuote>({ url, data, method: 'POST', headers }, true);
    dispatch(fetchQuote(promise));
    try {
      const response = await promise;
      if (response) {
        if (response.delivery) {
          dispatch(
            setQuoteDelivery({
              address: response.delivery.address || '',
              location: response.delivery?.location as IQuoteDeliveryLocation,
              deliveryCampgroundId: response.delivery_campground_id,
              stationary: response.delivery?.stationary || false,
              estimated_distance: response.delivery?.estimated_distance,
              comment: response.delivery?.comment,
            }),
          );
        }

        dispatch(getQuoteSuccess(response));
        if (prevQuote) {
          trackListingQuoteUpdatedEvent(prevQuote, response);
        }
      } else {
        dispatch(fetchQuoteError(defaultErrorMessage));

        if (throwOnFetchFailure) {
          return Promise.reject(defaultErrorMessage);
        }
      }
    } catch (error) {
      const firstError = error.response?.data?.errors[0];
      let errorMessage = firstError?.error || error.error || defaultErrorMessage;
      //Hardcoding this error message for now
      if (isCampsite && error?.original_error?.toLowerCase().includes('there is no availability')) {
        errorMessage = campsiteErrorMessage;
      }
      dispatch(fetchQuoteError(errorMessage));

      if (throwOnFetchFailure) {
        return Promise.reject(errorMessage);
      }
    }
    return promise;
  };

export const fetchQuoteById =
  (
    quoteId?: string,
    rentalId?: number,
  ): ThunkAction<Promise<void | IQuote>, TRootState, void, ISetDeliveryAction> =>
  async (dispatch, getState) => {
    if (!quoteId || quoteId === 'undefined') {
      logger.captureExceptionWithDatadog(new Error('Parameter quote is undefined!'), {
        url: location.href,
        req_params: getUrlParams(location.href),
      });
      return;
    }

    const requestUrl = `${getCoreApi()}/quotes/${quoteId}`;
    const state = getState();
    const { authToken } = getQuoteInput(state);
    const headers = authToken ? { authorization: `Token=${authToken}` } : undefined;

    return new Promise((resolve, reject) =>
      apiRequest<IQuote>({ url: requestUrl, headers }, true)
        .then(data => {
          if (!data) {
            return resolve();
          }

          const { rental_summary: { id } = {} } = data;

          if (Number(id) !== rentalId) {
            return resolve();
          }

          dispatch(
            setQuoteDelivery({
              address: null, // todo: get formattedAddress from quotes response
              location: data.delivery?.location as IQuoteDeliveryLocation,
              deliveryCampgroundId: data.delivery_campground_id,
              stationary: data.delivery?.stationary || false,
              estimated_distance: data.delivery?.estimated_distance,
              comment: data.delivery?.comment,
            }),
          );

          dispatch(getQuoteSuccess(data));
          return resolve(data);
        })
        .catch(error => {
          return reject(error);
        }),
    );
  };

interface IState {
  data: IQuote | null;
  addons?: IAddOnItem[] | undefined;
  delivery?: IQuoteDelivery | undefined;
  errorMessage?: string | undefined;
  isLoadingQuote: boolean;
  quotePromise?: TQuotePromise;
  initialDuration?: number | undefined;
}

export const initialState: IState = {
  data: null,
  isLoadingQuote: false,
};

// TODO: Add async actions (REQUEST/FAIL/SUCCESS)
export default function reducer(state = initialState, action: TAction): IState {
  switch (action.type) {
    case SET_QUOTE:
      return {
        ...state,
        data: action.payload,
      };
    case QUOTE_REQUEST:
      return {
        ...state,
        isLoadingQuote: true,
        errorMessage: undefined,
        quotePromise: action.payload,
      };
    case QUOTE_RESPONSE:
      return {
        ...state,
        data: action.payload,
        isLoadingQuote: false,
        errorMessage: undefined,
        quotePromise: undefined,
      };
    case QUOTE_ERROR:
      return {
        ...state,
        data: null,
        isLoadingQuote: false,
        errorMessage: action.payload,
        quotePromise: undefined,
      };
    case CLEAR_QUOTE:
      return {
        ...state,
        data: null,
        isLoadingQuote: false,
        errorMessage: undefined,
        quotePromise: undefined,
      };
    case SET_ADDONS:
      return {
        ...state,
        addons: action.payload,
      };
    case SET_DELIVERY:
      if (!state.data) {
        return {
          ...state,
          delivery: action.payload,
        };
      }
      return {
        ...state,
        data: {
          ...state.data,
          delivery: action.payload as IDelivery,
        },
        delivery: action.payload,
      };
    case CLEAR_DELIVERY:
      return {
        ...state,
        delivery: undefined,
      };
    case SET_INITIAL_DURATION:
      return {
        ...state,
        initialDuration: action.payload,
      };
    default:
      return state;
  }
}
