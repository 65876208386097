import dayjs from 'dayjs';

import {
  ADD_FILTERS,
  APPLY_FILTERS,
  DISABLE_DELIVERABLE_FILTER,
  ENABLE_DELIVERABLE_FILTER,
  RESET_DATES_FILTER,
  RESET_FILTERS,
  RESET_GUESTS_FILTER,
  RESET_PRICE_FILTER,
  RESET_VEHICLE_TYPES_FILTER,
  SET_ADULTS_GUEST_FILTER,
  SET_CHILDREN_GUEST_FILTER,
  SET_DATES_FILTER,
  SET_FESTIVAL_FILTER,
  SET_IS_FETCHED,
  SET_LOCATION,
  SET_PETS_GUEST_FILTER,
  SET_PRICE_END,
  SET_PRICE_START,
  SET_RENTALS,
  SET_VEHICLE_TYPES,
} from '@/actions/rentals';

export const initialState = {
  meta: {},
  rentals: [],
  priceHistogramData: [],
  currentFilters: {
    'page[limit]': 8,
    'page[offset]': 0,
    'price[max]': null,
    'price[min]': null,
    'date[from]': null,
    'date[to]': null,
    'sleeps[adults]': null,
    'sleeps[children]': null,
    'filter[type]': [],
    delivery: null,
    near: null,
    deliveryStationary: null,
  },
  filters: {
    dateFrom: null,
    dateTo: null,
    adults: 0,
    children: 0,
    pets: 0,
    festival: null,
    vehicleTypes: [],
    delivery: null,
    deliveryStationary: null,
  },
  modals: {
    showAll: false,
  },
  isFetched: false,
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RENTALS:
      return {
        ...state,
        meta: action.meta,
        rentals: action.rentals || [],
        priceHistogramData: action.priceHistogramData || [],
        isFetched: action.isFetched,
      };
    case ADD_FILTERS:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          ...action.filterList,
        },
      };
    case APPLY_FILTERS:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          'price[max]': state.filters.priceEnd,
          'price[min]': state.filters.priceStart,
          'sleeps[adults]': state.filters.adults,
          'sleeps[children]': state.filters.children,
          'date[from]': state.filters.dateFrom
            ? dayjs(state.filters.dateFrom).format('YYYY-MM-DD')
            : null,
          'date[to]': state.filters.dateTo
            ? dayjs(state.filters.dateTo).format('YYYY-MM-DD')
            : null,
          'filter[type]': state.filters.vehicleTypes.join(','),
          'filter[feature]':
            state.filters.pets && state.filters.festival
              ? 'pet_friendly,festival_friendly'
              : state.filters.pets
                ? 'pet_friendly'
                : state.filters.festival
                  ? 'festival_friendly'
                  : null,
          delivery: state.filters.delivery,
          deliveryStationary: state.filters.deliveryStationary,
        },
      };
    case SET_LOCATION:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          near: action.location,
        },
      };
    case SET_PRICE_START:
      return {
        ...state,
        filters: {
          ...state.filters,
          priceStart: action.price,
        },
      };
    case SET_PRICE_END:
      return {
        ...state,
        filters: {
          ...state.filters,
          priceEnd: action.price,
        },
      };
    case SET_DATES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          dateFrom: action.from,
          dateTo: action.to,
        },
      };
    case SET_ADULTS_GUEST_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          adults: action.value,
        },
      };
    case SET_CHILDREN_GUEST_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          children: action.value,
        },
      };
    case SET_FESTIVAL_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          festival: action.value,
        },
      };
    case SET_PETS_GUEST_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          pets: action.value,
        },
      };
    case SET_VEHICLE_TYPES:
      return {
        ...state,
        filters: {
          ...state.filters,
          vehicleTypes: action.value,
        },
      };
    case RESET_FILTERS:
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
      };
    case RESET_DATES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          dateFrom: initialState.filters.dateFrom,
          dateTo: initialState.filters.dateTo,
        },
      };
    case RESET_PRICE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          priceStart: initialState.filters.priceStart,
          priceEnd: initialState.filters.priceEnd,
        },
      };
    case RESET_GUESTS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          adults: initialState.filters.adults,
          children: initialState.filters.children,
          pets: initialState.filters.pets,
        },
      };
    case RESET_VEHICLE_TYPES_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          vehicleTypes: initialState.filters.vehicleTypes,
        },
      };

    case SET_IS_FETCHED:
      return {
        ...state,
        isFetched: action.isFetched,
      };

    case ENABLE_DELIVERABLE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          delivery: action.delivery,
          deliveryStationary: action.deliveryStationary,
        },
      };
    case DISABLE_DELIVERABLE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          delivery: null,
          deliveryStationary: null,
        },
      };

    default:
      return { ...state };
  }
};

export default filtersReducer;
