import { GeneralCameraOffIcon } from '@outdoorsyco/bonfire';
import classNames from 'classnames';
import Head from 'next/head';
import { StaticImageData } from 'next/image';
import React, { ImgHTMLAttributes, useEffect, useRef, useState } from 'react';

import formatImageURL, { getSourceSet, ImageSize, TImageCropMode } from '@/utility/formatImageURL';

import css from './ResponsiveImage.module.css';

interface ExtendedImgHTMLAttributes extends ImgHTMLAttributes<HTMLImageElement> {
  fetchpriority?: 'high' | 'low' | 'auto';
}

export type ImageSizes = ImageSize[];

export interface IImageProps {
  /**
   * Original image source
   */
  src: string | StaticImageData;
  /**
   * Comma-separated list of rendered sizes
   * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Img#attr-sizes
   */
  sizes?: string;
  /**
   * Single or list of predefined source sizes from `formatImageURL`
   */
  sourceSizes?: ImageSizes;
  /**
   * Crop mode
   */
  cropMode?: TImageCropMode;

  priority?: boolean;
  progressive?: boolean;
  withFetchPriority?: 'high' | 'low' | undefined;
  /**
   * Fallback component in case of error
   */
  showFallbackComponent?: boolean;
  customFallbackContent?: React.ReactNode;
  /**
   * Whether the image is being used in a modular listing tile
   */
  isModular?: boolean;
}

const loadImage = (image: HTMLImageElement, src: string) => {
  image.setAttribute('src', src);
  image.onload = () => {
    image.removeAttribute('data-src');
  };
};

// Fallback component with gray background and Bonfire icon
const ImageFallback: React.FC = () => (
  <div className="flex items-center justify-center w-full h-full">
    <GeneralCameraOffIcon className="text-3xl" />
  </div>
);

// Progressive loading implemented using intersection observer and data-src attribute with immediate CSS blur
// See https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Loading for more info.

const ResponsiveImage: React.FC<ExtendedImgHTMLAttributes & IImageProps> = ({
  alt = '',
  src,
  sizes,
  sourceSizes: sourceSizesProp,
  cropMode = 'fill',
  priority = false,
  progressive = false,
  withFetchPriority,
  showFallbackComponent = false,
  customFallbackContent,
  ...props
}) => {
  const sourceSizes: ImageSizes =
    sourceSizesProp && sourceSizesProp.length ? sourceSizesProp : ['default'];
  const srcSet = sourceSizes.length > 1 ? getSourceSet(src, sourceSizes, cropMode) : undefined;
  const transformedSrc = formatImageURL(src, sourceSizes[0] || 'default', cropMode);
  const loadingImage = formatImageURL(src, 'square20', cropMode);
  const imgRef = useRef<HTMLImageElement>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const currentSrc = srcSet ? undefined : progressive ? loadingImage : transformedSrc;

  useEffect(() => {
    if (progressive) {
      if (imgRef?.current) {
        if ('IntersectionObserver' in window) {
          const observer = new IntersectionObserver((items, obs) => {
            items.forEach(item => {
              if (item.isIntersecting && item.target === imgRef.current) {
                loadImage(imgRef.current, transformedSrc);
                obs.unobserve(imgRef.current);
              }
            });
          });
          observer.observe(imgRef.current);
        } else {
          loadImage(imgRef.current, transformedSrc);
        }
      }
    }
  }, [progressive, transformedSrc]);

  const handleError = () => {
    setIsError(true);
  };

  return (
    <>
      {priority && (
        <Head>
          <link
            data-testid="preload"
            rel="preload"
            as="image"
            href={currentSrc}
            imageSrcSet={srcSet}
            imageSizes={sizes}
          />
        </Head>
      )}
      {isError && showFallbackComponent ? (
        customFallbackContent || <ImageFallback />
      ) : (
        <img
          {...props}
          className={classNames(css.blur, props.className)}
          ref={imgRef}
          loading={!priority ? 'lazy' : undefined}
          data-testid="img"
          alt={alt}
          src={currentSrc}
          onError={handleError}
          srcSet={srcSet}
          sizes={sizes}
          {...(withFetchPriority && { fetchpriority: withFetchPriority })}
        />
      )}
    </>
  );
};

export default ResponsiveImage;
