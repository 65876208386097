import cn from 'classnames';
import React, { Fragment, memo, useEffect, useRef, useState } from 'react';

import css from './Ticker.module.css';

type TTickerProps = {
  items: JSX.Element[];
  speed?: number;
};

export const Ticker = memo(function Ticker({ items, speed = 35 }: TTickerProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLDivElement>(null);

  const [rootWidth, setRootWidth] = useState(0);
  const [itemsWidth, setItemsWidth] = useState(0);

  const content = (
    <>
      {items.map((item, index) => (
        <Fragment key={index}>{item}</Fragment>
      ))}
    </>
  );

  useEffect(() => {
    const updateWidths = () => {
      setRootWidth(rootRef.current?.offsetWidth || 0);
      setItemsWidth(itemsRef.current?.offsetWidth || 0);
    };

    updateWidths();

    window.addEventListener('resize', updateWidths, { passive: true });

    return () => window.removeEventListener('resize', updateWidths);
  }, []);

  const initialized = rootWidth > 0 && itemsWidth > 0;
  const hasSingleItemThatFits = items.length === 1 && itemsWidth < rootWidth;

  return (
    <div
      // Fully re-render when the width changes so we can reset the animation,
      // since we might duplicate the items a different amount of times after resize,
      // and the animation for some of the items might be already in progress.
      key={rootWidth}
      ref={rootRef}
      // Use items width as reference to calculate the speed so we end up with
      // the same speed regardless of the amount of items.
      style={{ ['--ticker-speed' as string]: itemsWidth / speed }}
      className={css.root}>
      <div
        className={cn(css.ticker, {
          [css.stationary as string]: initialized && hasSingleItemThatFits,
          [css.animated as string]: initialized && !hasSingleItemThatFits,
        })}>
        <div className={css.scroller}>
          <div ref={itemsRef} className={cn(css.items, css.original)}>
            {content}
          </div>

          {
            // We need to fill all the space with duplicated items, plus one more,
            // to avoid having empty space while rotating the content.
            Array.from({ length: Number(Math.ceil(rootWidth / itemsWidth)) }).map((_, index) => (
              <div key={index} className={css.items}>
                {content}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
});
