import { ParsedUrlQuery } from 'querystring';

import router from '@/hooks/useRouter';
import { TAuthUser } from '@/redux/selectors/auth/user';
import { IReviewData } from '@/redux/selectors/listing/reviews';
import { IBooking } from '@/services/types/booking/details';
import { IReview } from '@/services/types/core/reviews/id';
import { createListingUrlObj } from '@/utility/routes';
import { decodeString, stripHTML } from '@/utility/stripHtml';

export const getReviewOpenedImage = (queryParams: ParsedUrlQuery): [number, number | null] => {
  const reviewOpenedImage = (queryParams.reviewOpenedImage as string | undefined) || '';
  const [id, imageIndex] = reviewOpenedImage.split(',').map(r => Number(r));
  // @ts-expect-error fixable: unchecked index access
  return [id, imageIndex ?? null];
};

export const handleReviewLighboxChange = (id: number, imageIndex: number | null) => {
  const { reviewOpenedImage: _unused, ...query } = router.query;

  if (imageIndex !== null) {
    query.reviewOpenedImage = `${id},${imageIndex}`;
  }

  router.push(createListingUrlObj(query), undefined, { shallow: true });
};

const formatName = ({
  first_name,
  last_name,
}: {
  first_name: string;
  last_name: string;
}): string => {
  const firstName = first_name.trim();
  const lastName = last_name.trim()[0];

  return lastName ? `${firstName} ${lastName.toUpperCase()}.` : firstName;
};

export const formatReviewData = (
  review: IReview,
  queryParams: ParsedUrlQuery = {},
): IReviewData => {
  const [queryReviewId, queryOpenedImage] = getReviewOpenedImage(queryParams);

  return {
    id: review.id,
    name: formatName(review.reviewer),
    date: new Date(review.timestamp),
    photoUrl: review.reviewer.avatar_url,
    ratingNumber: review.score,
    review: stripHTML(decodeString(review.text)),
    images: review.images,
    tags: review.tags ? review.tags.map(tag => tag.name) : [],
    ownerAnswer: review.owner_response.text
      ? {
          name: review.owner.first_name,
          answer: stripHTML(decodeString(review.owner_response.text)),
        }
      : undefined,
    openedImage: queryReviewId === review.id ? queryOpenedImage : null,
    exclude_from_rating: review.exclude_from_rating,
  };
};

export const mapReview = (booking: IBooking, user: TAuthUser) => {
  return {
    booking_id: booking.id,
    cleanliness_score: 0,
    communication_score: 0,
    exclude_from_rating: false,
    id: 0,
    images: [],
    listing_score: 0,
    mechanical_score: 0,
    name: '',
    owner: {
      avatar_url: booking.owner_summary.avatar_url,
      first_name: booking.owner_summary.first_name,
      last_name: booking.owner_summary.last_name,
      id: booking.owner_summary.id,
    },
    owner_can_respond: true,
    owner_response: {},
    published: false,
    rental_id: booking.rental_id,
    rental_tags: [],
    review_images: [],
    reviewer: {
      avatar_url: user?.avatar_url || '',
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      id: user?.id || 0,
    },
    reviewer_id: booking.renter_id,
    score: 0,
    tags: [],
    text: '',
    timestamp: '',
    user_id: null,
    user_recommend: true,
    value_score: 0,
    source_page: '',
  };
};

export const roundRatingNumber = (score: number) => {
  return Math.round(score * 10) / 10;
};
