import {
  AccomodationAccomodationTypeHouseIcon,
  ActivitiesActivitiesBikingIcon,
  ActivitiesActivitiesBoatingIcon,
  AmenitiesAdaAdaIcon,
  AmenitiesAdultOnlyIcon,
  AmenitiesAirConditionerAirConditionerIcon,
  AmenitiesAmusementParkIcon,
  AmenitiesArcadeIcon,
  AmenitiesBasketballIcon,
  AmenitiesBathingSuitIcon,
  AmenitiesBathroomBathroomsIcon,
  AmenitiesBathroomBathTowelsIcon,
  AmenitiesBathroomToiletriesIcon,
  AmenitiesBoatRampIcon,
  AmenitiesBookingBedroomsIcon,
  AmenitiesBookingSleepsIcon,
  AmenitiesCeilingFanCeilingFanIcon,
  AmenitiesClubhouseIcon,
  AmenitiesDockAccessIcon,
  AmenitiesDumpStationIcon,
  AmenitiesDumpsterIcon,
  AmenitiesElectricityGeneratorIcon,
  AmenitiesElectricityPowerIcon,
  AmenitiesElectricOutletIcon,
  AmenitiesEntertainmentBluetoothIcon,
  AmenitiesEventCenterIcon,
  AmenitiesFiftyFivePlusIcon,
  AmenitiesFirewoodIcon,
  AmenitiesGameRoomIcon,
  AmenitiesGolfCartIcon,
  AmenitiesGolfIcon,
  AmenitiesHeaterAvailableIcon,
  AmenitiesHomeBlanketsIcon,
  AmenitiesHomeLinensIcon,
  AmenitiesHomeWasherDryerIcon,
  AmenitiesHomeWorkspaceIcon,
  AmenitiesKitchenCoffeeMakerIcon,
  AmenitiesKitchenCookingBasicsIcon,
  AmenitiesKitchenDishesAndUtensilsIcon,
  AmenitiesKitchenPotableWaterIcon,
  AmenitiesKitchenPotsAndPansIcon,
  AmenitiesKitchenSilverwareIcon,
  AmenitiesLgbtiqaIcon,
  AmenitiesMarinaIcon,
  AmenitiesMicrowaveMicrowaveIcon,
  AmenitiesMinigolfIcon,
  AmenitiesNationalParkIcon,
  AmenitiesOutdoorDiningTableIcon,
  AmenitiesOutdoorFenceIcon,
  AmenitiesOutdoorFirepitIcon,
  AmenitiesOutdoorGrillIcon,
  AmenitiesOutdoorHotTubIcon,
  AmenitiesOutdoorOutdoorFurnitureIcon,
  AmenitiesOutdoorPatioIcon,
  AmenitiesOutdoorPicnicTableIcon,
  AmenitiesOutdoorPoolIcon,
  AmenitiesOvenOvenIcon,
  AmenitiesParkingParkingIcon,
  AmenitiesPetsPetFriendlyIcon,
  AmenitiesPillowIcon,
  AmenitiesPlaygroundIcon,
  AmenitiesPropaneIcon,
  AmenitiesRvStorageIcon,
  AmenitiesSafetyCarbonMonoxideDetectorIcon,
  AmenitiesSafetyFireExtinguisherIcon,
  AmenitiesSafetyFirstAidKitIcon,
  AmenitiesSafetySmokeDetectorIcon,
  AmenitiesShowerInsideShowerInsideIcon,
  AmenitiesShowerOutsideShowerOutsideIcon,
  AmenitiesSinkKitchenSinkIcon,
  AmenitiesSizeAreaIcon,
  AmenitiesSlideoutIcon,
  AmenitiesSnackBarIcon,
  AmenitiesSpaIcon,
  AmenitiesStateParkIcon,
  AmenitiesStoveStoveIcon,
  AmenitiesTemperatureIndoorFireplaceIcon,
  AmenitiesTerrainIcon,
  AmenitiesToiletCompostIcon,
  AmenitiesToiletFlushIcon,
  AmenitiesTvTvIcon,
  AmenitiesWaterfuelFreshWaterIcon,
  AmenitiesWaterfuelSewageTankIcon,
  AmenitiesWaterfuelWatertankIcon,
  AmenitiesWaterParkIcon,
  AmenitiesWifiIcon,
  AmenitiesWineIcon,
  GeneralBookBookIcon,
  GeneralBriefcaseIcon,
  GeneralLengthIcon,
  GeneralMailIcon,
  RoamlyShelterIcon,
  RoamlyWheelTireMonoIcon,
  TerrainTerrainBeachIcon,
  VehiclesVehiclesClassCSimpleIcon,
} from '@outdoorsyco/bonfire';
import { SVGProps } from 'react';

import { ICONS } from '@/components/switchback/Icon/Icon.constants';

import { THookupFilters } from './moreFilter';

export enum EVehicleHookupsCampgrounds {
  ELECTRICITY = 'electricity_hookups',
  SEWER = 'sewer_hookups',
  WATER = 'water_hookups',
}

export const vehicleHookupsToCampgroundVehicleHookupsMap: Record<
  THookupFilters,
  EVehicleHookupsCampgrounds
> = {
  hookup_electric: EVehicleHookupsCampgrounds.ELECTRICITY,
  hookup_sewer: EVehicleHookupsCampgrounds.SEWER,
  hookup_water: EVehicleHookupsCampgrounds.WATER,
};

export type AMENITIES_KEYS =
  | 'air_conditioner'
  | 'heater'
  | 'ceiling_fan'
  | 'refrigerator'
  | 'kitchen_sink'
  | 'microwave'
  | 'stove'
  | 'dining_table'
  | 'oven'
  | 'inside_shower'
  | 'toilet'
  | 'outside_shower'
  | 'generator'
  | 'backup_camera'
  | 'tv_dvd'
  | 'leveling_jacks'
  | 'wifi'
  | 'tow_hitch'
  | 'awning'
  | 'satellite'
  | 'bike_rack'
  | 'washer_dryer'
  | 'audio_inputs'
  | 'inverter'
  | 'radio'
  | 'extra_storage'
  | 'solar'
  | 'cd_player'
  | 'handicap_accessible'
  | 'beds_full'
  | 'beds_king'
  | 'beds_queen'
  | 'beds_twin'
  | 'beds_dinette_conversion'
  | 'beds_bunk'
  | 'beds_fold_out_sofa'
  | 'beds_other'
  | 'bath_towels'
  | 'bathroom_sink'
  | 'toilet_paper'
  | 'toiletries'
  | 'hot_water'
  | 'potable_water'
  | 'coffee_maker'
  | 'pots_and_pans'
  | 'dishes_and_utensils'
  | 'cooking_basics'
  | 'hookup_water'
  | 'hookup_electric'
  | 'hookup_sewer'
  | 'bluetooth';

export type AMENITIES_FOR_STAY_KEYS =
  | 'air_conditioning'
  | 'bath_towels'
  | 'ada_accessible'
  | 'wheelchair_accessible'
  | 'compost_toilet'
  | 'hot_water'
  | 'indoor_shower'
  | 'outdoor_shower'
  | 'toiletries'
  | 'tv_dvd'
  | 'flush_toilet'
  | 'toilet_paper'
  | 'dryer'
  | 'pillows'
  | 'blankets'
  | 'fresh_linens'
  | 'washer'
  | 'coffee_maker'
  | 'dishes_and_utensils'
  | 'potable_water'
  | 'cooking_basics'
  | 'pots_and_pans'
  | 'workspace'
  | 'outdoor_furniture'
  | 'patio_balcony_deck'
  | 'pool'
  | 'bbq_grill'
  | 'fire_pit'
  | 'hot_tub'
  | 'picnic_table'
  | 'first_aid_kit'
  | 'carbon_monoxide_detector'
  | 'fire_extinguisher'
  | 'smoke_detector'
  | 'air_conditioning'
  | 'heating'
  | 'indoor_fireplace'
  | 'portable_fan'
  | 'ceiling_fan'
  | 'kitchen_sink'
  | 'microwave'
  | 'oven'
  | 'refrigerator'
  | 'stove'
  | 'dining_table'
  | 'wifi';

export type AMENITIES_FOR_RV_CAMPSITES_KEYS =
  | 'sleeps'
  | 'potable_water'
  | 'bathrooms'
  | 'indoor_shower'
  | 'outdoor_shower'
  | 'flush_toilet'
  | 'compost_toilet'
  | 'hot_water'
  | 'wifi'
  | 'ada_accessible'
  | 'wheelchair_accessible'
  | 'fire_pit'
  | 'grill'
  | 'hot_tub'
  | 'outdoor_furniture'
  | 'pool'
  | 'picnic_table'
  | 'patio_deck_or_balcony'
  | 'cooking_basics'
  | 'pots_and_pans'
  | 'dishes_and_utensils'
  | 'allows_pets'
  | 'size_area'
  | 'firewood'
  | 'trash_bins'
  | 'grass_terrain'
  | 'field_terrain'
  | 'dirt_terrain'
  | 'gravel_terrain'
  | 'concrete_terrain'
  | 'allows_campfires'
  | 'allows_slideouts'
  | '4wd_recommended'
  | '2wd_recommended'
  | 'covered_rv_spot'
  | 'max_vehicle_length'
  | 'allows_rv'
  | 'pull_through'
  | 'back_in'
  | 'pull_in'
  | '20_amp'
  | '30_amp'
  | '50_amp'
  | '100_amp'
  | '110_amp'
  | 'full_hookups'
  | 'electricity_hookups'
  | 'water_hookups'
  | 'sewer_hookups'
  | 'allows_generators'
  | 'dump_station'
  | 'grey_water_discharge'
  | 'docks_access'
  | 'ev_charging'
  | 'gated_entrance'
  | 'community_fire_pit'
  | 'basketball_court'
  | 'beach'
  | 'golf_course'
  | 'dog_park'
  | 'event_center'
  | 'clubhouse'
  | 'business_center'
  | 'community_swimming_pool'
  | 'community_hot_tub'
  | 'community_laundry'
  | 'library'
  | 'mini_golf'
  | 'personal_mailboxes'
  | 'playground'
  | 'propane_fills'
  | 'recreation_center'
  | 'restaurants_on_site'
  | 'rv_storage'
  | 'snack_bar'
  | 'bike_rentals'
  | 'boat_rentals';

export type AMENITIES_FOR_LODGING_CAMPSITES_KEYS =
  | 'bedrooms'
  | 'beds'
  | 'sleeps'
  | 'blankets'
  | 'linens'
  | 'pillows'
  | 'potable_water'
  | 'refrigerator'
  | 'microwave'
  | 'oven'
  | 'stove'
  | 'bathrooms'
  | 'indoor_shower'
  | 'outdoor_shower'
  | 'flush_toilet'
  | 'compost_toilet'
  | 'bath_towels'
  | 'toiletries'
  | 'hot_water'
  | 'wifi'
  | 'workspace'
  | 'air_conditioning'
  | 'heating'
  | 'ceiling_fan'
  | 'indoor_fireplace'
  | 'co_detector'
  | 'smoke_detector'
  | 'fire_extinguisher'
  | 'first_aid_kit'
  | 'ada_accessible'
  | 'wheelchair_accessible'
  | 'fire_pit'
  | 'grill'
  | 'hot_tub'
  | 'outdoor_furniture'
  | 'pool'
  | 'picnic_table'
  | 'patio_deck_or_balcony'
  | 'kitchen_sink'
  | 'dining_table'
  | 'coffee_maker'
  | 'cooking_basics'
  | 'pots_and_pans'
  | 'dishes_and_utensils'
  | 'tv'
  | 'allows_pets'
  | 'size_area'
  | 'firewood'
  | 'trash_bins'
  | 'grass_terrain'
  | 'field_terrain'
  | 'dirt_terrain'
  | 'gravel_terrain'
  | 'concrete_terrain'
  | 'allows_campfires'
  | '4wd_recommended'
  | '2wd_recommended'
  | 'max_vehicle_length'
  | 'allows_rv'
  | 'pull_through'
  | 'back_in'
  | 'pull_in'
  | 'docks_access'
  | 'ev_charging'
  | 'gated_entrance'
  | 'community_fire_pit'
  | 'basketball_court'
  | 'beach'
  | 'golf_course'
  | 'dog_park'
  | 'event_center'
  | 'clubhouse'
  | 'business_center'
  | 'community_swimming_pool'
  | 'community_hot_tub'
  | 'community_laundry'
  | 'library'
  | 'mini_golf'
  | 'personal_mailboxes'
  | 'playground'
  | 'propane_fills'
  | 'recreation_center'
  | 'restaurants_on_site'
  | 'rv_storage'
  | 'snack_bar'
  | 'bike_rentals'
  | 'boat_rentals';

export type AMENITIES_FOR_TENT_CAMPSITES_KEYS =
  | 'sleeps'
  | 'potable_water'
  | 'bathrooms'
  | 'indoor_shower'
  | 'outdoor_shower'
  | 'flush_toilet'
  | 'compost_toilet'
  | 'hot_water'
  | 'wifi'
  | 'ada_accessible'
  | 'wheelchair_accessible'
  | 'fire_pit'
  | 'grill'
  | 'hot_tub'
  | 'outdoor_furniture'
  | 'pool'
  | 'picnic_table'
  | 'patio_deck_or_balcony'
  | 'cooking_basics'
  | 'pots_and_pans'
  | 'dishes_and_utensils'
  | 'tv'
  | 'allows_pets'
  | 'size_area'
  | 'firewood'
  | 'trash_bins'
  | 'grass_terrain'
  | 'field_terrain'
  | 'dirt_terrain'
  | 'gravel_terrain'
  | 'concrete_terrain'
  | 'allows_campfires'
  | '4wd_recommended'
  | '2wd_recommended'
  | 'docks_access'
  | 'ev_charging'
  | 'gated_entrance'
  | 'community_fire_pit'
  | 'basketball_court'
  | 'beach'
  | 'golf_course'
  | 'dog_park'
  | 'event_center'
  | 'clubhouse'
  | 'business_center'
  | 'community_swimming_pool'
  | 'community_hot_tub'
  | 'community_laundry'
  | 'library'
  | 'mini_golf'
  | 'personal_mailboxes'
  | 'playground'
  | 'propane_fills'
  | 'recreation_center'
  | 'restaurants_on_site'
  | 'rv_storage'
  | 'snack_bar'
  | 'bike_rentals'
  | 'boat_rentals';

type AMENITIES_FOR_STATIONARY_CAMPER_CAMPSITES_KEYS =
  // RV site amenities
  | 'full_hookups'
  | 'electricity_hookups'
  | 'water_hookups'
  | 'grass_terrain'
  | 'field_terrain'
  | 'dirt_terrain'
  | 'gravel_terrain'
  | 'concrete_terrain'
  | 'covered_rv_spot'
  // RV amenities
  | 'awning'
  | 'extra_storage'
  | 'audio_inputs'
  | 'radio'
  | 'satellite'
  | 'cd_player'
  // lodging site amenities
  | 'ada_accessible'
  | 'air_conditioning'
  | 'bath_towels'
  | 'bbq_grill'
  | 'blankets'
  | 'carbon_monoxide_detector'
  | 'ceiling_fan'
  | 'coffee_maker'
  | 'compost_toilet'
  | 'cooking_basics'
  | 'dining_table'
  | 'dishes_and_utensils'
  | 'dryer'
  | 'fire_extinguisher'
  | 'fire_pit'
  | 'first_aid_kit'
  | 'flush_toilet'
  | 'fresh_linens'
  | 'heating'
  | 'hot_water'
  | 'indoor_fireplace'
  | 'indoor_shower'
  | 'kitchen_sink'
  | 'microwave'
  | 'outdoor_furniture'
  | 'outdoor_shower'
  | 'oven'
  | 'picnic_table'
  | 'pillows'
  | 'portable_fan'
  | 'potable_water'
  | 'pots_and_pans'
  | 'refrigerator'
  | 'smoke_detector'
  | 'stove'
  | 'toilet_paper'
  | 'toiletries'
  | 'tv_dvd'
  | 'washer'
  | 'wheelchair_accessible'
  | 'wifi'
  | 'workspace';

type AMENITIES_FOR_CAMPSITES_KEYS =
  | AMENITIES_FOR_STAY_KEYS
  | AMENITIES_FOR_RV_CAMPSITES_KEYS
  | AMENITIES_FOR_LODGING_CAMPSITES_KEYS
  | AMENITIES_FOR_TENT_CAMPSITES_KEYS;

export type AMENITIES_FOR_CAMPGROUNDS_KEYS =
  | 'potable_water'
  | 'hot_water'
  | 'wifi'
  | 'air_conditioning'
  | 'heating'
  | 'ada_accessible'
  | 'wheelchair_accessible'
  | 'tv'
  | 'allows_pets'
  | 'onsite_showers'
  | 'onsite_bathrooms'
  | 'general_store'
  | 'firewood'
  | 'trash_bins'
  | 'allows_campfires'
  | 'allows_rv'
  | 'full_hookups'
  | 'electricity_hookups'
  | 'water_hookups'
  | 'sewer_hookups'
  | 'allows_generators'
  | 'dump_station'
  | 'grey_water_discharge'
  | 'docks_access'
  | 'ev_charging'
  | 'gated_entrance'
  | 'basketball_court'
  | 'beach'
  | 'golf_course'
  | 'dog_park'
  | 'event_center'
  | 'clubhouse'
  | 'business_center'
  | 'community_fire_pit'
  | 'community_bbq'
  | 'community_picnic_tables'
  | 'community_swimming_pool'
  | 'community_hot_tub'
  | 'community_laundry'
  | 'library'
  | 'mini_golf'
  | 'personal_mailboxes'
  | 'playground'
  | 'propane_fills'
  | 'recreation_center'
  | 'restaurants_on_site'
  | 'rv_storage'
  | 'snack_bar'
  | 'bike_rentals'
  | 'boat_rentals'
  | 'adult_only'
  | 'amusement_park'
  | 'arcade'
  | 'bathing_suit'
  | 'boat_ramp'
  | 'fifty_five_plus'
  | 'lgbtiqa'
  | 'golf_cart'
  | 'spa'
  | 'national_park'
  | 'state_park'
  | 'water_park'
  | 'wine'
  | 'marina';

export type TAmenitiesIcons =
  | {
      icon?: null;
      BonfireIcon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
      unavailableIcon?: null;
    }
  | {
      icon: ICONS;
      BonfireIcon?: null;
      unavailableIcon: ICONS | null;
    };

export const AMENITIES_FOR_STAY_ICONS: Record<AMENITIES_FOR_STAY_KEYS, TAmenitiesIcons> = {
  air_conditioning: {
    icon: ICONS.AIR_CONDITIONING_FOR_STAY,
    unavailableIcon: ICONS.AIR_CONDITIONING_FOR_STAY_UNAVAILABLE,
  },
  bath_towels: {
    icon: ICONS.BATH_TOWELS_FOR_STAY,
    unavailableIcon: ICONS.BATH_TOWELS_FOR_STAY_UNAVAILABLE,
  },
  ada_accessible: {
    icon: ICONS.WHEELCHAIR_ACCESSIBLE_FOR_STAY,
    unavailableIcon: ICONS.WHEELCHAIR_ACCESSIBLE_FOR_STAY_UNAVAILABLE,
  },
  wheelchair_accessible: {
    icon: ICONS.WHEELCHAIR_ACCESSIBLE_FOR_STAY,
    unavailableIcon: ICONS.WHEELCHAIR_ACCESSIBLE_FOR_STAY_UNAVAILABLE,
  },
  compost_toilet: {
    icon: ICONS.COMPOST_TOILET_FOR_STAY,
    unavailableIcon: ICONS.COMPOST_TOILET_FOR_STAY_UNAVAILABLE,
  },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  indoor_shower: {
    icon: ICONS.INDOOR_SHOWER_FOR_STAY,
    unavailableIcon: ICONS.INDOOR_SHOWER_FOR_STAY_UNAVAILABLE,
  },
  outdoor_shower: {
    icon: ICONS.OUTDOOR_SHOWER_FOR_STAY,
    unavailableIcon: ICONS.OUTDOOR_SHOWER_FOR_STAY_UNAVAILABLE,
  },
  toiletries: {
    icon: ICONS.TOILETRIES_FOR_STAY,
    unavailableIcon: ICONS.TOILETRIES_FOR_STAY_UNAVAILABLE,
  },
  flush_toilet: {
    icon: ICONS.FLUSH_TOILET_FOR_STAY,
    unavailableIcon: ICONS.FLUSH_TOILET_FOR_STAY_UNAVAILABLE,
  },
  toilet_paper: {
    icon: ICONS.TOILET_PAPER_FOR_STAY,
    unavailableIcon: ICONS.TOILET_PAPER_FOR_STAY_UNAVAILABLE,
  },
  tv_dvd: {
    icon: ICONS.TV_DVD_FOR_STAY,
    unavailableIcon: ICONS.TV_DVD_FOR_STAY_UNAVAILABLE,
  },
  dryer: {
    icon: ICONS.DRYER_FOR_STAY,
    unavailableIcon: ICONS.DRYER_FOR_STAY_UNAVAILABLE,
  },
  pillows: {
    icon: ICONS.PILLOWS_FOR_STAY,
    unavailableIcon: ICONS.PILLOWS_FOR_STAY_UNAVAILABLE,
  },
  blankets: {
    icon: ICONS.BLANKETS_FOR_STAY,
    unavailableIcon: ICONS.BLANKETS_FOR_STAY_UNAVAILABLE,
  },
  fresh_linens: {
    icon: ICONS.FRESH_LINENS_FOR_STAY,
    unavailableIcon: ICONS.FRESH_LINENS_FOR_STAY_UNAVAILABLE,
  },
  washer: {
    icon: ICONS.WASHER_FOR_STAY,
    unavailableIcon: ICONS.WASHER_FOR_STAY_UNAVAILABLE,
  },
  coffee_maker: {
    icon: ICONS.COFFEE_MAKER_FOR_STAY,
    unavailableIcon: ICONS.COFFEE_MAKER_FOR_STAY_UNAVAILABLE,
  },
  dishes_and_utensils: {
    icon: ICONS.DISHES_AND_UTENSILS_FOR_STAY,
    unavailableIcon: ICONS.DISHES_AND_UTENSILS_FOR_STAY_UNAVAILABLE,
  },
  potable_water: {
    icon: ICONS.POTABLE_WATER_FOR_STAY,
    unavailableIcon: ICONS.POTABLE_WATER_FOR_STAY_UNAVAILABLE,
  },
  cooking_basics: {
    icon: ICONS.COOKING_BASICS_FOR_STAY,
    unavailableIcon: ICONS.COOKING_BASICS_FOR_STAY_UNAVAILABLE,
  },
  pots_and_pans: {
    icon: ICONS.POTS_AND_PANS_FOR_STAY,
    unavailableIcon: ICONS.POTS_AND_PANS_FOR_STAY_UNAVAILABLE,
  },
  workspace: {
    icon: ICONS.WORKSPACE_FOR_STAY,
    unavailableIcon: ICONS.WORKSPACE_FOR_STAY_UNAVAILABLE,
  },
  outdoor_furniture: {
    icon: ICONS.OUTDOOR_FURNITURE_FOR_STAY,
    unavailableIcon: ICONS.OUTDOOR_FURNITURE_FOR_STAY_UNAVAILABLE,
  },
  patio_balcony_deck: {
    icon: ICONS.PATIO_BALCONY_DECK_FOR_STAY,
    unavailableIcon: ICONS.PATIO_BALCONY_DECK_FOR_STAY_UNAVAILABLE,
  },
  pool: {
    icon: ICONS.POOL_FOR_STAY,
    unavailableIcon: ICONS.POOL_FOR_STAY_UNAVAILABLE,
  },
  bbq_grill: {
    icon: ICONS.BBQ_GRILL_FOR_STAY,
    unavailableIcon: ICONS.BBQ_GRILL_FOR_STAY_UNAVAILABLE,
  },
  fire_pit: {
    icon: ICONS.FIRE_PIT_FOR_STAY,
    unavailableIcon: ICONS.FIRE_PIT_FOR_STAY_UNAVAILABLE,
  },
  hot_tub: {
    icon: ICONS.HOT_TUB_FOR_STAY,
    unavailableIcon: ICONS.HOT_TUB_FOR_STAY_UNAVAILABLE,
  },
  dining_table: {
    icon: ICONS.DINING_TABLE_FOR_STAY,
    unavailableIcon: ICONS.DINING_TABLE_FOR_STAY_UNAVAILABLE,
  },
  picnic_table: {
    icon: ICONS.PICNIC_TABLE_FOR_STAY,
    unavailableIcon: ICONS.PICNIC_TABLE_FOR_STAY_UNAVAILABLE,
  },
  first_aid_kit: {
    icon: ICONS.FIRST_AID_KIT_FOR_STAY,
    unavailableIcon: ICONS.FIRST_AID_KIT_FOR_STAY_UNAVAILABLE,
  },
  carbon_monoxide_detector: {
    icon: ICONS.CARBON_MONOXIDE_DETECTOR_FOR_STAY,
    unavailableIcon: ICONS.CARBON_MONOXIDE_DETECTOR_FOR_STAY_UNAVAILABLE,
  },
  fire_extinguisher: {
    icon: ICONS.FIRE_EXTINGUISHER_FOR_STAY,
    unavailableIcon: ICONS.FIRE_EXTINGUISHER_FOR_STAY_UNAVAILABLE,
  },
  smoke_detector: {
    icon: ICONS.SMOKE_DETECTOR_FOR_STAY,
    unavailableIcon: ICONS.SMOKE_DETECTOR_FOR_STAY_UNAVAILABLE,
  },
  wifi: {
    icon: ICONS.WIFI_FOR_STAY,
    unavailableIcon: ICONS.WIFI_FOR_STAY_UNAVAILABLE,
  },
  heating: {
    icon: ICONS.HEATING_FOR_STAY,
    unavailableIcon: ICONS.HEATING_FOR_STAY_UNAVAILABLE,
  },
  indoor_fireplace: {
    icon: ICONS.INDOOR_FIREPLACE_FOR_STAY,
    unavailableIcon: ICONS.INDOOR_FIREPLACE_FOR_STAY_UNAVAILABLE,
  },
  portable_fan: {
    icon: ICONS.CEILING_FAN_FOR_STAY,
    unavailableIcon: ICONS.CEILING_FAN_FOR_STAY_UNAVAILABLE,
  },
  ceiling_fan: {
    icon: ICONS.CEILING_FAN_FOR_STAY,
    unavailableIcon: ICONS.CEILING_FAN_FOR_STAY_UNAVAILABLE,
  },
  kitchen_sink: {
    icon: ICONS.KITCHEN_SINK_FOR_STAY,
    unavailableIcon: ICONS.KITCHEN_SINK_FOR_STAY_UNAVAILABLE,
  },
  microwave: {
    icon: ICONS.MICROWAVE_FOR_STAY,
    unavailableIcon: ICONS.MICROWAVE_FOR_STAY_UNAVAILABLE,
  },
  oven: {
    icon: ICONS.OVEN_FOR_STAY,
    unavailableIcon: ICONS.OVEN_FOR_STAY_UNAVAILABLE,
  },
  refrigerator: {
    icon: ICONS.REFRIGERATOR_FOR_STAY,
    unavailableIcon: ICONS.REFRIGERATOR_FOR_STAY_UNAVAILABLE,
  },
  stove: {
    icon: ICONS.STOVE_FOR_STAY,
    unavailableIcon: ICONS.STOVE_FOR_STAY_UNAVAILABLE,
  },
};

const AMENITIES_FOR_RV_CAMPSITES_ICONS: Record<AMENITIES_FOR_RV_CAMPSITES_KEYS, TAmenitiesIcons> = {
  sleeps: { BonfireIcon: AmenitiesBookingSleepsIcon },
  potable_water: { BonfireIcon: AmenitiesKitchenPotableWaterIcon },
  bathrooms: { BonfireIcon: AmenitiesBathroomBathroomsIcon },
  indoor_shower: { BonfireIcon: AmenitiesShowerInsideShowerInsideIcon },
  outdoor_shower: { BonfireIcon: AmenitiesShowerOutsideShowerOutsideIcon },
  flush_toilet: { BonfireIcon: AmenitiesToiletFlushIcon },
  compost_toilet: { BonfireIcon: AmenitiesToiletCompostIcon },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  wifi: { BonfireIcon: AmenitiesWifiIcon },
  ada_accessible: { BonfireIcon: AmenitiesAdaAdaIcon },
  wheelchair_accessible: { BonfireIcon: AmenitiesAdaAdaIcon },
  fire_pit: { BonfireIcon: AmenitiesOutdoorFirepitIcon },
  grill: { BonfireIcon: AmenitiesOutdoorGrillIcon },
  hot_tub: { BonfireIcon: AmenitiesOutdoorHotTubIcon },
  outdoor_furniture: { BonfireIcon: AmenitiesOutdoorOutdoorFurnitureIcon },
  pool: { BonfireIcon: AmenitiesOutdoorPoolIcon },
  picnic_table: { BonfireIcon: AmenitiesOutdoorPicnicTableIcon },
  patio_deck_or_balcony: { BonfireIcon: AmenitiesOutdoorPatioIcon },
  cooking_basics: { BonfireIcon: AmenitiesKitchenCookingBasicsIcon },
  pots_and_pans: { BonfireIcon: AmenitiesKitchenPotsAndPansIcon },
  dishes_and_utensils: { BonfireIcon: AmenitiesKitchenDishesAndUtensilsIcon },
  allows_pets: { BonfireIcon: AmenitiesPetsPetFriendlyIcon },
  size_area: { BonfireIcon: AmenitiesSizeAreaIcon },
  firewood: { BonfireIcon: AmenitiesFirewoodIcon },
  trash_bins: { BonfireIcon: AmenitiesDumpsterIcon },
  grass_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  field_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  dirt_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  gravel_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  concrete_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  allows_campfires: { BonfireIcon: AmenitiesOutdoorFirepitIcon },
  '4wd_recommended': { BonfireIcon: RoamlyWheelTireMonoIcon },
  '2wd_recommended': { BonfireIcon: RoamlyWheelTireMonoIcon },
  covered_rv_spot: { BonfireIcon: RoamlyShelterIcon },
  max_vehicle_length: { BonfireIcon: GeneralLengthIcon },
  allows_rv: { BonfireIcon: VehiclesVehiclesClassCSimpleIcon },
  pull_through: { BonfireIcon: AmenitiesParkingParkingIcon },
  back_in: { BonfireIcon: AmenitiesParkingParkingIcon },
  pull_in: { BonfireIcon: AmenitiesParkingParkingIcon },
  '20_amp': { BonfireIcon: AmenitiesElectricityPowerIcon },
  '30_amp': { BonfireIcon: AmenitiesElectricityPowerIcon },
  '50_amp': { BonfireIcon: AmenitiesElectricityPowerIcon },
  '100_amp': { BonfireIcon: AmenitiesElectricityPowerIcon },
  '110_amp': { BonfireIcon: AmenitiesElectricityPowerIcon },
  full_hookups: { BonfireIcon: AmenitiesElectricOutletIcon },
  electricity_hookups: { BonfireIcon: AmenitiesElectricOutletIcon },
  water_hookups: { BonfireIcon: AmenitiesWaterfuelFreshWaterIcon },
  sewer_hookups: { BonfireIcon: AmenitiesWaterfuelSewageTankIcon },
  allows_generators: { BonfireIcon: AmenitiesElectricityGeneratorIcon },
  dump_station: { BonfireIcon: AmenitiesDumpStationIcon },
  grey_water_discharge: { BonfireIcon: AmenitiesWaterfuelWatertankIcon },
  docks_access: { BonfireIcon: AmenitiesDockAccessIcon },
  ev_charging: { BonfireIcon: AmenitiesElectricityPowerIcon },
  gated_entrance: { BonfireIcon: AmenitiesOutdoorFenceIcon },
  community_fire_pit: { BonfireIcon: AmenitiesOutdoorFirepitIcon },
  basketball_court: { BonfireIcon: AmenitiesBasketballIcon },
  beach: { BonfireIcon: TerrainTerrainBeachIcon },
  golf_course: { BonfireIcon: AmenitiesGolfIcon },
  dog_park: { BonfireIcon: AmenitiesPetsPetFriendlyIcon },
  event_center: { BonfireIcon: AmenitiesEventCenterIcon },
  clubhouse: { BonfireIcon: AmenitiesClubhouseIcon },
  business_center: { BonfireIcon: GeneralBriefcaseIcon },
  community_swimming_pool: { BonfireIcon: AmenitiesOutdoorPoolIcon },
  community_hot_tub: { BonfireIcon: AmenitiesOutdoorHotTubIcon },
  community_laundry: { BonfireIcon: AmenitiesHomeWasherDryerIcon },
  library: { BonfireIcon: GeneralBookBookIcon },
  mini_golf: { BonfireIcon: AmenitiesMinigolfIcon },
  personal_mailboxes: { BonfireIcon: GeneralMailIcon },
  playground: { BonfireIcon: AmenitiesPlaygroundIcon },
  propane_fills: { BonfireIcon: AmenitiesPropaneIcon },
  recreation_center: { BonfireIcon: AmenitiesGameRoomIcon },
  restaurants_on_site: { BonfireIcon: AmenitiesKitchenSilverwareIcon },
  rv_storage: { BonfireIcon: AmenitiesRvStorageIcon },
  snack_bar: { BonfireIcon: AmenitiesSnackBarIcon },
  bike_rentals: { BonfireIcon: ActivitiesActivitiesBikingIcon },
  boat_rentals: { BonfireIcon: ActivitiesActivitiesBoatingIcon },
  allows_slideouts: { BonfireIcon: AmenitiesSlideoutIcon },
};

const AMENITIES_FOR_LODGING_CAMPSITES_ICONS: Record<
  AMENITIES_FOR_LODGING_CAMPSITES_KEYS,
  TAmenitiesIcons
> = {
  bedrooms: { BonfireIcon: AmenitiesBookingBedroomsIcon },
  beds: { BonfireIcon: AmenitiesBookingSleepsIcon },
  sleeps: { BonfireIcon: AmenitiesBookingSleepsIcon },
  blankets: { BonfireIcon: AmenitiesHomeBlanketsIcon },
  linens: { BonfireIcon: AmenitiesHomeLinensIcon },
  pillows: { BonfireIcon: AmenitiesPillowIcon },
  potable_water: { BonfireIcon: AmenitiesKitchenPotableWaterIcon },
  refrigerator: {
    icon: ICONS.REFRIGERATOR_FOR_STAY,
    unavailableIcon: ICONS.REFRIGERATOR_FOR_STAY_UNAVAILABLE,
  },
  microwave: { BonfireIcon: AmenitiesMicrowaveMicrowaveIcon },
  oven: { BonfireIcon: AmenitiesOvenOvenIcon },
  stove: { BonfireIcon: AmenitiesStoveStoveIcon },
  bathrooms: { BonfireIcon: AmenitiesBathroomBathroomsIcon },
  indoor_shower: { BonfireIcon: AmenitiesShowerInsideShowerInsideIcon },
  outdoor_shower: { BonfireIcon: AmenitiesShowerOutsideShowerOutsideIcon },
  flush_toilet: { BonfireIcon: AmenitiesToiletFlushIcon },
  compost_toilet: { BonfireIcon: AmenitiesToiletCompostIcon },
  bath_towels: { BonfireIcon: AmenitiesBathroomBathTowelsIcon },
  toiletries: { BonfireIcon: AmenitiesBathroomToiletriesIcon },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  wifi: { BonfireIcon: AmenitiesWifiIcon },
  workspace: { BonfireIcon: AmenitiesHomeWorkspaceIcon },
  air_conditioning: { BonfireIcon: AmenitiesAirConditionerAirConditionerIcon },
  heating: { BonfireIcon: AmenitiesHeaterAvailableIcon },
  ceiling_fan: { BonfireIcon: AmenitiesCeilingFanCeilingFanIcon },
  indoor_fireplace: { BonfireIcon: AmenitiesTemperatureIndoorFireplaceIcon },
  co_detector: { BonfireIcon: AmenitiesSafetyCarbonMonoxideDetectorIcon },
  smoke_detector: { BonfireIcon: AmenitiesSafetySmokeDetectorIcon },
  fire_extinguisher: { BonfireIcon: AmenitiesSafetyFireExtinguisherIcon },
  first_aid_kit: { BonfireIcon: AmenitiesSafetyFirstAidKitIcon },
  ada_accessible: { BonfireIcon: AmenitiesAdaAdaIcon },
  wheelchair_accessible: { BonfireIcon: AmenitiesAdaAdaIcon },
  fire_pit: { BonfireIcon: AmenitiesOutdoorFirepitIcon },
  grill: { BonfireIcon: AmenitiesOutdoorGrillIcon },
  hot_tub: { BonfireIcon: AmenitiesOutdoorHotTubIcon },
  outdoor_furniture: { BonfireIcon: AmenitiesOutdoorOutdoorFurnitureIcon },
  pool: { BonfireIcon: AmenitiesOutdoorPoolIcon },
  picnic_table: { BonfireIcon: AmenitiesOutdoorPicnicTableIcon },
  patio_deck_or_balcony: { BonfireIcon: AmenitiesOutdoorPatioIcon },
  kitchen_sink: { BonfireIcon: AmenitiesSinkKitchenSinkIcon },
  dining_table: { BonfireIcon: AmenitiesOutdoorDiningTableIcon },
  coffee_maker: { BonfireIcon: AmenitiesKitchenCoffeeMakerIcon },
  cooking_basics: { BonfireIcon: AmenitiesKitchenCookingBasicsIcon },
  pots_and_pans: { BonfireIcon: AmenitiesKitchenPotsAndPansIcon },
  dishes_and_utensils: { BonfireIcon: AmenitiesKitchenDishesAndUtensilsIcon },
  tv: { BonfireIcon: AmenitiesTvTvIcon },
  allows_pets: { BonfireIcon: AmenitiesPetsPetFriendlyIcon },
  size_area: { BonfireIcon: AmenitiesSizeAreaIcon },
  firewood: { BonfireIcon: AmenitiesFirewoodIcon },
  trash_bins: { BonfireIcon: AmenitiesDumpsterIcon },
  grass_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  field_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  dirt_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  gravel_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  concrete_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  allows_campfires: { BonfireIcon: AmenitiesOutdoorFirepitIcon },
  '4wd_recommended': { BonfireIcon: RoamlyWheelTireMonoIcon },
  '2wd_recommended': { BonfireIcon: RoamlyWheelTireMonoIcon },
  max_vehicle_length: { BonfireIcon: GeneralLengthIcon },
  allows_rv: { BonfireIcon: VehiclesVehiclesClassCSimpleIcon },
  pull_through: { BonfireIcon: AmenitiesParkingParkingIcon },
  back_in: { BonfireIcon: AmenitiesParkingParkingIcon },
  pull_in: { BonfireIcon: AmenitiesParkingParkingIcon },
  docks_access: { BonfireIcon: AmenitiesDockAccessIcon },
  ev_charging: { BonfireIcon: AmenitiesElectricityPowerIcon },
  gated_entrance: { BonfireIcon: AmenitiesOutdoorFenceIcon },
  community_fire_pit: { BonfireIcon: AmenitiesOutdoorFirepitIcon },
  basketball_court: { BonfireIcon: AmenitiesBasketballIcon },
  beach: { BonfireIcon: TerrainTerrainBeachIcon },
  golf_course: { BonfireIcon: AmenitiesGolfIcon },
  dog_park: { BonfireIcon: AmenitiesPetsPetFriendlyIcon },
  event_center: { BonfireIcon: AmenitiesEventCenterIcon },
  clubhouse: { BonfireIcon: AmenitiesClubhouseIcon },
  business_center: { BonfireIcon: GeneralBriefcaseIcon },
  community_swimming_pool: { BonfireIcon: AmenitiesOutdoorPoolIcon },
  community_hot_tub: { BonfireIcon: AmenitiesOutdoorHotTubIcon },
  community_laundry: { BonfireIcon: AmenitiesHomeWasherDryerIcon },
  library: { BonfireIcon: GeneralBookBookIcon },
  mini_golf: { BonfireIcon: AmenitiesMinigolfIcon },
  personal_mailboxes: { BonfireIcon: GeneralMailIcon },
  playground: { BonfireIcon: AmenitiesPlaygroundIcon },
  propane_fills: { BonfireIcon: AmenitiesPropaneIcon },
  recreation_center: { BonfireIcon: AmenitiesGameRoomIcon },
  restaurants_on_site: { BonfireIcon: AmenitiesKitchenSilverwareIcon },
  rv_storage: { BonfireIcon: AmenitiesRvStorageIcon },
  snack_bar: { BonfireIcon: AmenitiesSnackBarIcon },
  bike_rentals: { BonfireIcon: ActivitiesActivitiesBikingIcon },
  boat_rentals: { BonfireIcon: ActivitiesActivitiesBoatingIcon },
};

const AMENITIES_FOR_TENT_CAMPSITES_ICONS: Record<
  AMENITIES_FOR_TENT_CAMPSITES_KEYS,
  TAmenitiesIcons
> = {
  sleeps: { BonfireIcon: AmenitiesBookingSleepsIcon },
  potable_water: { BonfireIcon: AmenitiesKitchenPotableWaterIcon },
  bathrooms: { BonfireIcon: AmenitiesBathroomBathroomsIcon },
  indoor_shower: { BonfireIcon: AmenitiesShowerInsideShowerInsideIcon },
  outdoor_shower: { BonfireIcon: AmenitiesShowerOutsideShowerOutsideIcon },
  flush_toilet: { BonfireIcon: AmenitiesToiletFlushIcon },
  compost_toilet: { BonfireIcon: AmenitiesToiletCompostIcon },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  wifi: { BonfireIcon: AmenitiesWifiIcon },
  ada_accessible: { BonfireIcon: AmenitiesAdaAdaIcon },
  wheelchair_accessible: { BonfireIcon: AmenitiesAdaAdaIcon },
  fire_pit: { BonfireIcon: AmenitiesOutdoorFirepitIcon },
  grill: { BonfireIcon: AmenitiesOutdoorGrillIcon },
  hot_tub: { BonfireIcon: AmenitiesOutdoorHotTubIcon },
  outdoor_furniture: { BonfireIcon: AmenitiesOutdoorOutdoorFurnitureIcon },
  pool: { BonfireIcon: AmenitiesOutdoorPoolIcon },
  picnic_table: { BonfireIcon: AmenitiesOutdoorPicnicTableIcon },
  patio_deck_or_balcony: { BonfireIcon: AmenitiesOutdoorPatioIcon },
  cooking_basics: { BonfireIcon: AmenitiesKitchenCookingBasicsIcon },
  pots_and_pans: { BonfireIcon: AmenitiesKitchenPotsAndPansIcon },
  dishes_and_utensils: { BonfireIcon: AmenitiesKitchenDishesAndUtensilsIcon },
  tv: { BonfireIcon: AmenitiesTvTvIcon },
  allows_pets: { BonfireIcon: AmenitiesPetsPetFriendlyIcon },
  size_area: { BonfireIcon: AmenitiesSizeAreaIcon },
  firewood: { BonfireIcon: AmenitiesFirewoodIcon },
  trash_bins: { BonfireIcon: AmenitiesDumpsterIcon },
  grass_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  field_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  dirt_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  gravel_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  concrete_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  allows_campfires: { BonfireIcon: AmenitiesOutdoorFirepitIcon },
  '4wd_recommended': { BonfireIcon: RoamlyWheelTireMonoIcon },
  '2wd_recommended': { BonfireIcon: RoamlyWheelTireMonoIcon },
  docks_access: { BonfireIcon: AmenitiesDockAccessIcon },
  ev_charging: { BonfireIcon: AmenitiesElectricityPowerIcon },
  gated_entrance: { BonfireIcon: AmenitiesOutdoorFenceIcon },
  community_fire_pit: { BonfireIcon: AmenitiesOutdoorFirepitIcon },
  basketball_court: { BonfireIcon: AmenitiesBasketballIcon },
  beach: { BonfireIcon: TerrainTerrainBeachIcon },
  golf_course: { BonfireIcon: AmenitiesGolfIcon },
  dog_park: { BonfireIcon: AmenitiesPetsPetFriendlyIcon },
  event_center: { BonfireIcon: AmenitiesEventCenterIcon },
  clubhouse: { BonfireIcon: AmenitiesClubhouseIcon },
  business_center: { BonfireIcon: GeneralBriefcaseIcon },
  community_swimming_pool: { BonfireIcon: AmenitiesOutdoorPoolIcon },
  community_hot_tub: { BonfireIcon: AmenitiesOutdoorHotTubIcon },
  community_laundry: { BonfireIcon: AmenitiesHomeWasherDryerIcon },
  library: { BonfireIcon: GeneralBookBookIcon },
  mini_golf: { BonfireIcon: AmenitiesMinigolfIcon },
  personal_mailboxes: { BonfireIcon: GeneralMailIcon },
  playground: { BonfireIcon: AmenitiesPlaygroundIcon },
  propane_fills: { BonfireIcon: AmenitiesPropaneIcon },
  recreation_center: { BonfireIcon: AmenitiesGameRoomIcon },
  restaurants_on_site: { BonfireIcon: AmenitiesKitchenSilverwareIcon },
  rv_storage: { BonfireIcon: AmenitiesRvStorageIcon },
  snack_bar: { BonfireIcon: AmenitiesSnackBarIcon },
  bike_rentals: { BonfireIcon: ActivitiesActivitiesBikingIcon },
  boat_rentals: { BonfireIcon: ActivitiesActivitiesBoatingIcon },
};
export const AMENITIES_FOR_CAMPSITES_ICONS: Record<AMENITIES_FOR_CAMPSITES_KEYS, TAmenitiesIcons> =
  {
    ...AMENITIES_FOR_RV_CAMPSITES_ICONS,
    ...AMENITIES_FOR_LODGING_CAMPSITES_ICONS,
    ...AMENITIES_FOR_TENT_CAMPSITES_ICONS,
    ...AMENITIES_FOR_STAY_ICONS,
  };
export const AMENITIES_FOR_STATIONARY_CAMPER_CAMPSITES_ICONS: Record<
  AMENITIES_FOR_STATIONARY_CAMPER_CAMPSITES_KEYS,
  TAmenitiesIcons
> = {
  ada_accessible: { BonfireIcon: AmenitiesAdaAdaIcon },
  air_conditioning: { BonfireIcon: AmenitiesAirConditionerAirConditionerIcon },
  bath_towels: { BonfireIcon: AmenitiesBathroomBathTowelsIcon },
  bbq_grill: {
    icon: ICONS.BBQ_GRILL_FOR_STAY,
    unavailableIcon: ICONS.BBQ_GRILL_FOR_STAY_UNAVAILABLE,
  },
  blankets: { BonfireIcon: AmenitiesHomeBlanketsIcon },
  carbon_monoxide_detector: {
    icon: ICONS.CARBON_MONOXIDE_DETECTOR_FOR_STAY,
    unavailableIcon: ICONS.CARBON_MONOXIDE_DETECTOR_FOR_STAY_UNAVAILABLE,
  },
  ceiling_fan: { BonfireIcon: AmenitiesCeilingFanCeilingFanIcon },
  coffee_maker: { BonfireIcon: AmenitiesKitchenCoffeeMakerIcon },
  compost_toilet: { BonfireIcon: AmenitiesToiletCompostIcon },
  cooking_basics: { BonfireIcon: AmenitiesKitchenCookingBasicsIcon },
  dining_table: { BonfireIcon: AmenitiesOutdoorDiningTableIcon },
  dishes_and_utensils: { BonfireIcon: AmenitiesKitchenDishesAndUtensilsIcon },
  dryer: {
    icon: ICONS.DRYER_FOR_STAY,
    unavailableIcon: ICONS.DRYER_FOR_STAY_UNAVAILABLE,
  },
  fire_extinguisher: { BonfireIcon: AmenitiesSafetyFireExtinguisherIcon },
  fire_pit: { BonfireIcon: AmenitiesOutdoorFirepitIcon },
  first_aid_kit: { BonfireIcon: AmenitiesSafetyFirstAidKitIcon },
  flush_toilet: { BonfireIcon: AmenitiesToiletFlushIcon },
  fresh_linens: {
    icon: ICONS.FRESH_LINENS_FOR_STAY,
    unavailableIcon: ICONS.FRESH_LINENS_FOR_STAY_UNAVAILABLE,
  },
  heating: { BonfireIcon: AmenitiesHeaterAvailableIcon },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  indoor_fireplace: { BonfireIcon: AmenitiesTemperatureIndoorFireplaceIcon },
  indoor_shower: { BonfireIcon: AmenitiesShowerInsideShowerInsideIcon },
  kitchen_sink: { BonfireIcon: AmenitiesSinkKitchenSinkIcon },
  microwave: { BonfireIcon: AmenitiesMicrowaveMicrowaveIcon },
  outdoor_furniture: { BonfireIcon: AmenitiesOutdoorOutdoorFurnitureIcon },
  outdoor_shower: { BonfireIcon: AmenitiesShowerOutsideShowerOutsideIcon },
  oven: { BonfireIcon: AmenitiesOvenOvenIcon },
  picnic_table: { BonfireIcon: AmenitiesOutdoorPicnicTableIcon },
  pillows: { BonfireIcon: AmenitiesPillowIcon },
  portable_fan: {
    icon: ICONS.CEILING_FAN_FOR_STAY,
    unavailableIcon: ICONS.CEILING_FAN_FOR_STAY_UNAVAILABLE,
  },
  potable_water: { BonfireIcon: AmenitiesKitchenPotableWaterIcon },
  pots_and_pans: { BonfireIcon: AmenitiesKitchenPotsAndPansIcon },
  refrigerator: {
    icon: ICONS.REFRIGERATOR_FOR_STAY,
    unavailableIcon: ICONS.REFRIGERATOR_FOR_STAY_UNAVAILABLE,
  },
  smoke_detector: { BonfireIcon: AmenitiesSafetySmokeDetectorIcon },
  stove: { BonfireIcon: AmenitiesStoveStoveIcon },
  toilet_paper: {
    icon: ICONS.TOILET_PAPER_FOR_STAY,
    unavailableIcon: ICONS.TOILET_PAPER_FOR_STAY_UNAVAILABLE,
  },
  toiletries: { BonfireIcon: AmenitiesBathroomToiletriesIcon },
  tv_dvd: {
    icon: ICONS.TV_DVD_FOR_STAY,
    unavailableIcon: ICONS.TV_DVD_FOR_STAY_UNAVAILABLE,
  },
  washer: {
    icon: ICONS.WASHER_FOR_STAY,
    unavailableIcon: ICONS.WASHER_FOR_STAY_UNAVAILABLE,
  },
  wheelchair_accessible: { BonfireIcon: AmenitiesAdaAdaIcon },
  wifi: { BonfireIcon: AmenitiesWifiIcon },
  workspace: { BonfireIcon: AmenitiesHomeWorkspaceIcon },
  full_hookups: { BonfireIcon: AmenitiesElectricOutletIcon },
  electricity_hookups: { BonfireIcon: AmenitiesElectricOutletIcon },
  water_hookups: { BonfireIcon: AmenitiesWaterfuelFreshWaterIcon },
  grass_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  field_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  dirt_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  gravel_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  concrete_terrain: { BonfireIcon: AmenitiesTerrainIcon },
  covered_rv_spot: { BonfireIcon: RoamlyShelterIcon },
  audio_inputs: {
    icon: ICONS.AUDIO_INPUTS,
    unavailableIcon: ICONS.AUDIO_INPUTS_UNAVAILABLE,
  },
  radio: {
    icon: ICONS.RADIO,
    unavailableIcon: ICONS.RADIO_UNAVAILABLE,
  },
  satellite: {
    icon: ICONS.SATELLITE,
    unavailableIcon: ICONS.SATELLITE_UNAVAILABLE,
  },
  cd_player: {
    icon: ICONS.CD_PLAYER,
    unavailableIcon: ICONS.CD_PLAYER_UNAVAILABLE,
  },
  awning: {
    icon: ICONS.AWNING,
    unavailableIcon: ICONS.AWNING_UNAVAILABLE,
  },
  extra_storage: {
    icon: ICONS.EXTRA_STORAGE,
    unavailableIcon: ICONS.EXTRA_STORAGE_UNAVAILABLE,
  },
};

export const AMENITIES_FOR_CAMPGROUND_ICONS: Record<
  AMENITIES_FOR_CAMPGROUNDS_KEYS,
  TAmenitiesIcons
> = {
  spa: { BonfireIcon: AmenitiesSpaIcon },
  state_park: { BonfireIcon: AmenitiesStateParkIcon },
  water_park: { BonfireIcon: AmenitiesWaterParkIcon },
  wine: { BonfireIcon: AmenitiesWineIcon },
  boat_ramp: { BonfireIcon: AmenitiesBoatRampIcon },
  national_park: { BonfireIcon: AmenitiesNationalParkIcon },
  golf_cart: { BonfireIcon: AmenitiesGolfCartIcon },
  marina: { BonfireIcon: AmenitiesMarinaIcon },
  fifty_five_plus: { BonfireIcon: AmenitiesFiftyFivePlusIcon },
  lgbtiqa: { BonfireIcon: AmenitiesLgbtiqaIcon },
  amusement_park: { BonfireIcon: AmenitiesAmusementParkIcon },
  arcade: { BonfireIcon: AmenitiesArcadeIcon },
  bathing_suit: { BonfireIcon: AmenitiesBathingSuitIcon },
  adult_only: { BonfireIcon: AmenitiesAdultOnlyIcon },
  potable_water: { BonfireIcon: AmenitiesKitchenPotableWaterIcon },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  wifi: { BonfireIcon: AmenitiesWifiIcon },
  air_conditioning: { BonfireIcon: AmenitiesAirConditionerAirConditionerIcon },
  heating: { BonfireIcon: AmenitiesHeaterAvailableIcon },
  ada_accessible: { BonfireIcon: AmenitiesAdaAdaIcon },
  wheelchair_accessible: { BonfireIcon: AmenitiesAdaAdaIcon },
  tv: { BonfireIcon: AmenitiesTvTvIcon },
  allows_pets: { BonfireIcon: AmenitiesPetsPetFriendlyIcon },
  onsite_showers: { BonfireIcon: AmenitiesShowerOutsideShowerOutsideIcon },
  onsite_bathrooms: { BonfireIcon: AmenitiesBathroomBathroomsIcon },
  general_store: { BonfireIcon: AccomodationAccomodationTypeHouseIcon },
  firewood: { BonfireIcon: AmenitiesFirewoodIcon },
  trash_bins: { BonfireIcon: AmenitiesDumpsterIcon },
  allows_campfires: { BonfireIcon: AmenitiesOutdoorFirepitIcon },
  allows_rv: { BonfireIcon: VehiclesVehiclesClassCSimpleIcon },
  full_hookups: { BonfireIcon: AmenitiesElectricOutletIcon },
  electricity_hookups: { BonfireIcon: AmenitiesElectricOutletIcon },
  water_hookups: { BonfireIcon: AmenitiesWaterfuelFreshWaterIcon },
  sewer_hookups: { BonfireIcon: AmenitiesWaterfuelSewageTankIcon },
  allows_generators: { BonfireIcon: AmenitiesElectricityGeneratorIcon },
  dump_station: { BonfireIcon: AmenitiesDumpStationIcon },
  grey_water_discharge: { BonfireIcon: AmenitiesWaterfuelWatertankIcon },
  docks_access: { BonfireIcon: AmenitiesDockAccessIcon },
  ev_charging: { BonfireIcon: AmenitiesElectricityPowerIcon },
  gated_entrance: { BonfireIcon: AmenitiesOutdoorFenceIcon },
  basketball_court: { BonfireIcon: AmenitiesBasketballIcon },
  beach: { BonfireIcon: TerrainTerrainBeachIcon },
  golf_course: { BonfireIcon: AmenitiesGolfIcon },
  dog_park: { BonfireIcon: AmenitiesPetsPetFriendlyIcon },
  event_center: { BonfireIcon: AmenitiesEventCenterIcon },
  clubhouse: { BonfireIcon: AmenitiesClubhouseIcon },
  business_center: { BonfireIcon: GeneralBriefcaseIcon },
  community_fire_pit: { BonfireIcon: AmenitiesOutdoorFirepitIcon },
  community_bbq: { BonfireIcon: AmenitiesOutdoorGrillIcon },
  community_picnic_tables: { BonfireIcon: AmenitiesOutdoorPicnicTableIcon },
  community_swimming_pool: { BonfireIcon: AmenitiesOutdoorPoolIcon },
  community_hot_tub: { BonfireIcon: AmenitiesOutdoorHotTubIcon },
  community_laundry: { BonfireIcon: AmenitiesHomeWasherDryerIcon },
  library: { BonfireIcon: GeneralBookBookIcon },
  mini_golf: { BonfireIcon: AmenitiesMinigolfIcon },
  personal_mailboxes: { BonfireIcon: GeneralMailIcon },
  playground: { BonfireIcon: AmenitiesPlaygroundIcon },
  propane_fills: { BonfireIcon: AmenitiesPropaneIcon },
  recreation_center: { BonfireIcon: AmenitiesGameRoomIcon },
  restaurants_on_site: { BonfireIcon: AmenitiesKitchenSilverwareIcon },
  rv_storage: { BonfireIcon: AmenitiesRvStorageIcon },
  snack_bar: { BonfireIcon: AmenitiesSnackBarIcon },
  bike_rentals: { BonfireIcon: ActivitiesActivitiesBikingIcon },
  boat_rentals: { BonfireIcon: ActivitiesActivitiesBoatingIcon },
};

export const AMENITIES_ICONS: Record<AMENITIES_KEYS, TAmenitiesIcons> = {
  air_conditioner: {
    icon: ICONS.AIR_CONDITIONER,
    unavailableIcon: ICONS.AIR_CONDITIONER_UNAVAILABLE,
  },
  bath_towels: {
    icon: ICONS.BATH_TOWELS_FOR_STAY,
    unavailableIcon: ICONS.BATH_TOWELS_FOR_STAY_UNAVAILABLE,
  },
  bathroom_sink: {
    icon: ICONS.KITCHEN_SINK,
    unavailableIcon: ICONS.KITCHEN_SINK_UNAVAILABLE,
  },
  toilet_paper: {
    icon: ICONS.TOILET_PAPER_FOR_STAY,
    unavailableIcon: ICONS.TOILETRIES_FOR_STAY_UNAVAILABLE,
  },
  toiletries: {
    icon: ICONS.TOILETRIES_FOR_STAY,
    unavailableIcon: ICONS.TOILETRIES_FOR_STAY_UNAVAILABLE,
  },
  hot_water: {
    icon: ICONS.HOT_WATER_FOR_STAY,
    unavailableIcon: ICONS.HOT_WATER_FOR_STAY_UNAVAILABLE,
  },
  potable_water: {
    icon: ICONS.POTABLE_WATER_FOR_STAY,
    unavailableIcon: ICONS.POTABLE_WATER_FOR_STAY_UNAVAILABLE,
  },
  coffee_maker: {
    icon: ICONS.COFFEE_MAKER_FOR_STAY,
    unavailableIcon: ICONS.COFFEE_MAKER_FOR_STAY_UNAVAILABLE,
  },
  pots_and_pans: {
    icon: ICONS.POTS_AND_PANS_FOR_STAY,
    unavailableIcon: ICONS.POTS_AND_PANS_FOR_STAY_UNAVAILABLE,
  },
  dishes_and_utensils: {
    icon: ICONS.DISHES_AND_UTENSILS_FOR_STAY,
    unavailableIcon: ICONS.DISHES_AND_UTENSILS_FOR_STAY_UNAVAILABLE,
  },
  cooking_basics: {
    icon: ICONS.COOKING_BASICS_FOR_STAY,
    unavailableIcon: ICONS.COOKING_BASICS_FOR_STAY_UNAVAILABLE,
  },
  hookup_water: {
    BonfireIcon: AmenitiesWaterfuelWatertankIcon,
  },
  hookup_electric: {
    BonfireIcon: AmenitiesElectricityPowerIcon,
  },
  hookup_sewer: {
    BonfireIcon: AmenitiesWaterfuelSewageTankIcon,
  },
  heater: {
    icon: ICONS.HEATER,
    unavailableIcon: ICONS.HEATER_UNAVAILABLE,
  },
  ceiling_fan: {
    icon: ICONS.CEILING_FAN,
    unavailableIcon: ICONS.CEILING_FAN_UNAVAILABLE,
  },
  refrigerator: {
    icon: ICONS.FRIDGE,
    unavailableIcon: ICONS.FRIDGE_UNAVAILABLE,
  },
  kitchen_sink: {
    icon: ICONS.KITCHEN_SINK,
    unavailableIcon: ICONS.KITCHEN_SINK_UNAVAILABLE,
  },
  microwave: {
    icon: ICONS.MICROWAVE,
    unavailableIcon: ICONS.MICROWAVE_UNAVAILABLE,
  },
  stove: {
    icon: ICONS.STOVE,
    unavailableIcon: ICONS.STOVE_UNAVAILABLE,
  },
  dining_table: {
    icon: ICONS.TABLE,
    unavailableIcon: ICONS.TABLE_UNAVAILABLE,
  },
  oven: {
    icon: ICONS.OVEN,
    unavailableIcon: ICONS.OVEN_UNAVAILABLE,
  },
  inside_shower: {
    icon: ICONS.SHOWER_INSIDE,
    unavailableIcon: ICONS.SHOWER_INSIDE_UNAVAILABLE,
  },
  toilet: {
    icon: ICONS.TOILET,
    unavailableIcon: ICONS.TOILET_UNAVAILABLE,
  },
  outside_shower: {
    icon: ICONS.SHOWER_OUTSIDE,
    unavailableIcon: ICONS.SHOWER_OUTSIDE_UNAVAILABLE,
  },
  generator: {
    icon: ICONS.GENERATOR,
    unavailableIcon: ICONS.GENERATOR_UNAVAILABLE,
  },
  backup_camera: {
    icon: ICONS.BACKUP_CAMERA,
    unavailableIcon: ICONS.BACKUP_CAMERA_UNAVAILABLE,
  },
  tv_dvd: {
    icon: ICONS.TV,
    unavailableIcon: ICONS.TV_UNAVAILABLE,
  },
  leveling_jacks: {
    icon: ICONS.LEVELING_JACK,
    unavailableIcon: ICONS.LEVELING_JACK_UNAVAILABLE,
  },
  wifi: {
    icon: ICONS.WIFI,
    unavailableIcon: ICONS.WIFI_UNAVAILABLE,
  },
  tow_hitch: {
    icon: ICONS.TOW_HITCH,
    unavailableIcon: ICONS.TOW_HITCH_UNAVAILABLE,
  },
  awning: {
    icon: ICONS.AWNING,
    unavailableIcon: ICONS.AWNING_UNAVAILABLE,
  },
  satellite: {
    icon: ICONS.SATELLITE,
    unavailableIcon: ICONS.SATELLITE_UNAVAILABLE,
  },
  bike_rack: {
    icon: ICONS.BIKE_RACK,
    unavailableIcon: ICONS.BIKE_RACK_UNAVAILABLE,
  },
  washer_dryer: {
    icon: ICONS.WASHER_DRYER,
    unavailableIcon: ICONS.WASHER_DRYER_UNAVAILABLE,
  },
  audio_inputs: {
    icon: ICONS.AUDIO_INPUTS,
    unavailableIcon: ICONS.AUDIO_INPUTS_UNAVAILABLE,
  },
  inverter: {
    icon: ICONS.INVERTER,
    unavailableIcon: ICONS.INVERTER_UNAVAILABLE,
  },
  radio: {
    icon: ICONS.RADIO,
    unavailableIcon: ICONS.RADIO_UNAVAILABLE,
  },
  extra_storage: {
    icon: ICONS.EXTRA_STORAGE,
    unavailableIcon: ICONS.EXTRA_STORAGE_UNAVAILABLE,
  },
  solar: {
    icon: ICONS.SOLAR,
    unavailableIcon: ICONS.SOLAR_UNAVAILABLE,
  },
  cd_player: {
    icon: ICONS.CD_PLAYER,
    unavailableIcon: ICONS.CD_PLAYER_UNAVAILABLE,
  },
  handicap_accessible: {
    icon: ICONS.ACCESSIBILITY,
    unavailableIcon: ICONS.ACCESSIBILITY_UNAVAILABLE,
  },
  beds_bunk: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_dinette_conversion: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_fold_out_sofa: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_full: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_king: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_other: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_queen: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  beds_twin: {
    icon: ICONS.BED,
    unavailableIcon: null,
  },
  bluetooth: {
    BonfireIcon: AmenitiesEntertainmentBluetoothIcon,
  },
};
