import React, { useEffect, useState } from 'react';

import { useBreakpoint } from '@/hooks/useBreakpoint';

import { INavbarSearch } from '../../SearchHeader';
import { EVENT_OPEN_SEARCH_DATES } from '../events';
import MobileSearchMobileButton from './MobileSearchMobileButton';
import { MobileSearchModal } from './MobileSearchModal';

interface IMobileNavbarSearch extends INavbarSearch {
  onClose?: () => void;
  onOpen?: () => void;
  popularDestinations?: { label: string }[];
  isCampgroundSearch?: boolean;
  isGuestOccupancyAvailable?: boolean;
  isRvBundlesSearch?: boolean;
  scrollToSearchFilter?: () => void;
}

const MobileNavbarSearch: React.FC<IMobileNavbarSearch> = ({
  location,
  address,
  dates,
  guests,
  onSubmit,
  onChangeGuests,
  onChangeDates,
  onChangeAddress,
  popularDestinations,
  recentSearches,
  handleRecentSearchNavigation,
  maxRecentSearches,
  isCampgroundSearch,
  isGuestOccupancyAvailable,
  isRvBundlesSearch,
  scrollToSearchFilter,
}) => {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const { isMobile } = useBreakpoint();
  useEffect(() => {
    if (!isMobile) return;
    const handleOnOpenSearchDates = () => {
      if (isSearchModalOpen) return;
      setTimeout(() => setIsSearchModalOpen(true), 100);
    };
    document.addEventListener(EVENT_OPEN_SEARCH_DATES, handleOnOpenSearchDates);
    return () => document.removeEventListener(EVENT_OPEN_SEARCH_DATES, handleOnOpenSearchDates);
  }, [isMobile, isSearchModalOpen]);

  return (
    <div>
      <MobileSearchMobileButton
        address={address}
        dates={dates}
        guests={guests}
        onOpenMobileSearch={() => setIsSearchModalOpen(true)}
        isCampgroundSearch={isCampgroundSearch}
        isRvBundlesSearch={isRvBundlesSearch}
        scrollToSearchFilter={scrollToSearchFilter}
      />
      <MobileSearchModal
        location={location}
        address={address}
        dates={dates}
        guests={guests}
        isSearchModalOpen={isSearchModalOpen}
        onCloseHandler={() => {
          setIsSearchModalOpen(false);
        }}
        onSubmit={onSubmit}
        onChangeGuests={onChangeGuests}
        onChangeDates={onChangeDates}
        onChangeAddress={onChangeAddress}
        popularDestinations={popularDestinations}
        recentSearches={recentSearches}
        handleRecentSearchNavigation={handleRecentSearchNavigation}
        maxRecentSearches={maxRecentSearches}
        isGuestOccupancyAvailable={isGuestOccupancyAvailable}
        isCampgroundSearch={isCampgroundSearch}
      />
    </div>
  );
};

export default MobileNavbarSearch;
