import {
  GeneralCompassIcon,
  GeneralMessageMessageSquareIcon,
  GeneralStarOutlineIcon,
  Popover,
} from '@outdoorsyco/bonfire';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { EFeedbackEventSource, trackFeedbackCTASelectedEvent } from '@/services/analytics/feedback';
import { parsePageMetaData } from '@/services/analytics/pageEventUtils';

import { HelpButton } from './HelpButton';

export const HelpMenu = () => {
  const intl = useIntl();
  const [originalInitialized, setOriginalInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (document.querySelector('.help-container')) {
      setOriginalInitialized(true);
      return;
    }

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if (node instanceof HTMLElement && node.classList.contains('help-container')) {
            setOriginalInitialized(true);
            observer.disconnect();
          }
        });
      });
    });

    observer.observe(document.body, {
      subtree: false,
      childList: true,
    });

    return () => observer.disconnect();
  }, []);

  if (!originalInitialized) return null;

  return (
    <Popover
      components={{
        Trigger: HelpButton,
      }}>
      <Popover.Option
        value="chat"
        label={intl.formatMessage({
          defaultMessage: 'Chat, email, or call',
          id: '3BYVbI',
        })}
        icon={GeneralMessageMessageSquareIcon}
        onClick={() => {
          if (typeof window.openChat === 'function') {
            window.openChat();
          }
        }}
      />

      <Popover.Option
        value="help"
        label={intl.formatMessage({
          defaultMessage: 'Visit the help center',
          id: '6y/tjR',
        })}
        icon={GeneralCompassIcon}
        onClick={() => window.open('/help', '_blank')}
      />

      <Popover.Option
        value="feedback"
        label={intl.formatMessage({
          defaultMessage: 'Give us feedback',
          id: 'aQPexO',
        })}
        icon={GeneralStarOutlineIcon}
        onClick={() => {
          trackFeedbackCTASelectedEvent({
            pageCategory: parsePageMetaData(window.sessionStorage).pageCategory,
            source: EFeedbackEventSource.HELP_MENU,
          });
        }}
      />
    </Popover>
  );
};
