import 'react-day-picker/dist/style.css';

/* eslint-disable import/no-duplicates */
import { isBefore, isPast, isSameDay } from 'date-fns';
import { enUS } from 'date-fns/locale';
/* eslint-enable import/no-duplicates */
import dayjs from 'dayjs';
import React, { lazy, Suspense, useMemo, useState } from 'react';
import { DayPickerProps } from 'react-day-picker';

import { CARET_LARGE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import { TLocale } from '@/config/locales';
import { useLocaleSettings } from '@/hooks/useLocaleSettings';
import { getIsAndroidDevice, getIsIOSDevice } from '@/utility/navigator';

import Loading from '../Loading/Loading';
import css from './DayPicker.module.css';

const DayPicker = lazy(() =>
  import('react-day-picker').then(module => ({ default: module.DayPicker })),
);

export interface IDatePickerProps
  extends Omit<DayPickerProps, 'locale' | 'firstDayOfWeek' | 'disabled' | 'modifiers'> {
  disabledDays?: DayPickerProps['disabled'];
  dateFrom?: Date;
  dateTo?: Date;
  range?: boolean;
  isStacked?: boolean;
  minimumDays?: number;
  disableBefore?: Date;
  disableFrom?: boolean;
  size?: 'small';
  onChange: (args: { from: Date | undefined; to: Date | undefined }) => void;
  isWishlist?: boolean;
}

// First day of the week
// 0 = Sunday
// 1 = Monday
// 6 = Saturday
const weekdaysForLocale = (
  locale: TLocale,
  type: 'long' | 'short' | 'narrow' | undefined,
): string[] => {
  const year = 2021; // This is just a dummy date.
  const month = 2; // The first day of this month is on monday.

  const weekList = [0, 1, 2, 3, 4, 5, 6];

  const format = new Intl.DateTimeFormat(locale, { weekday: type });

  const getWeekName = (dayIndex: number) => format.format(new Date(year, month, dayIndex));
  const result = weekList.map(getWeekName);
  return result;
};

const monthsForLocale = (locale: TLocale, type: 'long' | 'short'): string[] => {
  const year = new Date().getFullYear();
  const monthList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const formatter = new Intl.DateTimeFormat(locale, { month: type });

  const getMonthName = (monthIndex: number) => formatter.format(new Date(year, monthIndex));

  return monthList.map(getMonthName);
};

const getModifiers = ({
  range,
  dateFrom,
  dateTo,
  dayHover,
  minimumDays,
}: {
  range?: boolean;
  dateFrom?: Date;
  dateTo?: Date;
  dayHover?: Date;
  minimumDays?: number;
}): DayPickerProps['modifiers'] => {
  if (!range) {
    const isHoveringSelectedDate = dayHover && dateFrom && isSameDay(dayHover, dateFrom);

    return {
      hover: (!isHoveringSelectedDate && dayHover) || false,
      single: dateFrom || false,
    };
  }

  const modifiers: DayPickerProps['modifiers'] = {
    start: dateFrom || false,
    end: dateTo || false,
  };

  const isHoveringDateFrom = dayHover && dateFrom && isSameDay(dayHover, dateFrom);
  const isHoveringDateTo = dayHover && dateTo && isSameDay(dayHover, dateTo);

  modifiers.hover = (!isHoveringDateFrom && !isHoveringDateTo && dayHover) || false;

  const selectedTo = dateTo || dayHover;
  const isSameAsDateFrom = selectedTo && dateFrom && isSameDay(selectedTo, dateFrom);
  const isAfterDateFrom = selectedTo && dateFrom && selectedTo >= dateFrom;
  const isBeforeDateFrom = selectedTo && dateFrom && selectedTo < dateFrom;
  const lastDayOfMinimum = minimumDays
    ? dayjs(dateFrom).add(minimumDays, 'day').toDate()
    : undefined;
  const isWithinMinimum =
    selectedTo && lastDayOfMinimum && selectedTo < lastDayOfMinimum && isAfterDateFrom;

  if (dateFrom && selectedTo && !isSameAsDateFrom) {
    modifiers.interval = isAfterDateFrom
      ? {
          from: dateFrom,
          to: selectedTo,
        }
      : {
          from: selectedTo,
          to: dateFrom,
        };
  }

  if (!dateTo && dayHover && !isWithinMinimum) {
    if (isAfterDateFrom) {
      modifiers.intervalLast = dayHover;
    } else {
      modifiers.intervalLast = dateFrom || false;
      modifiers.intervalFirst = dayHover;
    }
  }

  if (dateFrom && !dateTo && lastDayOfMinimum && !isBeforeDateFrom) {
    modifiers.minimum = { from: dateFrom, to: lastDayOfMinimum };

    if (!isAfterDateFrom && dayHover) {
      modifiers.minimumLast = dateFrom;
    } else {
      modifiers.minimumFirst = dateFrom;
    }

    if (!dayHover || dayHover < lastDayOfMinimum) {
      modifiers.minimumLast = lastDayOfMinimum;
    }
  }

  return modifiers;
};

const DatePicker: React.FC<IDatePickerProps> = ({
  dateFrom,
  dateTo,
  disabledDays,
  disableBefore = new Date(),
  onChange,
  range = true,
  isStacked,
  numberOfMonths = 1,
  minimumDays,
  disableFrom,
  size,
  isWishlist,
  ...rest
}) => {
  const { locale, first_day_of_the_week: firstDayOfWeek } = useLocaleSettings();
  const isMobile = getIsAndroidDevice() || getIsIOSDevice();

  const parsedDisabledDays = useMemo(
    () => (disabledDays ? [...[disabledDays].flat()] : []),
    [disabledDays],
  );
  const disabledDaysWithBeforeToday = useMemo(
    () => [{ before: disableBefore }, ...parsedDisabledDays],
    [parsedDisabledDays, disableBefore],
  );
  const [dayHover, setDayHover] = useState<Date | undefined>();

  const modifiers = useMemo<DayPickerProps['modifiers']>(
    () => getModifiers({ range, dateFrom, dateTo, dayHover, minimumDays }),
    [range, dateFrom, dateTo, dayHover, minimumDays],
  );

  const months = monthsForLocale(locale, isWishlist ? 'short' : 'long');
  const weekdaysShort = weekdaysForLocale(
    locale,
    isMobile && isWishlist ? 'narrow' : isWishlist ? 'short' : 'narrow',
  );
  const weekdaysLong = weekdaysForLocale(locale, 'long');

  const handleDayClick = (day: Date) => {
    const isTheSameDay = dateFrom && isSameDay(day, dateFrom);

    if (disableFrom) {
      onChange && onChange({ from: dateFrom, to: day });
    }

    if (!range) {
      onChange && onChange({ from: !isTheSameDay ? day : undefined, to: undefined });
      return;
    }

    const isBeforeFirstDay = dateFrom && day && isBefore(day, dateFrom);
    const isRangeSelected = dateFrom && dateTo;
    const isSelectingFirstDay = !dateFrom || isRangeSelected;

    if (isTheSameDay) {
      onChange && onChange({ from: day, to: undefined });
      return;
    }

    if (isSelectingFirstDay) {
      onChange && onChange({ from: day, to: undefined });
      return;
    }

    if (isBeforeFirstDay) {
      onChange && onChange({ from: day, to: dateFrom });
      return;
    }

    onChange && onChange({ from: dateFrom, to: day });
  };

  const handleKeyDown: DayPickerProps['onDayKeyDown'] = (day, _, e) => {
    if (e.key !== 'Enter' && e.key !== ' ') {
      return;
    }

    const isDayDisabled =
      isPast(day) || parsedDisabledDays.some(item => isSameDay(day, item as Date));

    if (isDayDisabled) {
      return;
    }

    handleDayClick(day);
  };

  const handleDayHover: DayPickerProps['onDayMouseEnter'] = day => {
    setDayHover(day);
  };

  const handleDayFocus: DayPickerProps['onDayFocus'] = day => {
    setDayHover(day);
  };

  const handleDayBlur: DayPickerProps['onDayBlur'] = () => {
    setDayHover(undefined);
  };

  const selectedDays = dateFrom && [dateFrom, { from: dateFrom, to: dateTo as Date }];

  return (
    <div
      className={`${css.datePicker} ${size === 'small' ? css.smallDatePicker : ''}`}
      data-stacked={isStacked}
      data-testid="date-calendar">
      <Suspense
        fallback={
          <div className="relative h-100">
            <Loading className="absolute inset-0 flex items-center justify-center overflow-hidden" />
          </div>
        }>
        <DayPicker
          // Override en-us locale to use our own weekdays and months.
          // In the future maybe we would like to use raw locale from date-fns.
          locale={{
            ...enUS,
            localize: enUS.localize && {
              ...enUS.localize,
              day: (dayIndex: number, options: { width: 'short' | 'wide' }) => {
                return options.width === 'short' ? weekdaysShort[dayIndex] : weekdaysLong[dayIndex];
              },
              month: (monthIndex: number) => months[monthIndex],
            },
            options: {
              weekStartsOn: firstDayOfWeek as 0 | 1,
            },
          }}
          fixedWeeks={true}
          disabled={disabledDaysWithBeforeToday}
          selected={selectedDays}
          modifiers={modifiers}
          modifiersClassNames={{
            hover: !isMobile ? (css.hover as string) : '',
            single: css.single as string,
            start: css.start as string,
            end: css.end as string,
            interval: css.interval as string,
            intervalFirst: css.intervalFirst as string,
            intervalLast: css.intervalLast as string,
            minimum: css.minimum as string,
            minimumFirst: css.minimumFirst as string,
            minimumLast: css.minimumLast as string,
          }}
          numberOfMonths={numberOfMonths}
          onDayClick={handleDayClick}
          onDayKeyDown={!isMobile ? handleKeyDown : undefined}
          onDayMouseEnter={!isMobile ? handleDayHover : undefined}
          onDayFocus={!isMobile ? handleDayFocus : undefined}
          onDayBlur={!isMobile ? handleDayBlur : undefined}
          components={{
            IconRight: () => {
              return <Icon name={CARET_LARGE} height={20} width={20} className="rotate-180" />;
            },
            IconLeft: () => {
              return <Icon name={CARET_LARGE} height={20} width={20} />;
            },
          }}
          hideHead={isWishlist}
          // We don't do a good job on differentiating range and single date pickers from types.
          // Just cast as an object to avoid typescript errors for now.
          {...(rest as object)}
        />
      </Suspense>
    </div>
  );
};

export default DatePicker;
